import { Dispatch } from 'redux'
import FetchApi from 'libs/fetch'
import * as ActionUtility from 'store/utilities/actionUtility'
import ActionType from './ActionType'
import { StateMachineType } from 'libs/statemachine'
import { FavoriteCompanyParams } from './Action'

export const accountInfo = async () => {
  const fetch = await FetchApi.get(`/v1/account/info`, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const setLoading = (dispatch: Dispatch, payload: StateMachineType) =>
  dispatch(ActionUtility.createAction(ActionType.ACCOUNT_INFO_DATA_LOADING, payload))

export const setData = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.ACCOUNT_INFO_DATA, payload))

export const setError = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.ACCOUNT_INFO_DATA_ERROR, payload))

export const accountUpdate = async (params: any) => {
  const fetch = await FetchApi.put(`/v1/account/update`, params, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const setLoadingUpdate = (dispatch: Dispatch, payload: StateMachineType) =>
  dispatch(ActionUtility.createAction(ActionType.ACCOUNT_UPDATE_LOADING, payload))

export const setErrorUpdate = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.ACCOUNT_UPDATE_ERROR, payload))

export const favoriteCompany = async (body: FavoriteCompanyParams) => {
  const fetch = await FetchApi.post(`/v1/account/company/favorite`, body, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const accountVerify = async ({ email }: { email: string }) => {
  const fetch = await FetchApi.post(`/v1/account/check`, { email }, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const setLoadingVerify = (dispatch: Dispatch, payload: StateMachineType) =>
  dispatch(ActionUtility.createAction(ActionType.ACCOUNT_VERIFY_LOADING, payload))

export const setErrorVerify = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.ACCOUNT_VERIFY_ERROR, payload))

export const setDataVerify = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.ACCOUNT_VERIFY_DATA, payload))

export const accountContact = async ({ name, email, bestContact, phone}: { name: string, email: string, bestContact: string; phone: string }) => {
  const fetch = await FetchApi.post(`/v1/firm/account/contact`, { name, email, bestContact, phone }, false)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}