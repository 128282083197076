import React from 'react'
import { Tabs } from 'antd'
import { useHistory } from 'react-router-dom'
import { RightOutlined, LogoutOutlined } from '@ant-design/icons'
import Storage from 'libs/storage'
import Layout from 'components/atom/layout'
import Text from 'components/atom/text'
import Header from 'components/organism/header'
import Footer from 'components/organism/footer'
import CreditCard from 'components/organism/creditcard'
import AccountUserData from 'pages/UserAccount/accountUserData'
import AccountUserQuote from 'pages/UserAccount/accountUserQuote'
import AccountUserFavorite from 'pages/UserAccount/accountUserFavorite'
import AccountUserBooking from 'pages/UserAccount/accountUserBooking'

import './styles.scss'

const { TabPane } = Tabs

const UserAccount: React.FC<any> = () => {
  const history = useHistory()
  const data = history.location.state as any

  const onChange = (key: any) => {
    // Rever essa implementação do logout;
    if (key === "6") {
      Storage.clear()
      history.push(`/`)
    }
  }

  return (
    <Layout>
      <Header />
      <div className="account_user-content _mt-6">
        <div className="_text-8 _font-weight-bold account_user-content-title"> Meu Perfil </div>

        <Tabs className="account_user-content-tabs" defaultActiveKey={data?.activeKey ? data.activeKey : "1"} onChange={onChange} animated={true} size="large">
          <TabPane tab="Minhas reservas" key="1">
            <AccountUserBooking />
          </TabPane>
          <TabPane tab="Minhas cotações" key="2">
            <AccountUserQuote />
          </TabPane>
          <TabPane tab="Meus favoritos" key="3">
            <AccountUserFavorite />
          </TabPane>
          <TabPane tab="Meus Cartões" key="4">
            <CreditCard hideSecurityFields={true} />
          </TabPane>
          <TabPane tab="Meus Dados" key="5">
            <AccountUserData />
          </TabPane>
          <TabPane tab="Sair" key="6" />
        </Tabs>

        {/** Mobile Menu */}
        <div className="account_user-content-menu">
          <div className="account_user-content-menu__item" onClick={() => history.push("/account/user/mobile/book")}>
            <Text className="_text-2">Minhas reservas</Text>
            <RightOutlined />
          </div>

          <div className="account_user-content-menu__item" onClick={() => history.push("/account/user/mobile/favorite")}>
            <Text className="_text-2">Meus favoritos</Text>
            <RightOutlined />
          </div>

          <div className="account_user-content-menu__item" onClick={() => history.push("/account/user/mobile/creditcard")}>
            <Text className="_text-2">Meus cartões</Text>
            <RightOutlined />
          </div>

          <div className="account_user-content-menu__item" onClick={() => history.push("/account/user/mobile/data")}>
            <Text className="_text-2">Meus dados</Text>
            <RightOutlined />
          </div>

          <div className="account_user-content-menu__item account_user-content-menu__item-last">
            <Text className="_text-2">Sair</Text>
            <LogoutOutlined />
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default UserAccount