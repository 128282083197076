const ActionType = {
  VEHICLE_OUTCOME_DATA_REVENUE: "OutcomeAction.VEHICLE_OUTCOME_DATA_REVENUE",
  VEHICLE_OUTCOME_DATA_EXPENSE: "OutcomeAction.VEHICLE_OUTCOME_DATA_EXPENSE",
  VEHICLE_OUTCOME_DATA_LOADING: "OutcomeAction.VEHICLE_OUTCOME_DATA_LOADING",
  VEHICLE_OUTCOME_DATA_LOADING_REVENUE: "OutcomeAction.VEHICLE_OUTCOME_DATA_LOADING_REVENUE",
  VEHICLE_OUTCOME_DATA_LOADING_EXPENSE: "OutcomeAction.VEHICLE_OUTCOME_DATA_LOADING_EXPENSE",
  VEHICLE_OUTCOME_DATA_ERROR: "OutcomeAction.VEHICLE_OUTCOME_DATA_ERROR",
  COMPANY_FINANCE: "OutcomeAction.COMPANY_FINANCE",
  COMPANY_FINANCE_LOADING: "loading",
  COMPANY_FINANCE_DATA: "data",
};

export default ActionType;