import React, { useState } from 'react'
import { Drawer } from 'antd'
import { useTranslation } from 'react-i18next'
import Text from 'components/atom/text'
import Button from 'components/atom/button'
import DatePicker from 'components/molecule/datepicker'
import Time from 'components/molecule/time'
import Grid, { GridSize } from 'components/atom/grid'
import ManageDate from 'libs/date'

interface DatePickerDrawerProps {
  startDate: string | undefined;
  endDate: string | undefined;
  onClick: (value: any) => void;
  onSelectStartDate?: (value: any) => void;
  onSelectStartHour?: (value: any) => void;
  onSelectEndDate?: (value: any) => void;
  onSelectEndHour?: (value: any) => void;
}

const DatePickerDrawer: React.FC<DatePickerDrawerProps> = ({ startDate, endDate, onClick, onSelectStartDate, onSelectStartHour, onSelectEndDate, onSelectEndHour }) => {
  const { t } = useTranslation()
  const [showDate, setShowDate] = useState(false)
  const [dateMobile, setDateMobile] = useState({
    start: ManageDate.mountDate(startDate),
    startHour: ManageDate.mountDate(startDate),
    end: ManageDate.mountDate(endDate),
    endHour: ManageDate.mountDate(endDate),
  })

  const onHandleSelectStartDate = (value: any) => {
    setDateMobile({
      ...dateMobile, 
      start: value,
    })

    onSelectStartDate && onSelectStartDate(value)
  }

  const onHandleSelectStartHour = (value: any) => {
    setDateMobile({
      ...dateMobile, 
      startHour: value,
    })

    onSelectStartHour && onSelectStartHour(value)
  }

  const onHandleSelectEndDate = (value: any) => {
    setDateMobile({
      ...dateMobile, 
      end: value,
    })

    onSelectEndDate && onSelectEndDate(value)
  }

  const onHandleSelectEndHour = (value: any) => {
    setDateMobile({
      ...dateMobile, 
      endHour: value,
    })

    onSelectEndHour && onSelectEndHour(value)
  }

  const onHandleClick = () => {
    closeMobileDate()

    onClick({
      startFormatDate: `${dateMobile.start.format("YYYY-MM-DD")}T${dateMobile.startHour.format("HH:mm")}`,
      endFormatDate: `${dateMobile.end.format("YYYY-MM-DD")}T${dateMobile.endHour.format("HH:mm")}`,
      ...dateMobile
    })
  }

  const openMobileDate = () => {
    setShowDate(true)
  }

  const closeMobileDate = () => {
    setShowDate(false)
  }

  return (
    <>
      <Drawer
        title={t('change_date_mobile_title')}
        placement="bottom"
        closable={false}
        onClose={closeMobileDate}
        visible={showDate}
        key="bottom"
        height={350}
      >
        <div className="_mb-4">
          <Text strong>{t('choose_mobile_pickup')}</Text>

          <Grid size={GridSize.Two} className="_mt-2">
            <DatePicker defaultValue={dateMobile.start} onSelect={onHandleSelectStartDate} />
            <Time defaultValue={dateMobile.startHour} onSelect={onHandleSelectStartHour} />
          </Grid>
        </div>

        <div className="_mb-4">
          <Text strong>{t('choose_mobile_return')}</Text>

          <Grid size={GridSize.Two} className="_mt-2">
            <DatePicker defaultValue={dateMobile.end} onSelect={onHandleSelectEndDate} />
            <Time defaultValue={dateMobile.endHour} onSelect={onHandleSelectEndHour} />
          </Grid>
        </div>

        <Button onClick={onHandleClick} theme="primary" block> Buscar </Button>
      </Drawer>

      <Button onClick={openMobileDate} theme="primary" block>
        {`${ManageDate.formatBrazil(startDate)} - ${ManageDate.formatBrazil(endDate)}`}
      </Button>
    </>
  )
}

export default DatePickerDrawer