import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Divider } from 'antd'
import { AppState } from 'store'
import * as AccountAction from 'store/account/Action'
import Button from 'components/atom/button'
import UserDataEditing, { UserDataEditingType } from './userDataEditing'
import UserFormEditing from './userFormEditing'
import StateMachine, { StateMachineType } from 'libs/statemachine'
import Loading, { Rendering } from 'components/molecule/loading'
import Grid, { GridSize } from 'components/atom/grid'
import TakePhotoDocumentModal, { DocumentUploadType, useTakePhotoDocument } from 'pages/UserAccount/takePhotoDocumentModal'

import './styles.scss'

const AccountUserData: React.FC<any> = ({ accountInfo, accountUpdate, update, data }) => {
  useEffect(() => {
    accountInfo()
  }, [])

  const { ref: refCNH, show: showCNH } = useTakePhotoDocument()
  const { ref: refProofResidence, show: showProofResidence } = useTakePhotoDocument()
  const { ref: refProofAdditionalDocument, show: showProofAdditionalDocument } = useTakePhotoDocument()

  const onSuccessPhotoCNH = (data: any) => {
    accountUpdate({ fields: [
      { field: "photo_cnh", value: data.fileKey, additional: { entity: "document_cnh" } },
    ]})
  }

  const onSuccessPhotoEndereco = (data: any) => {
    accountUpdate({ fields: [
      { field: "photo_proof_residence", value: data.fileKey, additional: { entity: "document_proof_residence" } },
    ]})
  }

  const onSuccessPhotoAddtionalDocument = (data: any) => {
    accountUpdate({ fields: [
      { field: "photo_proof_additional_document", value: data.fileKey, additional: { entity: "document_proof_additional_document" } },
    ]})
  }

  const renderStateNotStarted = () => <Rendering className="_mb-6" />

  const renderStateLoading = () => <Loading className="_mb-6" />

  const renderStateLoaded = () => (
    <Grid size={GridSize.Two} className="account_user-data__grid _mb-8">
      <div className="_mr-6">
        <div className="_text-6 _mb-5"> Dados Pessoais </div>
        <UserDataEditing
          label="Nome completo" 
          field="name"
          min={3}
          max={50}
          value={data?.value?.name}
          additional={{ entity: "account" }}
        />

        <UserDataEditing 
          label="DDD + Telefone" 
          field="phone" 
          min={11}
          max={11}
          value={data?.value?.phone} 
          additional={{ entity: "account" }}
        />
        
        <UserDataEditing 
          label="Gênero" 
          field="gender" 
          value={data?.value?.gender} 
          type={UserDataEditingType.Options}
          option={[{ value: "F", text: "Feminino" }, { value: "M", text: "Masculino" }, { value: "NB", text: "Não Binário" }]}
          additional={{ entity: "account" }}
        />

        <UserDataEditing 
          label="Data de nascimento" 
          field="birthday" 
          value={data?.value?.birthday} 
          type={UserDataEditingType.Date}
          additional={{ entity: "account" }}
        />

        <UserDataEditing 
          label={data?.value?.cpf?.documentName}
          field={data?.value?.cpf?.fieldKey}
          min={Number(data?.value?.cpf?.min_equal_length)}
          max={Number(data?.value?.cpf?.max_equal_length)}
          value={data?.value?.cpf?.value}
          additional={{ uCode: data?.value?.cpf?.uCode }}
        />
      </div>
      <div>
        <div>
          <div className="_text-6"> Endereço </div>
          <div className="_mb-5">Use seu endereço de residência.</div>
          <UserFormEditing initialValues={{ ...data?.value?.address }} />
        </div>
        <Divider />
        <div>
          <div className="_text-6"> Documentos </div>
          <div className="_mb-5">Faça o upload dos arquivos dos seus documentos.</div>

          <TakePhotoDocumentModal 
            ref={refCNH} 
            onSuccess={onSuccessPhotoCNH} 
            initalPhotoDocumentUrl={data?.value?.cnhPhotoUrl}
            documentType={DocumentUploadType.CNH}
            title="Foto da CNH" />

          <TakePhotoDocumentModal 
            ref={refProofResidence} 
            onSuccess={onSuccessPhotoEndereco} 
            initalPhotoDocumentUrl={data?.value?.proofResidencePhotoUrl}
            documentType={DocumentUploadType.PROOF_RESIDENCE}
            title="Foto do comprovante de endereço" />

          <TakePhotoDocumentModal 
            ref={refProofAdditionalDocument} 
            onSuccess={onSuccessPhotoAddtionalDocument} 
            initalPhotoDocumentUrl={data?.value?.proofAdditionalDocumentPhotoUrl}
            documentType={DocumentUploadType.PROOF_ADDITIONAL_DOCUMENT}
            title="Foto de documento com foto complementar" />

          <Button 
            onClick={() => showCNH()} 
            loading={StateMachine.in(update?.loading, StateMachineType.Loading)}
            theme="primary" 
            block 
            type="button">
              Enviar foto da CNH
          </Button>

          <Button 
            className="_mt-4"
            onClick={() => showProofResidence()} 
            loading={StateMachine.in(update?.loading, StateMachineType.Loading)}
            theme="primary" 
            block 
            type="button">
              Enviar foto do comprovante de endereço
          </Button>

          <Button 
            className="_mt-4"
            onClick={() => showProofAdditionalDocument()} 
            loading={StateMachine.in(update?.loading, StateMachineType.Loading)}
            theme="primary" 
            block 
            type="button">
              Enviar foto de documento com foto complementar
          </Button>
        </div>
      </div>
    </Grid>
  )

  const rendering = () => {
    switch(data?.loading) {
      case StateMachineType.NotStarted:
        return renderStateNotStarted()
      case StateMachineType.Loading:
        return renderStateLoading()
      case StateMachineType.Loaded:
        return renderStateLoaded()
    }
  }
  
  return (
    <>{rendering()}</>
  )
}

const mapStateToProps = ({ account }: AppState) => {
  return {
    data: account?.data,
    update: account?.update,
  }
}

const mapDispatchToProps = {
  accountInfo: AccountAction.accountInfo,
  accountUpdate: AccountAction.accountUpdate,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountUserData);