import { Dispatch } from "redux"
import FetchApi from "libs/fetch"
import * as ActionUtility from "store/utilities/actionUtility"
import ActionType from "./ActionType"

export const getCompanyStore = async (city: string) => {
  return FetchApi.get(`/v1/company/search?locale=${city}`, true)
}

export const dashboard = async ({ start, end }: { start: string; end: string; }) => {
  const fetch = await FetchApi.get(`/v1/company/dashboard?start=${start}&end=${end}`, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const setCompanyHoldStore = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.COMPANY_STORE_HOLD_DATA, payload));

export const setCompanyStore = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.COMPANY_STORE_DATA, payload));

export const setCompanyStoreLoading = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.COMPANY_STORE_DATA_LOADING, payload));

export const setCompanyStoreError = (dispatch: Dispatch) =>
  dispatch(ActionUtility.createAction(ActionType.COMPANY_STORE_DATA_ERROR, ""));

export const setCompanyDashboardLoading = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.COMPANY_DASHBOARD, { key: ActionType.COMPANY_DASHBOARD_KEY_LOADING, value: payload }));

export const setCompanyDashboardData = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.COMPANY_DASHBOARD, { key: ActionType.COMPANY_DASHBOARD_KEY_DATA, value: payload }));
