import { ErrorPayload } from 'libs/fetch/interface'
import baseReducer from '../utilities/baseReducer'
import ActionType from './ActionType'

export interface ITableState {
  readonly loading: boolean | null;
  readonly error: ErrorPayload | null;
  readonly actionError: ErrorPayload | null;
  readonly data: any | null;
}

const initialState: ITableState = {
  loading: false,
  error: null,
  actionError: null,
  data: null,
};

export default baseReducer(initialState, {
  [ActionType.TABLE_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loading: action.payload,
    };
  },
  [ActionType.TABLE_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      error: action.payload,
    };
  },
  [ActionType.TABLE_ACTION_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      actionError: action.payload,
    };
  },
  [ActionType.TABLE_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      data: action.payload,
    };
  },
})