import { ErrorPayload } from "libs/fetch/interface"
import baseReducer from "../utilities/baseReducer"
import ActionType from "./ActionType"

export interface ISignupState {
  readonly loading: boolean | null;
  readonly error: ErrorPayload | null;
  readonly success: boolean | null;
}

const initialState: ISignupState = {
  loading: false,
  error: null,
  success: null
};

const landingReducer = baseReducer(initialState, {
  [ActionType.SIGNUP_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loading: action.payload,
    };
  },
  [ActionType.SIGNUP_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      error: action.payload,
    };
  },
  [ActionType.SIGNUP_SUCCESS](
    state,
    action,
  ) {
    return {
      ...state,
      success: action.payload,
    };
  },
});

export default landingReducer;