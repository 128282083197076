import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Layout, Card, Divider, Spin, Form } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DEFAULT_ERROR_MESSAGE } from 'libs/i18n/pt'
import { connect } from 'react-redux'
import qs from 'qs'

import { VehicleSearched } from 'store/vehicle/Selector'
import * as CheckTokenAction from 'store/checktoken/Action'
import * as BookAction from 'store/book/Action'

import Header from 'components/organism/header'
import Footer from 'components/organism/footer'
import Content from 'components/atom/content'
import Text from 'components/atom/text'
import Button from 'components/atom/button'
import RangePicker from 'components/molecule/rangepicker'
import FormInput, { InputValidateStatus } from 'components/molecule/form_input'
import DatePickerDrawer from 'components/molecule/datepicker_drawer'

import CreditCard from 'components/organism/creditcard'
import CardVehicle from 'pages/UserSearchVehicle/card'
import CardGroupVehicle from 'pages/UserSearchVehicle/cardGroup'
import CardStore from 'pages/UserRentDetail/cardStore'
import CardAccountInfo from 'pages/UserRentDetail/cardAccountInfo'
import { AppState } from 'store'
import { CompanyTypeUserPayment } from 'store/company/Action'
import { useGetterStore } from 'store/getter/Hooks'
import { FeatureGetter } from 'store/getter/Action'
import StateMachine from 'libs/statemachine'
import { ReactComponent as LoadingBookingSvg } from './assets/loading_booking.svg'

import './styles.scss'

export interface QuerySearch {
  uCode?: string;
  city?: string;
  start?: string;
  end?: string;
  type?: string;
}

const UserRentDetail: React.FC<any> = ({ checkToken, loading, loadingBooking, data, booking, bookingCupom, securityData, rentalRate = {}, isCompleted }) => {

  const [form] = Form.useForm()
  const [formCard] = Form.useForm()
  const [formCupom] = Form.useForm()

  const { t } = useTranslation()
  const locationPath = useLocation()
  const history = useHistory<VehicleSearched>()

  const params: QuerySearch = qs.parse(locationPath.search, { ignoreQueryPrefix: true }) || {}

  const [rentError, setRentError] = useState(false)
  const [rentErrorMessage, setRentErrorMessage] = useState("")
  const [cupomError, setCupomError] = useState<any>(null)
  const [cupomValidate, setCupomValidate] = useState<InputValidateStatus>()

  const { getData: getAllVehicle, data: dataVehicle = {}, viewState } = useGetterStore({ 
    feature: FeatureGetter.GetVehicleAllDetail 
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    getAllVehicle({ queryParams: { uCode: params.uCode, start: params.start, end: params.end }})
    loadingBookingCupom()
    checkToken()
  }, [])

  const onFinishOwnerRent = async () => {
    try {
      let dataUser = null
      await formCard.validateFields()
      
      if (!isCompleted) {
        dataUser = await form.validateFields()
      }

      const dataCupom = await formCupom.validateFields()
      
      setRentError(false)

      const result = await booking({
        cupom: dataCupom.cupom,
        creditcard: securityData,
        vehicleCode: dataVehicle.uCode,
        startRentDate: params.start,
        endRentDate: params.end,
        dataUser
      })

      if (result.hasError) {
        setRentError(true)
        setRentErrorMessage(result.data.key)
      } else {
        history.push(`/pages/vehicle/rent/done?start=${params.start}&end=${params.end}&reservationCode=${result.data.reservationCode}`, dataVehicle)
      }
    } catch (e) {}
  }

  const handleBookingCupom = async () => {
    setCupomError(null)
    setCupomValidate(InputValidateStatus.Validating)

    await formCupom.validateFields()

    const result = await loadingBookingCupom()

    if (result.hasError) {
      setCupomError(true)
      setCupomValidate(InputValidateStatus.Error)
    } else {
      setCupomError(false)
      setCupomValidate(InputValidateStatus.Success)
    }
  }

  const loadingBookingCupom = async ({ start = params.start, end = params.end }: any = {}) => {
    const cupom = formCupom.getFieldValue('cupom')
    return await bookingCupom({
      cupom: cupom || null,
      vehicleCode: params.uCode,
      startRentDate: start,
      endRentDate: end,
    })
  }

  const onSelectDate = (value: any) => {
    const start = value[0]
    const end = value[1]

    if (start !== null && end !== null) {
      const startDate = start.format("YYYY-MM-DDTHH:mm")
      const endDate = end.format("YYYY-MM-DDTHH:mm")

      history.push(`/pages/vehicle/rent/detail?uCode=${dataVehicle?.uCode}&city=${params.city}&start=${startDate}&end=${endDate}`)
      loadingBookingCupom({ start: startDate, end: endDate })
    }
  }

  const onClickDateMobile = ({ startFormatDate, endFormatDate }: { startFormatDate: string; endFormatDate: string; }) => {
    history.push(`/pages/vehicle/rent/detail?uCode=${dataVehicle?.uCode}&city=${params.city}&start=${startFormatDate}&end=${endFormatDate}`)
    loadingBookingCupom({ start: startFormatDate, end: endFormatDate })
  }

  function disabledDate(current: any) {
    return current && current < moment().endOf('day')
  }

  const byHour = rentalRate?.byHour || false

  const hourOrDayLabel = byHour ? t('price_per_hour') : t('price_per_day')
  const hourOrDayPrice = byHour ? rentalRate?.hourCharge?.priceFormat : rentalRate?.dayCharge?.priceFormat

  const totalPriceLabel = byHour ? t('total_hours', { hour: rentalRate?.hourCharge?.quantity }) : t('total_days', { day: rentalRate?.dayCharge?.quantity })
  const totalPrice = byHour ? rentalRate?.hourCharge?.totalFormat : rentalRate?.dayCharge?.totalFormat

  return (
    <div>
      <Layout>
        <Header />
        <Content>
          {StateMachine.isLoading(viewState) || StateMachine.isNotStarted(viewState) ? (
            <div className="_p-4">
              <Card>
                <div className="loading-booking-container">
                  <LoadingBookingSvg className="loading-booking-image" />
                </div>
                <div className="_text-center _mt-4 _text-4">
                  Só um minuto, estamos carregando os dados da sua reserva...
                </div>
                <div className="_flex-diplay _justify-content-center _mt-4">
                  <Spin size="large" />
                </div>
              </Card>
            </div>
          ) : (
            <div className="_mt-4 user-rent-detail-container">
              <div>
                <Card>
                  <Text strong className="_text-4">{params.city}</Text>

                  <div className="_mt-2 user-rent-detail-container-date">
                    <Text strong>{t('chosen_date')}</Text>
                    <RangePicker
                      selectWhenStart={false}
                      onSelectClick={onSelectDate}
                      disabledDate={disabledDate}
                      initialDate={{ start: params.start, end: params.end }}
                    />
                  </div>
                  <div className="user-rent-detail-container-date-mobile _mt-2">
                    <div>{t('chosen_date')}</div>
                    <DatePickerDrawer startDate={params.start} endDate={params.end} onClick={onClickDateMobile} />
                  </div>
                </Card>
                
                {(dataVehicle?.company?.typeUserPayment === CompanyTypeUserPayment.PartialUser || dataVehicle?.company?.typeUserPayment === CompanyTypeUserPayment.Full) && (
                  <CreditCard form={formCard} />
                )}

                <CardAccountInfo form={form} />

                {dataVehicle?.groupTypeRegister === 'FULL' ? (
                  <CardVehicle
                    key={dataVehicle?.uCode}
                    className="_pl-5"
                    vehicle={dataVehicle} 
                    showPrice={false} />
                ):(
                  <CardGroupVehicle 
                    key={dataVehicle?.uCode}
                    className="_pl-5"
                    vehicle={dataVehicle}
                    showPrice={false}
                  />
                )}
                <CardStore key={dataVehicle?.company?.uCode} company={dataVehicle?.company} />
              </div>

              <div className="user-rent-detail-container-data">
                <Card>
                  <div className="user-rent-detail-container-data-price">
                    <Text className="_text-color-grey user-rent-detail-container-data-mobile">{hourOrDayLabel}</Text>
                    <div className="_text-right">
                    {loadingBooking ? (
                      <Spin/>
                    ) : (
                      <Text className="_text-color-grey user-rent-detail-container-data-mobile">{hourOrDayPrice}</Text>
                    )}
                    </div>
                  </div>

                  <Divider />

                  <div className="user-rent-detail-container-data-price">
                    <Text className="_text-color-grey user-rent-detail-container-data-mobile">{loadingBooking ? "Aguarde..." : totalPriceLabel}</Text>
                    <div className="_text-right">
                      {loadingBooking ? (
                        <Spin/>
                      ) : (
                        <Text className="_text-color-grey user-rent-detail-container-data-mobile">{totalPrice}</Text>
                      )}
                    </div>
                  </div>

                  <Divider />

                  {rentalRate?.insurance?.total > 0 && (
                    <>
                      <div className="user-rent-detail-container-data-price">
                        <Text className="_text-color-grey user-rent-detail-container-data-mobile">{t('insurance_price')}</Text>
                        <div className="_text-right">
                          <Text className="_text-color-grey user-rent-detail-container-data-mobile">{rentalRate?.insurance?.totalFormat}</Text>
                        </div>
                      </div>

                      <Divider />
                    </>
                  )}

                  {rentalRate?.taxAdminstration?.total > 0 && (
                    <>
                      <div className="user-rent-detail-container-data-price">
                        <Text className="_text-color-grey user-rent-detail-container-data-mobile">{t('tax_administration_price')}</Text>
                        <div className="_text-right">
                          <Text className="_text-color-grey user-rent-detail-container-data-mobile">{rentalRate?.taxAdminstration?.totalFormat}</Text>
                        </div>
                      </div>

                      <Divider />
                    </>
                  )}

                  {rentalRate?.discountCharge?.total > 0 && (
                    <>
                      <div className="user-rent-detail-container-data-price">
                        {rentalRate?.discountCharge?.percentage ? (
                          <Text strong className="_text-color-green user-rent-detail-container-data-mobile">
                            {t('discount', { discount: rentalRate?.discountCharge?.percentage })}
                          </Text>
                        ) : (
                          <Text strong className="_text-color-green user-rent-detail-container-data-mobile">
                            {t('discount')}
                          </Text>
                        )}
                        <div className="_text-right">
                          <Text className=" _text-color-grey user-rent-detail-container-data-mobile">{rentalRate?.discountCharge?.totalFormat}</Text>
                        </div>
                      </div>
                    </>
                  )}

                </Card>

                <Card className="_mt-6">
                  <Form name="basic" form={formCupom}>
                    <div className="input_with_right_button">
                      <FormInput className="user-rent-cupom" label="Código do cupom" name="cupom" disabled={loading} validateStatus={cupomValidate} />
                      <Button onClick={handleBookingCupom} theme="primary" block loading={loadingBooking}>Aplicar</Button>
                    </div>

                    {cupomError && (
                      <div className="_text-1 _font-weight-bold _text-uppercase _text-color-red">
                        {t("cupom_error_invalid")}
                      </div>
                    )}
                    {cupomError === false && rentalRate?.discountCharge?.message && (
                      <div className="_text-1 _font-weight-bold _text-uppercase _text-color-green">
                        {rentalRate?.discountCharge?.message}
                      </div>
                    )}
                  </Form>
                </Card>

                <Card className="_mt-6 _mb-6">
                  <div className="user-rent-detail-container-data-price">
                    <Text className="_text-color-grey user-rent-detail-container-data-mobile">{t('total_price')}</Text>
                    <div className="_text-right">
                      <Text className="_text-5 _text-color-grey _font-weight-bold">{rentalRate?.totalCharge?.totalFormat}</Text>
                    </div>
                  </div>

                  <Divider />
                  
                  {rentError && (
                    <div className="_text-1 _font-weight-bold _mt-4 _mb-4 _text-uppercase _text-color-red _text-center">
                      {t([rentErrorMessage, DEFAULT_ERROR_MESSAGE])}
                    </div>
                  )}
                  
                  {loading || loadingBooking ? <div className="_text-center"><Spin tip="Carregando..." /></div> : (
                    <>
                      {data?.hasError ? (
                        <Button onClick={() => history.push('/signin')} theme="primary" block> {t('login')} </Button>
                      ) : (
                        <Button onClick={onFinishOwnerRent} theme="primary" block> {t('rent')} </Button>
                      )}
                    </>
                  )}
                </Card>
              </div>
            </div>
          )}
        </Content>
        <Footer />
      </Layout>
    </div>
  );
}

const mapStateToProps = ({ checkToken, book, creditcard, account }: AppState) => {
  return {
    loading: checkToken?.loading,
    loadingBooking: book?.loading,
    data: checkToken?.data,
    securityData: creditcard?.securityData,
    isCompleted: account?.data?.value?.completed,
    rentalRate: book?.rentalRate
  }
}

const mapDispatchToProps = {
  checkToken: CheckTokenAction.checkToken,
  booking: BookAction.booking,
  bookingCupom: BookAction.bookingCupom,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRentDetail);