import React from 'react'
import { Skeleton } from 'antd'

import './styles.scss'

const PageCompanyLoading: React.FC<any> = (props) => {

  return (
    <div className="page-company-loading-content">
      <div className="page-company-loading-content__menu _pt-8 _pl-4 _pr-4">
        <img src="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal_white.png" className="page-company-loading-content__logo _mb-8" />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </div>
      <div className="_ml-6 _mr-6 _mt-5">
        <Skeleton active />
        <Skeleton active />
      </div>
    </div>
  )
}

export default PageCompanyLoading