import { Dispatch } from 'redux'
import FetchApi from 'libs/fetch'
import * as ActionUtility from 'store/utilities/actionUtility'
import ActionType from './ActionType'

export interface BookingCupomParams {
  cupom: string;
  vehicleCode: string;
  startRentDate: string;
  endRentDate: string;
  insuranceCompany?: string;
  vehicleTypeRegister?: string;
}

export interface BookingParams {
  creditcard?: { 
    securityCode: string;
    nationalNumberId: string;
    cardHash: string;
  };
  cupom: string;
  vehicleCode: string;
  startRentDate: string;
  endRentDate: string;
  dataUser?: {
    name?: string;
    phone?: string;
    cpf?: string;
    cpfUcode?: string;
    zipCode?: string;
    street?: string;
    number?: string;
    neighbor?: string;
    city?: string;
    state?: string;
  }
}

export interface BookingMovidaParams {
  uCode: string;
  start: string;
  end: string;
  acriss: string;
}

export const booking = async (params: BookingParams) => {
  const fetch = await FetchApi.post(`/v1/booking`, params, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const bookingMovida = async (params: BookingMovidaParams) => {
  const fetch = await FetchApi.post(`/v1/booking/movida`, params, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const bookingCupom = async (params: BookingCupomParams) => {
  const fetch = await FetchApi.post(`/v1/booking/charge`, params, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const setInitialLoading = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.BOOK_INITIAL_LOADING, payload))

export const setLoading = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.BOOK_LOADING, payload))

export const setData = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.BOOK_DATA, payload))

export const setVehicle = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.BOOK_VEHICLE, payload))

export const setRentalRate = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.BOOK_RENTAL_RATE, payload))
  