import { StateMachineType } from 'libs/statemachine'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FeatureExecuter, executeAction } from 'store/executer/Action'
import { executerSelector } from 'store/executer/Selector'

interface UseExecuterStore {
  executeAction: ({ bodyParams, pathParams }?: { bodyParams?: any; pathParams?: any }) => Promise<any>;
  data: any;
  viewState: StateMachineType;
}

export const useExecuterStore = ({ feature }: { feature: FeatureExecuter }): UseExecuterStore => {
  const dispatch = useDispatch()

  const { data, viewState } = useSelector(executerSelector)

  const _executeAction = useCallback((params = {}) => executeAction({ feature, ...params })(dispatch), [dispatch, feature])

  return {
    data: data[feature] || null,
    viewState: viewState[feature] || StateMachineType.NotStarted,
    executeAction: _executeAction,
  }
}