import { Dispatch } from 'redux'
import FetchApi from 'libs/fetch'
import * as ActionUtility from 'store/utilities/actionUtility'
import ActionType from 'store/checktoken/ActionType'

export const checkToken = async () => {
  const fetch = await FetchApi.get(`/v1/auth/check`, true)

  return {
    status: fetch.status,
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const setLoading = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.CHECKTOKEN_LOADING, payload))

export const setData = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.CHECKTOKEN_DATA, payload))
