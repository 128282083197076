import baseReducer from '../utilities/baseReducer'
import ActionType from './ActionType'

export interface CreditCard {
  cardHash: string;
  last: string;
  holder: string;
  expirationDate: string;
  createdAt: string;
  cardDefault: string;
  brand: string;
}

export interface ICreditCardState {
  readonly loading?: boolean | null;
  readonly loadingCreate?: boolean | null;
  readonly data?: CreditCard[] | null;
  readonly error?: any | null;
  readonly errorCreate?: any | null;
  readonly selected?: CreditCard | null;
  readonly securityData?: any | null;
}

const initialState: ICreditCardState = {
  loading: false,
  loadingCreate: false,
  data: null,
  error: null,
  errorCreate: null,
  selected: null,
  securityData: null,
}

export default baseReducer(initialState, {
  [ActionType.CREDITCARD_DATA_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loading: action.payload,
    }
  },
  [ActionType.CREDITCARD_CREATE_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loadingCreate: action.payload,
    }
  },
  [ActionType.CREDITCARD_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      data: action.payload,
    }
  },
  [ActionType.CREDITCARD_DATA_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      data: action.payload,
    }
  },
  [ActionType.CREDITCARD_CREATE_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      errorCreate: action.payload,
    }
  },
  [ActionType.CREDITCARD_SELECTED](
    state,
    action,
  ) {
    return {
      ...state,
      selected: action.payload,
    }
  },
  [ActionType.CREDITCARD_SECURITY_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      securityData: action.payload,
    }
  },
})