import React, { useEffect } from 'react'
import { Form, Result } from 'antd'
import qs from 'qs'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ForgotPasswordSvg } from './assets/forgot_password.svg'

import Header from 'components/organism/header'
import Footer from 'components/organism/footer'
import Body from 'components/atom/body'
import Button from 'components/atom/button'
import Text from 'components/atom/text'
import Grid, { GridSize } from 'components/atom/grid'
import FormInput from 'components/molecule/form_input'
import Layout from 'components/atom/layout'
import { AppState } from 'store'
import * as SigninAction from 'store/signin/Action'

import './styles.scss'

const UserLostPassword = ({ changePassword, error, success, loading, clean }: any) => {

  const { t } = useTranslation()
  const location = useLocation()
  const params: any = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}

  useEffect(() => {
    return () => clean()
  }, [])
  
  const onSubmit = async ({ token, password }: any) => { 
    await changePassword({ token, password })
  }

  return (
    <div>
      <Layout>
        <Header />
        <Body>
          <Grid size={GridSize.Two} className="change_password_content_grid">
            <div className="change_password_content_image">
              <ForgotPasswordSvg className="change_password_image" />
            </div>
            
            <div className="_pt-6 _pl-8 _pr-8">
              {success ? (
                <Result
                  status="success"
                  title="Pronto, sua senha foi alterada com sucesso!"
                  subTitle="Agora você já pode acessar o sistema com a nova senha."
                  extra={[
                    <Link to="/signin">
                      <Button theme="primary" key="console">
                        Ir para o login
                      </Button>
                    </Link>,
                  ]}
                />
              ) : (
              <>
                <div className="_text-center _mb-6">
                  <Text className="_text-6">Trocar senha</Text>
                </div>

                <Form name="basic" onFinish={onSubmit} layout="vertical" initialValues={{ token: params.token }}>
                  <FormInput name="token" disabled={loading} hidden={true}/>

                  <FormInput label="Digite sua nova senha" name="password" type="password" min={8} disabled={loading} required />

                  <FormInput 
                    label="Confirme sua nova senha" 
                    name="confirmpassword" 
                    type="password"
                    min={8}
                    customRules={[
                      ({ getFieldValue }: any) => ({
                        validator(rule: any, value: any) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('As duas senha devem ser iguais!');
                        },
                      })
                    ]}
                    disabled={loading} 
                    required />

                  {error && (
                    <div className="_text-center _mb-4">
                      <Text className="_text-3 _text-color-red"> { t(error.key) } </Text>
                    </div>
                  )}

                  <Form.Item>
                    <Button htmlType="submit" theme="primary" loading={loading} block>Confirmar troca de senha</Button>
                  </Form.Item>
                </Form>
              </>
              )}
            </div>
          </Grid>
        </Body>
        <Footer />
      </Layout>
    </div>
  );
}

const mapStateToProps = ({ signin }: AppState) => {
  return {
    loading: signin?.loading,
    error: signin?.error,
    success: signin?.success,
  }
}

const mapDispatchToProps = {
  changePassword: SigninAction.changePassword,
  clean: SigninAction.clean
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLostPassword);