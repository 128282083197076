import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Input, Divider, Form, Select } from 'antd'
import * as CreditCardAction from 'store/creditcard/Action'

import { ReactComponent as Visa } from './assets/visa.svg'
import { ReactComponent as Master } from './assets/master.svg'
import { ReactComponent as Amex } from './assets/amex.svg'
import { ReactComponent as Diners } from './assets/diners.svg'
import { ReactComponent as Discover } from './assets/discover.svg'
import Elo from './assets/elo.png'
import Aura from './assets/aura.png'
import JCB from './assets/jcb.png'

import Text from 'components/atom/text'
import Button from 'components/atom/button'

import './index.scss'
import { AppState } from 'store'
import Grid, { GridSize } from 'components/atom/grid'
import FormSelect from 'components/molecule/form_select'
import FormInput from 'components/molecule/form_input'

const { Option } = Select;

const CreditCardForm: React.FC<any> = ({ createCreditCard, loadingCreate, errorCreate, onCreateSuccess, onCancel, isCompany = false }) => {
  const [brand, setBrand] = useState("")
  const [brandError, setBrandError] = useState(false)

  const onSubmit = async (data: any) => {
    if (brand.length <= 0) {
      setBrandError(true)
    } else {
      setBrandError(false)
      const create = await createCreditCard({ ...data, brand })
      
      if (!create?.hasError) {
        onCreateSuccess && onCreateSuccess({ 
          cardHash: create.data.cardHash, 
          securityCode: data.securityCode, 
          nationalNumberId: data.nationalNumberId,
          blockCreating: true,
        })
      }
    }
  }

  const onSelectBrand = (brand: string) => {
    setBrandError(false)
    setBrand(brand)
  }

  const expirationMonthOption = Array.from({ length: 12 }, (_, k) => k + 1).map(item => {
    if (item <= 9) {
      return { value: `0${item}`, text: `0${item}` }
    } else {
      return { value: `${item}`, text: `${item}` }
    }
  })

  const expirationYearOption = Array.from({ length: 20 }, (v, k) => k + 21).map(item => {
    return { value: `20${item}`, text: `20${item}` }
  })

  const typeDocumentOption = [
    { value: "cnpj", text: "CNPJ" },
    { value: "cpf", text: "CPF" },
  ]

  return (
    <div>
      <div className="creditcard-title">Novo cartão de crédito</div>
      <div className="creditcard-subtitle _mt-2 _mb-2 _text-uppercase">Insira os dados do cartão abaixo</div>

      <Divider />

      <div className="_mb-6 _mt-6">
        <div className="_text-1 _font-weight-bold _mt-2 _mb-4 _text-uppercase">Selecione a bandeira do cartão</div>
        <div className="creditcard-select-brand">
          <div onClick={() => onSelectBrand("Visa")} className={`creditcard-select-brand-box ${brand === 'Visa' && 'creditcard-select-brand-box-selected'}`}>
            <Visa className="creditcard-image" />
          </div>
          <div onClick={() => onSelectBrand("Master")} className={`creditcard-select-brand-box ${brand === 'Master' && 'creditcard-select-brand-box-selected'}`}>
            <Master className="creditcard-image" />
          </div>
          <div onClick={() => onSelectBrand("Amex")} className={`creditcard-select-brand-box ${brand === 'Amex' && 'creditcard-select-brand-box-selected'}`}>
            <Amex className="creditcard-image" />
          </div>
          <div onClick={() => onSelectBrand("Diners")} className={`creditcard-select-brand-box ${brand === 'Diners' && 'creditcard-select-brand-box-selected'}`}>
            <Diners className="creditcard-image" />
          </div>
          <div onClick={() => onSelectBrand("Discover")} className={`creditcard-select-brand-box ${brand === 'Discover' && 'creditcard-select-brand-box-selected'}`}>
            <Discover className="creditcard-image" />
          </div>
          <div onClick={() => onSelectBrand("Elo")} className={`creditcard-select-brand-box ${brand === 'Elo' && 'creditcard-select-brand-box-selected'}`}>
            <img className="creditcard-image" src={Elo} />
          </div>
          <div onClick={() => onSelectBrand("Aura")} className={`creditcard-select-brand-box ${brand === 'Aura' && 'creditcard-select-brand-box-selected'}`}>
            <img className="creditcard-image" src={Aura} />
          </div>
          <div onClick={() => onSelectBrand("JCB")} className={`creditcard-select-brand-box ${brand === 'JCB' && 'creditcard-select-brand-box-selected'}`}>
            <img className="creditcard-image" src={JCB} />
          </div>
        </div>

        {brandError && (
          <div className="_text-1 _font-weight-bold _mt-4 _mb-4 _text-uppercase _text-color-red">Você deve selecionar a bandeira do cartão</div>
        )}
      </div>

      <Form name="basic" onFinish={onSubmit}>
 
        <Grid size={GridSize.Two} className="creditcard-first-part">
          <FormInput label="Número" name="cardNumber" disabled={loadingCreate} required/>
          <FormInput label="Titular" name="holder" disabled={loadingCreate} required/>
        </Grid>

        <Grid size={GridSize.Three} className="creditcard-second-part">
          <div>
            <div><Text className="_text-2">Vencimento</Text></div>
            <Grid size={GridSize.Two}>
              <FormSelect placeholder="Mês" name="expirationMonth" disabled={loadingCreate} option={expirationMonthOption} required/>
              <FormSelect placeholder="Ano" name="expirationYear" disabled={loadingCreate} option={expirationYearOption} required/>
            </Grid>
          </div>

          <FormInput label="Cód. Segurança" name="securityCode" disabled={loadingCreate} required/>

          {isCompany ? (
            <Grid size={GridSize.Two}>
              <FormSelect label="Selecione o documento" name="typeNumberId" disabled={loadingCreate} option={typeDocumentOption} required/>
              <FormInput label="Número do documento" name="nationalNumberId" disabled={loadingCreate} required/>
            </Grid>
          ) : (
            <FormInput label="CPF" name="nationalNumberId" disabled={loadingCreate} required/>
          )}
        </Grid>

        <Divider />

        {errorCreate && (
          <div className="_text-1 _font-weight-bold _mt-4 _mb-4 _text-uppercase _text-color-red">Ops, infelizmente não foi possível adicionar este cartão.</div>
        )}

        <Grid size={GridSize.Two}>
          <Button onClick={onCancel} size="large" block disabled={loadingCreate} theme="outline-black"> Cancelar </Button>
          <Button htmlType="submit" size="large" theme="outline-primary" block loading={loadingCreate}> Salvar </Button>
        </Grid>
        
      </Form>
    </div>
  )
}

const mapStateToProps = ({ creditcard }: AppState) => {
  return {
    loadingCreate: creditcard?.loadingCreate,
    errorCreate: creditcard?.errorCreate,
  }
}

const mapDispatchToProps = {
  createCreditCard: CreditCardAction.createCreditCard
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardForm);