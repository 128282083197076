import Button from "components/atom/button";
import React, { useRef } from "react"
import Webcam from "react-webcam"

export const WebCamDisplay = ({ onCapture }: any) => {
  const webcamRef = useRef<Webcam>(null)

  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef?.current?.getScreenshot();
      onCapture && onCapture(imageSrc)
    },
    [webcamRef, onCapture]
  );

  return (
    <>
      <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
      <div className="_flex-diplay _justify-content-center _mt-4">
        <Button onClick={capture} theme="secondary">Tirar foto</Button>
      </div>
    </>
  )
}