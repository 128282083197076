import { Dispatch } from 'redux'
import * as ListEffect from './Effect'
import { StateMachineType } from 'libs/statemachine'
import { FavoriteCompanyParams } from 'store/account/Action'
import * as AccountAction from 'store/account/Action'
import { AppState } from 'store'

export enum ListFetchModule {
  Favorite = "/v1/account/company/favorite",
  Booking = "/v1/booking"
}

export const getList = (module: ListFetchModule) => async (dispatch: Dispatch) => {
  
  try {
    ListEffect.setLoading(dispatch, StateMachineType.Loading)

    const result = await ListEffect.fetchData(module)

    ListEffect.setData(dispatch, result.data)
  } catch (e) {
    ListEffect.setError(dispatch, true)
  } finally {
    ListEffect.setLoading(dispatch, StateMachineType.Loaded)
  }
}

export const favoriteCompany = (body: FavoriteCompanyParams) => async (dispatch: Dispatch, getState: () => AppState) => {
  const module = "company_favorite"

  loadingItemList(dispatch, true, getState()?.list?.data, "uCode", body.uCodeCompany, module)

  const result = await AccountAction.favoriteCompany(body)()

  if (!result.hasError) {
    const filterList = getState()?.list?.data?.find((item: any) => item.key === module)
    const mapListModule = filterList.list.filter((company: any) => company.uCode !== body.uCodeCompany)

    ListEffect.setData(dispatch, { key: module, list: mapListModule })
  }
}

export const loadingItemList = (dispatch: Dispatch, loading: boolean, list: any, keyTarge: string, target: any, module: string) => {
  const filterList = list?.find((item: any) => item.key === module)

  const mapListModule = filterList.list.map((dataListModule: any) => {
    if (dataListModule[keyTarge] === target) {
      dataListModule.loading = loading
    }

    return dataListModule
  })

  ListEffect.setData(dispatch, { key: module, list: mapListModule })
}