import React, { useEffect, useState } from 'react'
import { Alert, Divider, Form } from 'antd'
import { connect } from 'react-redux'
import qs from 'qs'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Text from 'components/atom/text'
import Body from 'components/atom/body'
import Layout from 'components/atom/layout'
import Header from 'components/organism/header'
import Content from 'components/atom/content'
import Button from 'components/atom/button'
import FormInput, { InputMask, makedPhoneMask } from 'components/molecule/form_input'
import FormCheckbox from 'components/molecule/form_checkbox'
import Footer from 'components/organism/footer'
import FormGroupSelect from './components/form_group_select'
import RangePicker from 'components/molecule/rangepicker'
import * as AccountAction from 'store/account/Action'
import { useExecuterStore } from 'store/executer/Hooks'
import { FeatureExecuter } from 'store/executer/Action'
import StateMachine from 'libs/statemachine'

import './styles.scss'
import AutoComplete from 'components/molecule/autocomplete'
import { FeatureGetter } from 'store/getter/Action'
import { CitySelected } from 'components/molecule/search'

interface QueryUserFormRequest {
  city?: string;
  cityId?: string;
  start?: string;
  end?: string;
}

const UserFormRequest:  React.FC<any> = ({ accountInfo }) => {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const params: QueryUserFormRequest = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
  const [dataForm, setDataForm] = useState<any>({ start: `${params.start}T10:00`, end: `${params.end}T10:00`, city: { id: params.cityId, nome: params.city } })
  const [error, setErrors] = useState<{city: boolean; httpError: string }>({ city: false, httpError: '' })
  const [form] = Form.useForm()

  const { executeAction: quoteCreate, viewState } = useExecuterStore({ 
    feature: FeatureExecuter.QuoteCreate 
  })

  const loadAccountInfo = async () => {
    const info = await accountInfo()

    if (info) {
      form.setFieldsValue({
        name: info.name,
        email: info.email,
        phone: makedPhoneMask(info.phone),
      })
    }
  }

  useEffect(() => {
    loadAccountInfo()
  }, [])

  const onSelectDate = (value: any) => {
    const dataStart = value[0]
    const dataEnd = value[1]

    setDataForm((prevState: any) => ({ 
      ...prevState, 
      start: dataStart.format("YYYY-MM-DDTHH:mm"), 
      end: dataEnd.format("YYYY-MM-DDTHH:mm")
    }))
  }

  const vehicleCategoryOption = [
    { value: "Hatch", text: "Hatch" },
    { value: "Sedan", text: "Sedan" },
    { value: "SUV", text: "SUV" },
    { value: "Pick-up", text: "Pick-up" },
    { value: "Van / Furgão", text: "Van / Furgão" },
    { value: "4x4", text: "4x4" },
    { value: "Elétrico", text: "Elétrico" },
  ]

  const cambio = [
    { value: "Manual", text: "Manual" },
    { value: "Automático", text: "Automático" },
  ]

  const months = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
    { value: "6+", text: "6+" },
  ]

  const subscribe = [
    { value: "12", text: "12 meses" },
    { value: "18", text: "18 meses" },
    { value: "24", text: "24 meses" },
    { value: "30", text: "30 meses" },
    { value: "36", text: "36 meses" },
    { value: "48", text: "48 meses" },
  ]

  const franquiaKM = [
    { value: "1000", text: "1000 KM" },
    { value: "1500", text: "1500 KM" },
    { value: "2000", text: "2000 KM" },
    { value: "2500", text: "2500 KM" },
    { value: "3000+", text: "3000+ KM" },
  ]

  const franquiaKMDiaria = [
    { value: "KM Livre", text: "KM Livre" },
    { value: "100", text: "100 KM" },
    { value: "300", text: "300 KM" },
    { value: "500", text: "500 KM" },
  ]

  const modalidade = [
    { value: "Diária", text: "Diária" },
    { value: "Mensal", text: "Mensal" },
    { value: "Assinatura", text: "Assinatura" },
    { value: "Frota", text: "Frota" },
    { value: "Aplicativo", text: "Aplicativo" },
  ]

  const onChangeModalidade = (option: any) => {
    let typeAmount = "MONTH"

    if (option === "Diária") {
      typeAmount = "DAY"
    }

    setDataForm((prevState: any) => ({ ...prevState, modalidade: option, typeAmount }))
  }

  const onSelect = (value: CitySelected) => {
    setDataForm((prevState: any) => ({ ...prevState, city: value}))
    setErrors((previous: any) => ({ ...previous, city: false }))
  }

  const onChangeValue = () => {
    setDataForm((prevState: any) => ({ ...prevState, city: null}))
  }

  const onSubmit = async (data: any) => {
    if (!dataForm.city?.id) {
      setErrors((previous: any) => ({ ...previous, city: false, quoteAccountForbiden: false }))
      window.scrollTo(0, 0)
      return
    }

    const bodyParams = { 
      ...data, 
      ...(dataForm.typeAmount === "DAY" && { start: dataForm.start, end: dataForm.end }),
      typeAmount: dataForm.typeAmount,
      city: dataForm?.city.nome,
      cityId: dataForm?.city.id, 
    }

    const result = await quoteCreate({ bodyParams })
    console.log(result)
    
    if (!result.hasError) {
      history.replace('/pages/request/success', result.data)
    } else {
      setErrors((previous: any) => ({ ...previous, httpError: result.data.key }))
    }
  }

  return (
    <Layout>
      <Header />
      <Body className="user-form-request-body">
        <Content>
          <Form form={form} name="basic" onFinish={onSubmit} initialValues={{ city: params.city }}>
            <div className="_text-center">
              <Text className="_text-9">Solicite sua cotação</Text>
            </div>
            <div className="_mt-4 _flex-diplay _align-items-center _flex-column">
              <div>
                <Text className="_text-2">Digite a cidade</Text>
              </div>
              <AutoComplete
                className="user-form-request-city _mt-2"
                onSelect={onSelect}
                onChangeValue={onChangeValue}
                feature={FeatureGetter.GetCities}
                defaultValue={params.city}
                queryParam='city'
                keyData='nome'
              />
              {error.city && (
                <Alert className="_mt-2" message="Você deve escolher uma cidade na lista" type="error" />
              )}
            </div>

            <Divider />

            <div className="_text-center">
              <div className="_mb-4">
                <Text className="_text-4">Qual modalidade de aluguel você está buscando? </Text>
              </div>

              <FormGroupSelect name="typeQuote" options={modalidade} onChange={onChangeModalidade} disabled={StateMachine.isLoading(viewState)} required />
            </div>

            <div className="_text-center _mt-4">
              <div className="_mb-4">
                <Text className="_text-4">Qual a categoria que você deseja? </Text><br />
                <Text className="_text-2">Escolha pelo menos uma.</Text>
              </div>
              <FormGroupSelect name="category" options={vehicleCategoryOption} multipleChoice disabled={StateMachine.isLoading(viewState)} required />
            </div>

            <div className="_text-center _mt-4">
              <div className="_mb-4">
                <Text className="_text-4">Qual o cambio que você deseja? </Text><br />
                <Text className="_text-2">Escolha pelo menos uma.</Text>
              </div>
              <FormGroupSelect name="carGearbox" options={cambio} multipleChoice disabled={StateMachine.isLoading(viewState)} required />
            </div>
            {dataForm.modalidade === "Diária" ? (
              <div className="_text-center _mt-4">
                <div className="_mb-4">
                  <Text className="_text-4">Qual a franquia de quilômetros por dia que você está buscando? </Text><br />
                  <Text className="_text-2">Escolha pelo menos uma.</Text>
                </div>
                <FormGroupSelect name="franquiaKM" options={franquiaKMDiaria} multipleChoice disabled={StateMachine.isLoading(viewState)} required />
              </div>
            ) : (
              <div className="_text-center _mt-4">
                <div className="_mb-4">
                  <Text className="_text-4">Qual a franquia de quilômetros por mes que você está buscando? </Text><br />
                  <Text className="_text-2">Escolha pelo menos uma.</Text>
                </div>
                <FormGroupSelect name="franquiaKM" options={franquiaKM} multipleChoice disabled={StateMachine.isLoading(viewState)} required />
              </div>
            )}

            {dataForm.modalidade === "Diária" && (
              <div className="_text-center _mt-4">
                <div className="_mb-4">
                  <Text className="_text-4">Quantos dias? </Text>
                </div>

                <div className="_text-center _mt-2">
                  <div>
                    <Text className="_text-2">Escolha a data de retirada e entrega</Text>
                  </div>
                  <RangePicker
                    className="user-form-request-date"
                    initialDate={{ start: dataForm.start, end: dataForm.end }}
                    onSelect={onSelectDate}
                    onSelectClick={onSelectDate}/>
                </div>
              </div>
            )}

            {dataForm.modalidade === "Mensal" && (
              <div className="_text-center _mt-4">
                <div className="_mb-4">
                  <Text className="_text-4">Quantos meses? </Text>
                </div>
                <FormGroupSelect name="amount" options={months} disabled={StateMachine.isLoading(viewState)} required />
              </div>
            )}

            {dataForm.modalidade === "Assinatura" && (
              <div className="_text-center _mt-4">
                <div className="_mb-4">
                  <Text className="_text-4">Por quantos meses você deseja assinar? </Text>
                </div>
                <FormGroupSelect name="amount" options={subscribe} disabled={StateMachine.isLoading(viewState)} required />
              </div>
            )}

            {dataForm.modalidade === "Frota" && (
              <div className="_text-center _mt-4">
                <div className="_mb-4">
                  <Text className="_text-4">Por quantos meses será o contrato? </Text>
                </div>
                <FormGroupSelect name="amount" options={subscribe} disabled={StateMachine.isLoading(viewState)} required />
              </div>
            )}

            {dataForm.modalidade === "Aplicativo" && (
              <div className="_text-center _mt-4">
                <div className="_mb-4">
                  <Text className="_text-4">Quantos meses? </Text>
                </div>
                <FormGroupSelect name="amount" options={months} disabled={StateMachine.isLoading(viewState)} required />
              </div>
            )}

            <Divider />
            <div className="_text-center _mt-4">
              <div className="_mb-4">
                <Text className="_text-4">Precisamos dos seus dados para entrarmos em contato</Text>
              </div>
            </div>
            <div className='user-form-contact-container'>
              <div className='user-form-contact' >
                <FormInput label="Nome completo" name="name" max={35} disabled={StateMachine.isLoading(viewState)} required/>
                <FormInput label="Email" name="email" type="email" disabled={StateMachine.isLoading(viewState)} required/>
                <FormInput label="Telefone (DDD + Telefone)" placeholder="31 9 9999 9999" name="phone" mask={InputMask.DynamicPhone} disabled={StateMachine.isLoading(viewState)} required/>

                <FormCheckbox 
                  name="acceptTerms"
                  required
                  requiredMessage="Você deve aceitar os termos para se cadastrar."
                  label={
                    <a href="https://closecar-public.s3.amazonaws.com/Termos+de+Uso+-+Usuario+e+CloseCar.pdf" target="_blank">
                      Li e aceito os termos de uso (clique para ver os termos) 
                    </a>} 
                  />
              </div>
            </div>

            <Divider />
            <Form.Item>
              <Button className="_button_default_action" htmlType="submit" theme="primary" loading={StateMachine.isLoading(viewState)} block>
                Solicitar cotação
              </Button>
            </Form.Item>
            {error.httpError && (
              <Alert message={t(error.httpError)} type="error" />
            )}
            <Divider />
          </Form>
        </Content>
      </Body>
      <Footer />
    </Layout>
  )
};


const mapDispatchToProps = {
  accountInfo: AccountAction.accountInfo,
}

export default connect(null, mapDispatchToProps)(UserFormRequest);
