import ReduxThunk from "redux-thunk"
import { applyMiddleware, combineReducers, createStore } from "redux"
import company, { ICompanyState } from "./company/Reducer"
import vehicle, { IVehicleState } from "./vehicle/Reducer"
import signup, { ISignupState } from "./signup/Reducer"
import signin, { ISigninState } from "./signin/Reducer"
import checkToken, { ICheckTokenState } from './checktoken/Reducer'
import book, { IBookState } from './book/Reducer'
import creditcard, { ICreditCardState } from './creditcard/Reducer'
import account, { IAccountState } from './account/Reducer'
import document, { IDocumentState } from './document/Reducer'
import form, { IFormState } from './form/Reducer'
import table, { ITableState } from './table/Reducer'
import list, { IListState } from './list/Reducer'
import outcome, { IVehicleOutcomeState } from './outcome/Reducer'
import rent, { IVehicleRentState } from './rent/Reducer'
import firm, { IFirmState } from './firm/Reducer'
import subscription, { ISubscriptionState } from './subscription/Reducer'
import getter, { IGetterState } from './getter/Reducer'
import executer, { IExecuterState } from './executer/Reducer'

export interface AppState {
  company?: ICompanyState,
  vehicle?: IVehicleState;
  signup?: ISignupState;
  signin?: ISigninState;
  checkToken?: ICheckTokenState;
  book?: IBookState;
  creditcard?: ICreditCardState;
  account?: IAccountState;
  document?: IDocumentState;
  form?: IFormState;
  table?: ITableState;
  list?: IListState;
  outcome?: IVehicleOutcomeState;
  rent?: IVehicleRentState;
  firm?: IFirmState;
  subscription?: ISubscriptionState;
  getter?: IGetterState;
  executer?: IExecuterState;
}

const reducers = combineReducers({
  company,
  vehicle,
  signup,
  signin,
  checkToken,
  book,
  creditcard,
  account,
  document,
  form,
  table,
  list,
  outcome,
  rent,
  firm,
  subscription,
  getter,
  executer,
})

export default createStore(reducers, {}, applyMiddleware(ReduxThunk))
