import React from 'react'
import Layout from 'components/atom/layout'
import Body from 'components/atom/body'
import Content from 'components/atom/content'

import "./styles.scss"

const LandpageCompany: React.FC<any> = () => {

  return (
    <Layout hasHeader={false}>
      <Body hasHeader={false}>

      <Content className="_mt-8">
        LandingCompany
      </Content>
      </Body>
    </Layout>
  )
}

export default LandpageCompany