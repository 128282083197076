import { Dispatch } from 'redux'
import { AppState } from 'store'
import * as DocumentEffect from './Effect'

interface UploadParams {
  documentType?: string;
  file: any;
  type: string;
  position?: number;
}

export const upload = ({ documentType, file, type, position }: UploadParams) => async (dispatch: Dispatch, getState: () => AppState) => {
  DocumentEffect.setLoading(dispatch, true)

  const documentUpload = await DocumentEffect.upload({ file, type })

  if (documentUpload.hasError) {
    DocumentEffect.setError(dispatch, true)
  } else {
    DocumentEffect.setSuccess(dispatch, true)
    const document = getState()?.document

    if (document?.data === null) {
      DocumentEffect.setData(dispatch, [{ fileKey: documentUpload.fileKey, url: documentUpload.url, documentType, position }])
    } else {
      const documents = getState()?.document?.data.filter((doc: any) => doc.fileKey !== documentUpload.fileKey)
      DocumentEffect.setData(dispatch, [...documents, { fileKey: documentUpload.fileKey, url: documentUpload.url, documentType, position }])
    }
  }

  DocumentEffect.setLoading(dispatch, false)

  return { 
    url: documentUpload.url,
    hasError: documentUpload.hasError,
    fileKey: documentUpload.fileKey
  }
}

export const uploadBase64 = ({ file }: { file: string }) => async () => {

  const documentUpload = await DocumentEffect.uploadBase64({ file })

  return { 
    url: documentUpload.url,
    hasError: documentUpload.hasError,
    fileKey: documentUpload.fileKey
  }
}

export const remove = ({ fileKey }: { fileKey: string }) => async (dispatch: Dispatch, getState: () => AppState) => {
  const documents = getState()?.document?.data.filter((doc: any) => doc.fileKey !== fileKey)
  DocumentEffect.setData(dispatch, documents)
}

export const clean = () => async (dispatch: Dispatch) => {
  DocumentEffect.setData(dispatch, null)
}