import React, { useState, useRef, useImperativeHandle } from 'react'
import { Divider, Modal } from 'antd'
import { CameraOutlined } from '@ant-design/icons'
import FormImage from 'components/molecule/form_image'
import Button from 'components/atom/button'
import Grid, { GridSize } from 'components/atom/grid'
import { WebCamDisplay } from 'components/organism/webcam_display'
import * as DocumentEffect from 'store/document/Effect'
import ImageLoad from 'components/atom/image_load'
import Loading from 'components/molecule/loading'

import './styles.scss'

export enum DocumentUploadType {
  CNH = 'CNH_USER_DOCUMENT',
  PROOF_RESIDENCE = 'PROOF_RESIDENCE_USER_DOCUMENT',
  PROOF_ADDITIONAL_DOCUMENT = 'PROOF_ADDITIONAL_DOCUMENT_USER_DOCUMENT',
}

export interface TakePhotoDocumentModalRefProps {
  show: () => void;
  dismiss: () => void;
}

export const useTakePhotoDocument = () => {
  const modal = useRef<TakePhotoDocumentModalRefProps>(null)

  const show = () => modal.current?.show()
  const dismiss = () => modal.current?.dismiss()

  return { ref: modal, show, dismiss }
}

interface Props {
  onSuccess?: (data: any) => void;
  initalPhotoDocumentUrl?: string;
  documentType: DocumentUploadType;
  title?: string;
}

const TakePhotoDocumentModal = React.forwardRef<TakePhotoDocumentModalRefProps, Props>(({ onSuccess, initalPhotoDocumentUrl, documentType, title }, ref) => {

  const [visible, setVisible] = useState(false)
  const [documentUrl, setDocumentUrl] = useState(initalPhotoDocumentUrl)
  const [isCameraVisible, setCameraVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [uploadData, setUploadData] = useState<any>({})

  const show = () => { 
    setVisible(true)
  }

  const dismiss = () => setVisible(false)

  useImperativeHandle(ref, () => ({
    show,
    dismiss
  }));
  
  const handleOk = () => {
    onSuccess && onSuccess(uploadData)
    if (!uploadData.hasError) {
      setDocumentUrl(uploadData.url)
    }
    setUploadData(null)
    setCameraVisible(false)
    dismiss()
  }

  const handleClose = () => {
    setCameraVisible(false)
    setVisible(false)
  }

  const onSuccessUpload = (data: any) => {
    setUploadData(data)
  }

  const onCapturePhoto = async (photo: string) => {
    setLoading(true)
    const result = await DocumentEffect.uploadBase64({ file: photo })
    setUploadData(result)
    setCameraVisible(false)
    setLoading(false)
  }

  const onRetakePhoto = () => {
    setUploadData(null)
    setCameraVisible(true)
  }

  return (
    <Modal
      visible={visible}
      width={690}
      title={title || 'Foto do documento'}
      onOk={handleOk}
      onCancel={handleClose}
      footer={[
        <Button key="back" theme="outline-black" className="_mr-2" onClick={handleClose}>
          Fechar
        </Button>,
        <Button key="submit" theme="primary" onClick={handleOk}>
          Adicionar foto
        </Button>,
      ]}>
      
      {loading ? (
        <Loading text="Estamos carregando a foto..." />
      ) : (
        <>
          {uploadData?.url ? (
            <>
              <ImageLoad url={uploadData.url} />
              <div className="_flex-diplay _justify-content-center _mt-4">
                <Button key="submit" theme="secondary" onClick={onRetakePhoto}>
                  Escolher outra foto
                </Button>
              </div>
            </>
          ) : (
            <>
              {isCameraVisible ? (
                <WebCamDisplay onCapture={onCapturePhoto} />
              ) : (
                <>
                  <Grid size={GridSize.Two}>
                    <div className="take-photo-box-container">
                      <div onClick={() => setCameraVisible(true)} className="take-photo-box">
                        <CameraOutlined className="molecule-form-image-box__icon" />
                      </div>
                      <div className="_text-3 _font-weight-bold _mt-4"> Tirar foto </div>
                    </div>
                    <FormImage title="Carregar do computador" name="documentFile" documentType={documentType} onSuccess={onSuccessUpload} />
                  </Grid>
                  <Divider />
                  <div>
                    <div className="_text-3 _font-weight-bold _mb-4 _text-center"> Foto Atual </div>
                    <ImageLoad url={documentUrl} />
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  )
})

export default TakePhotoDocumentModal