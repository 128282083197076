import { Dispatch } from "redux"
import FetchApi from "libs/fetch"
import * as ActionUtility from "store/utilities/actionUtility"
import ActionType from "store/getter/ActionType"
import { StateMachineType } from "libs/statemachine"

export const getData = async ({ url, signal }: { url: string; signal?: AbortSignal }) => {
  const fetch = await FetchApi.get(url, true, { signal })

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const setData = (dispatch: Dispatch, payload: PayloadDataGetter) =>
  dispatch(ActionUtility.createAction(ActionType.GETTER_DATA, payload));

export const setViewState = (dispatch: Dispatch, payload: PayloadViewStateGetter) =>
  dispatch(ActionUtility.createAction(ActionType.GETTER_VIEW_STATE, payload));


interface PayloadDataGetter {
  feature: string;
  data: any | null;
}

interface PayloadViewStateGetter {
  feature: string;
  viewState: StateMachineType;
}