import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Card } from 'antd'
import Button from 'components/atom/button'
import CreditCardData from './cardData'
import CreditCardForm from './cardForm'
import CreditCardEmpty from 'components/molecule/empty/creditcard'
import Loading from 'components/molecule/loading'
import * as CreditCardAction from 'store/creditcard/Action'
import { AppState } from 'store'

const CreditCard: React.FC<any> = ({ className, creditcard, selectedCreditCard, setDefault, loading, data, hideSecurityFields, form, isCompany = false }) => {
  const [addCard, setAddCard] = useState(false)
  const [defaultParams, setDefaultParams] = useState({})

  useEffect(() => {
    creditcard()
  },[])

  const onSelect = (card: any) => {
    setDefaultParams({})
    selectedCreditCard(card)
    setDefault(card.cardHash)
  }

  const onCreateCreditCard = (data: any) => {
    setDefaultParams(data)
    setAddCard(false)
    creditcard()
  }
  
  return (
    <Card className={className}>
      {loading ? (
        <Loading text="Carregando seu cartões..." />
      ) : (
        <>
          {addCard? (
            <CreditCardForm onCancel={() => setAddCard(false)} onCreateSuccess={onCreateCreditCard} isCompany={isCompany} />
          ) : (
            <>
              {data?.length ? (
                <div>
                  <div className="_text-2 _font-weight-bold _mt-2 _mb-4 _text-uppercase">Seus cartões</div>
                  {data?.map((card: any) => (
                    <CreditCardData 
                      key={card.cardHash} 
                      form={form}
                      hideSecurityFields={hideSecurityFields}
                      defaultParams={defaultParams} 
                      onSelect={() => onSelect(card)} card={card} />
                  ))}

                  <Button theme="outline-primary" className="_mt-6" onClick={() => setAddCard(true)}> + Cadastrar um novo cartão </Button>
                </div>
              ) : (
                <CreditCardEmpty onAddCreditCard={() => setAddCard(true)} />
              )}
            </>
          )}
        </>
      )}
    </Card>
  )
}

const mapStateToProps = ({ creditcard }: AppState) => {
  return {
    loading: creditcard?.loading,
    data: creditcard?.data,
  }
}

const mapDispatchToProps = {
  creditcard: CreditCardAction.creditcard,
  selectedCreditCard: CreditCardAction.selectedCreditCard,
  setDefault: CreditCardAction.setDefault
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard);