import React from 'react'
import { useStorage } from 'libs/storage'
import { Space } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'

import Text from 'components/atom/text'
import DescriptionVehicle from 'pages/UserSearchVehicle/descriptionVehicle'
import Card from 'components/molecule/card'
import Button from 'components/atom/button'
import ImageLoad from 'components/atom/image_load'
import Config from 'libs/config'

import './index.scss'

interface CardVehicleProps {
  className?: string;
  vehicle?: any;
  key?: string;
  onClick?: any;
  showPrice?: boolean;
  showRentButton?: boolean;
}

const CardVehicle: React.FC<CardVehicleProps> = ({ className, vehicle, onClick, showPrice = true, showRentButton = true }) => {
  const history = useHistory()
  const { authorization } = useStorage()
  
  const dayOrHour = vehicle.rentalRate.byHour ? `hora${vehicle.rentalRate.hourCharge.quantity > 1 ? 's' : ''}` : `dia${vehicle.rentalRate.dayCharge.quantity > 1 ? 's' : ''}`
  const dayOrHourPrice = vehicle.rentalRate.byHour ? vehicle.rentalRate.hourCharge.priceFormat : vehicle.rentalRate.dayCharge.priceFormat
  const quantity = vehicle.rentalRate.byHour ? vehicle.rentalRate.hourCharge.quantity : vehicle.rentalRate.dayCharge.quantity

  const onSelectStore = (companyTag: string) => {
    history.push(`/my/${companyTag}`)
  }

  return (
    <Card className={className}>
      <div className="vehicle-description-container">
        <div className="vehicle-description-data">
          <div className="vehicle-description-box">
            <div>
              <ImageLoad url={vehicle.pictureURL} />
              <div className="_mt-4 vehicle-description-box-company" onClick={() => onSelectStore(vehicle.company.companyTag)}>
                <div className="_mr-4 vehicle-description-box-company__avatar">
                  <ImageLoad url={vehicle.company.logo} className="vehicle-description-box-company__image" />
                </div>
                <div>{vehicle.company.tradingName}</div>
              </div>
            </div>
            <div className="_ml-4 _mr-4">
              <Space direction="vertical">
                <Text strong className="_text-3">{vehicle?.description}</Text>
                <Text strong className="_text-1">{vehicle?.vehicleGroup}</Text>
                <DescriptionVehicle vehicle={vehicle}/>
                <div>
                  <InfoCircleOutlined style={{ fontSize: '10px' }} />
                  <span className="_text-1 _ml-1">A sua reserva garante um dos carros desse grupo. Modelo sujeito à disponibilidade.</span>
                </div>
              </Space>
            </div>
          </div>
        </div>
        {showPrice && (
          <Space direction="vertical">
            {(authorization === null || authorization?.role === Config.role.user) && (
              <>
                {showRentButton ? (
                  <Button onClick={onClick} theme="primary" block> Selecionar </Button>
                ): (
                  <Text> Carregando... </Text>
                )}
              </>
            )}
            <div>
              <Text className="_text-6 _font-weight-bold">{dayOrHourPrice}</Text>
            </div>
            <div><Text className="_text-1">Preço por {dayOrHour}</Text></div>
            <div>
              <Text className="_text-1">
                Preço final por <strong>{quantity}</strong> {dayOrHour} <strong>{vehicle.rentalRate.totalCharge.totalFormat}</strong>
              </Text>
            </div>
            {vehicle?.rentalRate?.discountCharge && vehicle?.rentalRate?.discountCharge.total > 0 && (
              <div className="vehicle-discount-box">
                Desconto de <strong>{vehicle.rentalRate.discountCharge.totalFormat}</strong>
              </div>
            )}
          </Space>
        )}
      </div>

      {/* Mobile Version */}
      <div className="vehicle-description-container__mobile">
        <div className="_text-center">
          <Space direction="vertical">
            <Text strong className="_text-2 _font-weight-bold">{vehicle?.description}</Text>
            <Text strong className="_text-1">{vehicle?.vehicleGroup}</Text>
          </Space>
        </div>
        <div className="vehicle-description-container__mobile-carousel">
          <ImageLoad url={vehicle.pictureURL} />
        </div>

        <DescriptionVehicle vehicle={vehicle} />
        
        {showPrice && (
          <>
            <div className="_mt-4 _mb-2">
              <Text className="_text-1">Preço por {dayOrHour}</Text>
            </div>

            {showRentButton ? (
              <Button onClick={onClick} theme="primary" block>{dayOrHourPrice}</Button>
            ): (
              <Text> Carregando... </Text>
            )}
            <div className="_mt-2">
              <Text className="_text-1">
                Preço final por <strong>{quantity}</strong> {dayOrHour} <strong>{vehicle.rentalRate.totalCharge.totalFormat}</strong>
              </Text>
            </div>
          </>
        )}
      </div>
    </Card>
  )
}

export default CardVehicle