import React from 'react';
import { Typography } from 'antd';

const { Text: AntText } = Typography;

function Text(props: any) {
  return (
    <AntText {...props}>
      { props.children }
    </AntText>
  );
}

export default Text;
