import { ErrorPayload } from 'libs/fetch/interface'
import baseReducer from '../utilities/baseReducer'
import ActionType from './ActionType'

export interface IDocumentState {
  readonly loading: boolean | null;
  readonly error: ErrorPayload | null;
  readonly success: boolean | null;
  readonly data: any | null;
}

const initialState: IDocumentState = {
  loading: false,
  error: null,
  success: null,
  data: null,
};

const documentReducer = baseReducer(initialState, {
  [ActionType.DOCUMENT_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loading: action.payload,
    };
  },
  [ActionType.DOCUMENT_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      error: action.payload,
    };
  },
  [ActionType.DOCUMENT_SUCCESS](
    state,
    action,
  ) {
    return {
      ...state,
      success: action.payload,
    };
  },
  [ActionType.DOCUMENT_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      data: action.payload,
    };
  },
});

export default documentReducer