import { ErrorPayload } from 'libs/fetch/interface'
import baseReducer from '../utilities/baseReducer'
import ActionType from './ActionType'
import { StateMachineType } from 'libs/statemachine'

export interface IListState {
  readonly loading: StateMachineType;
  readonly error: ErrorPayload | null;
  readonly data: any | null;
}

const initialState: IListState = {
  loading: StateMachineType.NotStarted,
  error: null,
  data: null,
};

export default baseReducer(initialState, {
  [ActionType.LIST_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loading: action.payload,
    };
  },
  [ActionType.LIST_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      error: action.payload,
    };
  },
  [ActionType.LIST_DATA](
    state,
    action,
  ) {
    const data = state.data?.filter((item: any) => item.key !== action.payload?.key) || []

    return {
      ...state,
      data: [
        ...data,
        action.payload
      ],
    };
  },
})