import Grid, { GridSize } from 'components/atom/grid'
import Card from 'components/molecule/card'
import { makedPhoneMask } from 'components/molecule/form_input'
import Loading from 'components/molecule/loading'
import ManageDate from 'libs/date'
import StateMachine from 'libs/statemachine'
import React, { useEffect } from 'react'
import { FeatureGetter } from 'store/getter/Action'
import { useGetterStore } from 'store/getter/Hooks'

const AccountUserQuote: React.FC<any> = () => {
  const { getData: getQuote, data = [], viewState } = useGetterStore({ 
    feature: FeatureGetter.GetQuote
  })

  useEffect(() => {
    getQuote()
  }, [])
  
  return (
    <div>
      {StateMachine.isLoading(viewState) ? (
        <Loading className="_mb-6" />
      ) : (
        <>
          {data?.map((quote: any) => (
            <Card className="_mb-4">
              <div>
                <div className="_text-5 _mb-2">Código da cotação <span className="_font-weight-bold">{quote.code}</span></div>
              </div>
              <Grid size={GridSize.Three}>
                <div className="_divider_right">
                  <div className="_text-4 _mb-2 _font-weight-bold">Dados da cotação</div>
                  <div className="_text-2 _mb-1">Modalidade: {quote.dataQuote.typeQuote}</div>
                  <div className="_text-2 _mb-1">Categoria(s): {quote.dataQuote.category.join(", ")}</div>
                  <div className="_text-2 _mb-1">Câmbio(s): {quote.dataQuote.carGearbox.join(", ")}</div>
                  <div className="_text-2 _mb-1">Franquia (KM): {quote.dataQuote.franquiaKM.join(", ")}</div>
                </div>

                <div className="_divider_right">
                  <div className="_text-4 _mb-2 _font-weight-bold">Local e Datas</div>
                  <div className="_text-2 _mb-1">Cidade: {quote.dataQuote.city}</div>
                  {quote.dataQuote.typeAmount === "DAY" ? (
                    <>
                      <div className="_text-2 _mb-1">Data de retirada: {ManageDate.formatBrazil(quote.dataQuote.start)}</div>
                      <div className="_text-2 _mb-1">Data de entrega: {ManageDate.formatBrazil(quote.dataQuote.end)}</div>
                    </>
                  ) : (
                    <div className="_text-2">Tempo de contrato: {quote.dataQuote.amount} {quote.dataQuote.amount > 1 ? 'meses' : 'mês'}</div>
                  )}
                </div>

                <div>
                  <div className="_text-4 _mb-2 _font-weight-bold">Dados pessoais</div>
                  <div className="_text-2 _mb-1">Solicitante: {quote.name}</div>
                  <div className="_text-2 _mb-1">Email: {quote.email}</div>
                  <div className="_text-2 _mb-1">Telefone: {makedPhoneMask(quote.phone)}</div>
                </div>
              </Grid>
            </Card>
          ))}
        </>
      )}
    </div>
  ) 
}

export default AccountUserQuote