import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Form } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import Card from 'components/molecule/card'
import FormInput from 'components/molecule/form_input'
import FormSelect from 'components/molecule/form_select'
import Grid, { GridSize } from 'components/atom/grid'

import { StateMachineType } from 'libs/statemachine'
import Loading, { Rendering } from 'components/molecule/loading'

import * as AccountAction from 'store/account/Action'
import { AppState } from 'store'

import './styles.scss'

const CardAccountInfo: React.FC<any> = ({ accountInfo, data, form }) => {

  useEffect(() => {
    accountInfo()
  }, [])

  const typeAddressOption = [
    { value: "Rua", text: "Rua" },
    { value: "Avenida", text: "Avenida" },
    { value: "Via", text: "Via" },
    { value: "Rodovia", text: "Rodovia" },
    { value: "Acesso", text: "Acesso" },
    { value: "Alameda", text: "Alameda" },
    { value: "Alto", text: "Alto" },
    { value: "Estrada", text: "Estrada" },
    { value: "Ladeira", text: "Ladeira" },
    { value: "Largo", text: "Largo" },
    { value: "Parque", text: "Parque" },
    { value: "Praca", text: "Praca" },
    { value: "Quadra", text: "Quadra" },
    { value: "Travessa", text: "Travessa" },
    { value: "Vale", text: "Vale" },
    { value: "Viaduto", text: "Viaduto" },
    { value: "Viela", text: "Viela" },
    { value: "Vila", text: "Vila" },
  ]

  const renderStateNotStarted = () => <Rendering />

  const renderStateLoading = () => <Loading />

  const renderStateLoaded = () => (
    <>
      <div className="_text-2 _font-weight-bold _mt-2 _mb-4 _text-uppercase">Complete Seu dados pessoais</div>
      <Form name="basic" form={form} initialValues={{ 
        name: data?.value?.name, 
        phone: data?.value?.phone, 
        cpf: data?.value?.cpf?.value, 
        cpfUcode: data?.value?.cpf?.uCode,
        zipCode: data?.value?.address?.zipCode,
        typeStreet: data?.value?.address?.typeStreet,
        street: data?.value?.address?.street,
        number: data?.value?.address?.number,
        neighbor: data?.value?.address?.neighbor,
        city: data?.value?.address?.city,
        state: data?.value?.address?.state,
      }}>
        <FormInput label="Nome" name="name" required />
        <Grid size={GridSize.Two}>
          <FormInput label="DDD+Telefone" name="phone" required min={11} max={11} />
          <FormInput label="CPF" name="cpf" required min={11} max={11} />
        </Grid>
        <Grid size={GridSize.Two}>
          <FormInput label="CEP" name="zipCode" required />
        </Grid>

        <div className="address_number_form">
          <div className="user-rent-address">
            <FormSelect label="Tipo do endereço" name="typeStreet" option={typeAddressOption} required/>
            <FormInput boxClassName="user-rent-address-street" label="Endereço" name="street" required />
          </div>
          <FormInput label="Número" name="number" />
        </div>

        <Grid size={GridSize.Three}>
          <FormInput label="Bairro" name="neighbor" required />
          <FormInput label="Cidade" name="city" required />
          <FormInput label="Estado" name="state" required />
        </Grid>
        <div className="_text-1">
          <InfoCircleOutlined className="_mr-2" />Pode ficar tranquilo, todos os dados são criptografados e armazenados com segurança.
        </div>
      </Form>
    </>
  )

  const rendering = () => {
    switch(data?.loading) {
      case StateMachineType.NotStarted:
        return renderStateNotStarted()
      case StateMachineType.Loading:
        return renderStateLoading()
      case StateMachineType.Loaded:
        return renderStateLoaded()
    }
  }
  
  return (
    <>
      {!data?.value?.completed && (
        <Card>{rendering()}</Card>
      )}
    </>
  )
}

const mapStateToProps = ({ account }: AppState) => {
  return {
    data: account?.data,
  }
}

const mapDispatchToProps = {
  accountInfo: AccountAction.accountInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(CardAccountInfo);