import { Dispatch } from 'redux'
import FetchApi from 'libs/fetch'
import { Buffer } from "buffer"
import * as ActionUtility from 'store/utilities/actionUtility'
import ActionType from './ActionType'

interface UploadParams {
  file: any;
  type: string;
}

export const upload = async ({ file, type }: UploadParams) => {

  const fetch = await FetchApi.post(`/v1/document/signed`, { fileType: type }, true)

  const upload = await FetchApi.upload(fetch.data.signedRequest, type, file)

  const document = await FetchApi.get(`/v1/document/signed/${fetch.data.fileKey}`, true)

  return {
    hasError: upload.status === FetchApi.HttpErrorBadRequest,
    fileKey: fetch.data.fileKey,
    url: document.data.signedUrl
  }
}

export const uploadBase64 = async ({ file }: { file: string }) => {
  try {
    const type = file.split(';')[0].split('/')[1]

    const fetch = await FetchApi.post(`/v1/document/signed`, { fileType: `image/${type}` }, true)

    const buffer = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""),'base64');

    const upload = await FetchApi.uploadBase64(fetch.data.signedRequest, type, buffer)

    const document = await FetchApi.get(`/v1/document/signed/${fetch.data.fileKey}`, true)

    return {
      hasError: upload.status === FetchApi.HttpErrorBadRequest,
      fileKey: fetch.data.fileKey,
      url: document.data.signedUrl
    }
  } catch (error) {
    console.log(error)
    return {
      hasError: true,
      fileKey: null,
      url: null
    }
  }
}

export const setLoading = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.DOCUMENT_LOADING, payload))

export const setSuccess = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.DOCUMENT_SUCCESS, payload))

export const setError = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.DOCUMENT_ERROR, payload))

export const setData = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.DOCUMENT_DATA, payload))