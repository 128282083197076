const ActionType = {
    ACCOUNT_INFO_DATA: "AccountAction.ACCOUNT_INFO_DATA",
    ACCOUNT_INFO_DATA_LOADING: "AccountAction.ACCOUNT_INFO_DATA_LOADING",
    ACCOUNT_INFO_DATA_ERROR: "AccountAction.ACCOUNT_INFO_DATA_ERROR",
    ACCOUNT_UPDATE_LOADING: "AccountAction.ACCOUNT_UPDATE_LOADING",
    ACCOUNT_UPDATE_ERROR: "AccountAction.ACCOUNT_UPDATE_ERROR",
    ACCOUNT_VERIFY_LOADING: "AccountAction.ACCOUNT_VERIFY_LOADING",
    ACCOUNT_VERIFY_ERROR: "AccountAction.ACCOUNT_VERIFY_ERROR",
    ACCOUNT_VERIFY_DATA: "AccountAction.ACCOUNT_VERIFY_DATA",
  };
  
  export default ActionType;