import { Dispatch } from 'redux'
import { StateMachineType } from 'libs/statemachine'
import * as ExecuterEffect from 'store/executer/Effect'
import { HttpVerb } from 'store/executer/Effect'

export enum FeatureExecuter {
  CancelRent = "CANCEL_RENT",
  GroupManualBook = "GROUP_MANUAL_BOOK",
  GroupCancelManualRent = "GROUP_CANCEL_MANUAL_RENT",
  ManualBook = "MANUAL_BOOK",
  CancelManualRent = "CANCEL_MANUAL_RENT",
  ContactLandpageCompany = "CONTACT_LANDPAGE_COMPANY",
  BookRating = "BOOK_RATING",
  QuoteCreate = "QUOTE_CREATE",
  AddRemoveCompanyCities = "ADD_REMOVE_COMPANY_CITIES",
  QuoteUpdateStatus = "QUOTE_UPDATE_STATUS",
  QuoteAddNote = "QUOTE_ADD_NOTE",
}

export const features = {
  [FeatureExecuter.CancelRent]: {
    url: "/v1/vehicle/cancelrent/{uCode}",
    httpVerb: HttpVerb.Post,
  },
  [FeatureExecuter.GroupCancelManualRent]: {
    url: "/v1/vehicle/group/manualBook/{uCode}",
    httpVerb: HttpVerb.Delete,
  },
  [FeatureExecuter.GroupManualBook]: {
    url: "/v1/vehicle/group/manualBook",
    httpVerb: HttpVerb.Post,
  },
  [FeatureExecuter.ManualBook]: {
    url: "/v1/vehicle/manualBook",
    httpVerb: HttpVerb.Post,
  },
  [FeatureExecuter.CancelManualRent]: {
    url: "/v1/vehicle/manualBook/{uCode}",
    httpVerb: HttpVerb.Delete,
  },
  [FeatureExecuter.ContactLandpageCompany]: {
    url: "/v1/firm/account/contact",
    httpVerb: HttpVerb.Post,
  },
  [FeatureExecuter.BookRating]: {
    url: "/v1/booking/rating",
    httpVerb: HttpVerb.Post,
  },
  [FeatureExecuter.QuoteCreate]: {
    url: "/v1/quote",
    httpVerb: HttpVerb.Post,
  },
  [FeatureExecuter.AddRemoveCompanyCities]: {
    url: "/v1/company/mycities/{cidadeId}/{action}",
    httpVerb: HttpVerb.Put,
  },
  [FeatureExecuter.QuoteUpdateStatus]: {
    url: "/v1/company/quotes/{uCode}/{status}",
    httpVerb: HttpVerb.Put,
  },
  [FeatureExecuter.QuoteAddNote]: {
    url: "/v1/company/quotes/note",
    httpVerb: HttpVerb.Post,
  },
}

export const executeAction = ({ feature, bodyParams = {}, pathParams = {} }: { feature: FeatureExecuter; bodyParams?: any; pathParams?: any }) => async (dispatch: Dispatch) => {
  
  try {
    const dataFeature = features[feature]

    let url = dataFeature.url

    ExecuterEffect.setViewState(dispatch, { feature, viewState: StateMachineType.Loading })

    const urlWithPathParams = Object.keys(pathParams).reduce((result: string, key: string) => {
      return result.replace(`{${key}}`, pathParams[key])
    }, url) || url

    const result = await ExecuterEffect.executeAction({ url: `${urlWithPathParams}`, httpVerb: dataFeature.httpVerb, bodyParams })

    if (result.hasError) {
      ExecuterEffect.setViewState(dispatch, { feature, viewState: StateMachineType.Error })
    } else {
      ExecuterEffect.setData(dispatch, { feature, data: result.data })
      ExecuterEffect.setViewState(dispatch, { feature, viewState: StateMachineType.Loaded })
    }

    return result
  } catch (e) {
    ExecuterEffect.setViewState(dispatch, { feature, viewState: StateMachineType.Error })
  }
}
