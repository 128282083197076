import React, { useState, useEffect } from 'react'
import { Moment } from 'moment'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import DatePicker from 'components/molecule/datepicker'
import Time from 'components/molecule/time'
import Text from 'components/atom/text'
import Button from 'components/atom/button'
import Card from 'components/molecule/card'

export interface SearchVerticalDefaultValue {
  city?: string;
  start?: Moment;
  end?: Moment;
}

export interface QuerySearchVertical {
  city?: string;
  start?: string | Moment | any;
  startTime?: string;
  end?: string | Moment | any;
  endTime?: string;
}

interface SearchVerticalProps {
  onSearch?: (query: any) => void;
  defaultFields?: SearchVerticalDefaultValue;
}

const SearchVertical: React.FC<SearchVerticalProps> = ({ onSearch, defaultFields }) => {
  
  const { t } = useTranslation()
  const [query, setQuery] = useState<QuerySearchVertical>({})

  useEffect(() => {
    setQuery({
      city: defaultFields?.city,
      start: defaultFields?.start?.format("YYYY-MM-DD"),
      startTime: defaultFields?.start?.format("HH:mm"),
      end: defaultFields?.end?.format("YYYY-MM-DD"),
      endTime: defaultFields?.end?.format("HH:mm"),
    })
  }, [])

  const onSelectDateStart = (value: any) => {
    if (value !== null) {
      setQuery({
        ...query, 
        start: value.format("YYYY-MM-DD"),
      })
    }
  };

  const onSelectTimeStart = (value: any) => {
    if (value !== null) {
      setQuery({
        ...query, 
        startTime: value.format("HH:mm"),
      })
    }
  };

  const onSelectDateEnd = (value: any) => {
    if (value !== null) {
      setQuery({
        ...query, 
        end: value.format("YYYY-MM-DD"),
      })
    }
  };

  const onSelectTimeEnd = (value: any) => {
    if (value !== null) {
      setQuery({
        ...query, 
        end: value.format("HH:mm"),
      })
    }
  };

  const onSubmitButton = () => {
    onSearch && onSearch(query)
  }

  return (
    <Card>
      <div>
        <Text strong>{t('choose_pickup')}</Text>
        <div className="_mt-2">
          <Row gutter={8}>
            <Col span={14}>
              <DatePicker defaultValue={defaultFields?.start} onSelect={onSelectDateStart} />
            </Col>
            <Col span={10}>
              <Time defaultValue={defaultFields?.start} onSelect={onSelectTimeStart} />
            </Col>
          </Row>
        </div>
      </div>

      <div className="_mt-4">
        <Text strong>{t('choose_return')}</Text>
        <div className="_mt-2">
          <Row gutter={8}>
            <Col span={14}>
              <DatePicker defaultValue={defaultFields?.end} onSelect={onSelectDateEnd} />
            </Col>
            <Col span={10}>
              <Time defaultValue={defaultFields?.end} onSelect={onSelectTimeEnd} />
            </Col>
          </Row>
        </div>
      </div>

      <Button 
        onClick={onSubmitButton} 
        className="_mt-6" 
        theme="outline-primary" 
        block> 
        {t('search')} 
      </Button>
    </Card>
  )
}

export default SearchVertical