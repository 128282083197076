import React from 'react'

import { Card } from 'antd'
import Text from 'components/atom/text'

import { ReactComponent as EmptyFavoriteSvg } from './assets/empty_favorite.svg'

import './index.scss'

const EmptyFavorite = () => {
  return (
    <Card className="empty-favorite-content">
      <div>
        <Text className="_text-6">Você ainda não tem nenhum favorito. </Text>
        <br />
        <Text className="_text-3">Selecione alguma loja como seu favorito. </Text>
      </div>
      <EmptyFavoriteSvg className="empty-favorite-img" />
    </Card>
  )
}

export default EmptyFavorite