import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"

const firebaseConfig = {
  apiKey: "AIzaSyAWko5e4z6YWWrkKWdIOvyHA0PXUIf96jA",
  authDomain: "closecar-firebase.firebaseapp.com",
  projectId: "closecar-firebase",
  storageBucket: "closecar-firebase.appspot.com",
  messagingSenderId: "250302625045",
  appId: "1:250302625045:web:94b7b9040ca394026fe51c",
  measurementId: "G-5FRVBYX592"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);
