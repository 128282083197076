const ActionType = {
  COMPANY_STORE_DATA: "CompanyAction.COMPANY_STORE_DATA",
  COMPANY_STORE_HOLD_DATA: "CompanyAction.COMPANY_STORE_HOLD_DATA",
  COMPANY_STORE_DATA_LOADING: "CompanyAction.COMPANY_STORE_DATA_LOADING",
  COMPANY_STORE_DATA_ERROR: "CompanyAction.COMPANY_STORE_DATA_ERROR",
  COMPANY_DASHBOARD: "CompanyAction.COMPANY_DASHBOARD",
  COMPANY_DASHBOARD_KEY_LOADING: "loading",
  COMPANY_DASHBOARD_KEY_DATA: "data",
};

export default ActionType;