import baseReducer from "../utilities/baseReducer"
import ActionType from "./ActionType"

export interface IVehicleOutcomeState {
  readonly revenue: any | null;
  readonly expense: any | null;
  readonly loading: boolean | null;
  readonly loadingRevenue: boolean | null;
  readonly loadingExpense: boolean | null;
  readonly error: boolean | null;
  readonly finance: { loading: boolean; data: any }
}

const initialState: IVehicleOutcomeState = {
  revenue: {},
  expense: {},
  loading: false,
  loadingRevenue: false,
  loadingExpense: false,
  error: false,
  finance: { loading: false, data: null }
};

const outcomeReducer = baseReducer(initialState, {
  [ActionType.VEHICLE_OUTCOME_DATA_REVENUE](
    state,
    action,
  ) {
    return {
      ...state,
      revenue: action.payload,
      error: false
    };
  },
  [ActionType.VEHICLE_OUTCOME_DATA_EXPENSE](
    state,
    action,
  ) {
    return {
      ...state,
      expense: action.payload,
      error: false
    };
  },
  [ActionType.VEHICLE_OUTCOME_DATA_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loading: action.payload,
    };
  },
  [ActionType.VEHICLE_OUTCOME_DATA_LOADING_REVENUE](
    state,
    action,
  ) {
    return {
      ...state,
      loadingRevenue: action.payload,
    };
  },
  [ActionType.VEHICLE_OUTCOME_DATA_LOADING_EXPENSE](
    state,
    action,
  ) {
    return {
      ...state,
      loadingExpense: action.payload,
    };
  },
  [ActionType.VEHICLE_OUTCOME_DATA_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      data: [],
      error: true
    };
  },
  [ActionType.COMPANY_FINANCE](
    state,
    action,
  ) {
    return {
      ...state,
      finance: { ...state.finance, [action.payload.key]: action.payload.value },
    };
  },
});

export default outcomeReducer;