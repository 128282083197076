import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card } from 'antd'
import Button from 'components/atom/button'
import Text from 'components/atom/text'

import { ReactComponent as EmptyStoreSvg } from './assets/empty_store.svg'

import './index.scss'

interface Props {
  className?: string;
  city?: String;
  start?: string;
  end?: string;
}

const EmptyStore = ({ className, city, start, end }: Props) => {
  const history = useHistory()
  
  const onClickQuote = () => {
    history.push(`/pages/request?city=${city}&start=${start}&end=${end}`)
  }

  return (
    <Card className={`empty-store-content ${className? className : ''}`}>
      <div>
        <Text className="empty-store-content-title">Ops! Não encontramos nenhum veículo disponível na plataforma.</Text>
      </div>

      {(city && start && end) && (
        <div className="_mt-6">
          <Text className="empty-store-content-subtitle">Você pode fazer uma cotação e iremos buscar o carro que você deseja.</Text>
          <Button block theme="primary" className="_mt-4" onClick={onClickQuote}>Fazer uma cotação</Button>
        </div>
      )}
      
      <EmptyStoreSvg className="empty-store-img" />
    </Card>
  )
}

export default EmptyStore