import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ICU from 'i18next-icu';
import { pt } from './pt'
import { en } from './en'
import { es } from './es'

const resources = {
  pt,
  en,
  es
};

i18n
  .use(initReactI18next)
  .use(ICU)
  .init({
    resources,
    lng: "pt",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;