import React, { useState } from 'react'
// import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Divider, Card } from 'antd'
import { analytics } from 'libs/firebase'
import { logEvent } from 'firebase/analytics'

import Header from 'components/organism/header'
import AutoComplete from 'components/molecule/autocomplete'
// import RangePicker from 'components/molecule/rangepicker'
import Button from 'components/atom/button'
import Text from 'components/atom/text'
// import DatePicker from 'components/molecule/datepicker'
// import Time from 'components/molecule/time'
// import Grid, { GridSize } from 'components/atom/grid'
// import ManageDate from 'libs/date'
import { FeatureGetter } from 'store/getter/Action'

import "./styles.scss"

export interface CitySelected {
  id: number;
  nome: string;
  estadoId: number;
}

export interface QuerySearch {
  city?: CitySelected | null;
  start?: string;
  startHour?: string;
  end?: string;
  endHour?: string;
}

const Search: React.FC<any> = ({ hasHeader }) => {

  // function disabledDate(current: any) {
  //   return current && current < moment().endOf('day')
  // }
  const { t } = useTranslation()
  const history = useHistory()
  const [query, setQuery] = useState<QuerySearch>({})
  const [validate, showValidate] = useState<{city: boolean}>({ city: false })

  const onSelect = (value: { city: string }) => {
    // setQuery((prevState: any) => ({ ...prevState, city: value.city}))
    onSearching(value.city, 'by_autocomplete')
  }

  const onChangeValue = (value: string) => {
    setQuery((prevState: any) => ({ ...prevState, city: value}))
  }
  
  // const onSelectDate = (value: any) => {
  //   const dataStart = value[0]
  //   const dataEnd = value[1]

  //   if (dataStart !== null && dataEnd !== null) {
  //     setQuery((prevState: any) => ({
  //       ...prevState, 
  //       start: dataStart.format("YYYY-MM-DDTHH:mm"),
  //       end: dataEnd.format("YYYY-MM-DDTHH:mm"),
  //     }))
  //   }
  // }

  // const onSelectMobileStartDate = (value: any) => {
  //   setQuery((prevState: any) => ({
  //     ...prevState,
  //     start: value.format("YYYY-MM-DD"),
  //   }))
  // }

  // const onSelectMobileStartHour = (value: any) => {
  //   setQuery((prevState: any) => ({
  //     ...prevState, 
  //     startHour: value.format("HH:mm"),
  //   }))
  // }

  // const onSelectMobileEndDate = (value: any) => {
  //   setQuery((prevState: any) => ({
  //     ...prevState,
  //     end: value.format("YYYY-MM-DD"),
  //   }))
  // }

  // const onSelectMobileEndHour = (value: any) => {
  //   setQuery((prevState: any) => ({
  //     ...prevState,
  //     endHour: value.format("HH:mm"),
  //   }))
  // }

  const onSearching = (city?: string, type?: string) => {
    let validCity = city ?? query?.city
    showValidate({ city: false })

    if (!validCity) {
      showValidate({ city: true })
      return false
    }

    logEvent(analytics, `searched_by_user`, {
      city: validCity,
      type
    })

    // const searching = { 
    //   start: query.start || ManageDate.nowWithFormat(),
    //   end: query.end || ManageDate.nowWithFormat(3),
    // }

    // if (query.startHour || query.endHour) {
    //   searching.start = `${query.start}T${query.startHour}`
    //   searching.end = `${query.end}T${query.endHour}`
    // }
    
    // if (type === 'quote') {
    //   history.push(`/pages/request?city=${city}&start=${searching.start}&end=${searching.end}`)
    // } else {
      // history.push(`/pages/store?city=${city}&start=${searching.start}&end=${searching.end}`)
    history.push(`/pages/store?city=${validCity}`)
    // }
  }

  const onSearchingPressEnter = (event: any) => {
    if (event.code === 'Enter') {
      onSearching(undefined, 'by_enter')
    }
  }

  // const onClickSearch = () => onSearching('search')
  // const onClickQuote = () => onSearching('quote')

  return (
    <div className="search_container">
      { hasHeader && (
        <Header withLogo={false} withBackground={false} />
      )}

      <div className="search_container_content">
        <Card className="search_container__card">
          <div className="search_container__logo-content">
            <img src="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal.png" className="search_container__logo" alt='Logo' />
          </div>
          <div className="search_container_near_you">
            <div>
              <Text className="_text-4">{t('reserver_near_you')}</Text>
            </div>

            <div className="_mt-4">
              <Text className="_text-2">Faça cotações para diárias, mensal, assinaturas, frotas ou aplicativo que se encaixam para você. </Text>
            </div>
          </div>
          
          <Divider />

          {/* <div>
            <div>
              <Text className="_text-2">
                Estamos à um passo de lançar a plataforma aonde você terá acesso a excelentes preços e veículos para aluguel.
              </Text>
            </div>
          </div> */}

          <div>
            <div>
              <Text strong>{t('city')}</Text>

              <AutoComplete
                className="_mt-2"
                onSelect={onSelect}
                onChangeValue={onChangeValue}
                onKeyDown={onSearchingPressEnter}
                feature={FeatureGetter.GetCities}
                queryParam='city'
                keyData='city'
                placeholder={'digite a cidade, bairro ou nome da empresa'}
              />
              {validate.city && (
                <div className="_mt-2">O busca não pode ser em branco, digite o nome de uma cidade.</div>
              )}
            </div>
            {/* <div className="search_container_range_picker">
              <Text strong>{t('select_the_date')}</Text>

              <div className="_mt-2">
                <RangePicker
                  onSelect={onSelectDate}
                  onSelectClick={onSelectDate}
                  disabledDate={disabledDate}
                />
              </div>
            </div> */}
            
            {/* <div className="search_container_range_picker-mobile">
              <Text strong>{t('choose_mobile_pickup')}</Text>

              <Grid size={GridSize.Two} className="_mt-2">
                <DatePicker defaultValue={ManageDate.nowAddDays(0)} onSelect={onSelectMobileStartDate} />
                <Time onSelect={onSelectMobileStartHour} />
              </Grid>
            </div>

            <div className="search_container_range_picker-mobile _mt-4">
              <Text strong>{t('choose_mobile_return')}</Text>

              <Grid size={GridSize.Two} className="_mt-2">
                <DatePicker defaultValue={ManageDate.nowAddDays(3)} onSelect={onSelectMobileEndDate} />
                <Time onSelect={onSelectMobileEndHour} />
              </Grid>
            </div> */}
            <div>
              <Text>&nbsp;</Text>

              <div className="_mt-2">
                <Button block theme="primary" onClick={() => onSearching()}>{t('search')}</Button>
                {/* <Button block theme="primary" onClick={onClickSearch}>{t('search')}</Button>
                <div className="_font-weight-bold _text-center _mt-2 _mb-2">ou</div> */}
                {/* <Button block theme="primary" onClick={onClickQuote}>Fazer uma cotação</Button> */}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

Search.defaultProps = {
  hasHeader: true
}

export default Search;
