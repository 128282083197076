import React from 'react'
import { Skeleton } from 'antd';
import Layout from 'components/atom/layout'
import Header from 'components/organism/header'
import Body from 'components/atom/body'

export default function PageLoading() {
  return (
    <Layout>
      <Header withMenu={false} />
      <Body>
        <Skeleton active />
      </Body>
    </Layout>
  )
}