import moment from 'moment'

class ManageDate {

  static now(addDays = 0) {
    return moment(new Date(), "YYYY-MM-DDTHH:mm").add(addDays, 'days')
  }

  static nowWithFormat(addDays = 0, format = "YYYY-MM-DD") {
    return moment(new Date(), "YYYY-MM-DDTHH:mm").add(addDays, 'days').format(format)
  }

  static nowAddDays(addDays = 0) {
    return moment(new Date(), "YYYY-MM-DDTHH:mm").add(addDays, 'days')
  }

  static nowWithRestrict(addDays = 0, restrict?: any) {
    const now = ManageDate.now()

    const date = now.format("YYYY-MM-DD")
    const hour = Number(now.format("HH"))
    const minute = Number(now.format("mm"))

    const restrictTime = ManageDate.restrictMinuteAndHour(hour, minute, restrict)

    return moment(`${date}T${restrictTime.hour}:${restrictTime.minute}`, "YYYY-MM-DDTHH:mm").add(addDays, 'days')
  }

  static dateWithRestrict(date?: string | null, addDays = 0, restrict?: any | null) {
    const momentDate = date? moment(date, "YYYY-MM-DDTHH:mm") : ManageDate.now()

    const dateFormat = momentDate.format("YYYY-MM-DD")
    const hour = Number(momentDate.format("HH"))
    const minute = Number(momentDate.format("mm"))

    const restrictTime = ManageDate.restrictMinuteAndHour(hour, minute, restrict)

    return moment(`${dateFormat}T${restrictTime.hour}:${restrictTime.minute}`, "YYYY-MM-DDTHH:mm").add(addDays, 'days')
  }

  static formatOnlyDate(date?: string, format = "YYYY-MM-DD", addDays = 0) {
    const mDate = moment(date, "YYYY-MM-DDTHH:mm").add(addDays, 'days')
    return mDate.format(format)
  }

  static mountDate(date?: string) {
    const mDate = moment(date, "YYYY-MM-DDTHH:mm")

    if (mDate.isValid()) {
      return moment(date, "YYYY-MM-DDTHH:mm")
    }
    
    return ManageDate.now()
  }

  static formatBrazil(date?: string) {
    const mDate = moment(date, "YYYY-MM-DDTHH:mm")
    return mDate.format("DD/MM/YYYY")
  }

  static transformBrazilToMoment(date?: string) {
    return moment(date, "DD/MM/YYYY")
  }

  static transformBrazilWithHourToMoment(date?: string) {
    return moment(date, "DD/MM/YYYY HH:mm")
  }

  static friendlyFormat(date?: string) {
    const mDate = moment(date, "YYYY-MM-DDTHH:mm")
    const day = mDate.format("DD")
    const month = mDate.format("MMMM")
    const time = mDate.format("HH:mm")

    return `${day} de ${month} às ${time}`
  }

  static friendlyFormatCreate(date?: string) {
    // 02 de junho às 17:13
    const mDate = moment(date, "YYYY-MM-DDTHH:mm")
    const day = mDate.format("DD")
    const month = mDate.format("MMM")
    const year = mDate.format("YYYY")

    return `${day} de ${month} de ${year}`
  }

  static formatRentDone(start?: string, end?: string) {
    
    const getStartDate = moment(start, "YYYY-MM-DDTHH:mm");
    const getEndDate = moment(end, "YYYY-MM-DDTHH:mm");

    const startDayMonth = getStartDate.format("dddd, DD MMMM")
    const endDayMonth = getEndDate.format("dddd, DD MMMM")

    const startTime = getStartDate.format("HH:mm")
    const endTime = getEndDate.format("HH:mm")

    return { startDayMonth, startTime, endDayMonth, endTime }
  }

  static nowHourAndMinute(restrict?: any): { hour: number, minute: number }{
    const now = ManageDate.now()

    const hour = Number(now.format("HH"))
    const minute = Number(now.format("mm"))

    return ManageDate.restrictMinuteAndHour(hour, minute, restrict)
  }

  static dateHourAndMinute(date?: string, restrict?: any): { hour: number, minute: number }{
    const momentDate = date? moment(date, "YYYY-MM-DDTHH:mm") : ManageDate.now()

    const hour = Number(momentDate.format("HH"))
    const minute = Number(momentDate.format("mm"))

    return ManageDate.restrictMinuteAndHour(hour, minute, restrict)
  }

  static restrictMinuteAndHour(hour: number, minute: number, restrict?: any): { hour: number, minute: number } {
    const sizeHour = restrict?.hour?.length
    const sizeMinute = restrict?.minute?.length

    const hoursRestrict = restrict?.hour?.reduce((result: any, item: any, index: number) => {
      if (index + 1 === sizeHour) {
        if (hour >= item) {
          return item
        } else {
          return result
        }
      } else {
        if (hour >= item && hour <= restrict.hour[index + 1]) {
          return item
        } else {
          return result
        }
      }
    }, hour) || hour

    const minutesRestrict = restrict?.minute?.reduce((result: any, item: any, index: number) => {
      if (index + 1 === sizeMinute) {
        if (minute >= item) {
          return item
        } else {
          return result
        }
      } else {
        if (minute >= item && minute <= restrict.minute[index + 1]) {
          return item
        } else {
          return result
        }
      }
    }, minute) || minute

    return { hour: hoursRestrict, minute: minutesRestrict }
  }

  static diff (start: string, end: string): { valid: boolean; days: number; hours: number } {

    const getStartDate = moment(start, "YYYY-MM-DDTHH:mm");
    const getEndDate = moment(end, "YYYY-MM-DDTHH:mm");

    if (!getStartDate.isValid() || !getEndDate.isValid()) {
      return { valid: false, days: 0, hours: 0 }
    }

    const days = getEndDate.diff(getStartDate, 'days')
    const hours = getEndDate.diff(getStartDate, 'hours')

    return { valid: true, days, hours }
  }
}

export default ManageDate