import React from 'react'
import { Form, Space } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import Text from 'components/atom/text'

import './styles.scss'

interface FormCheckboxProps {
  label?: any;
  name: string;
  disabled?: boolean;
  options: { value: string; text: string }[];
  required?: boolean;
  requiredMessage?: string;
  multipleChoice?: boolean;
  onChange?: (checkedValue: Array<CheckboxValueType>) => void;
}

const FormGroupItem: React.FC<any> = ({ option, checked, onChange }) => {
  const onSelectOption = (option: string) => {
    onChange(option)
  }

  return (
    <div className={`component-form-group-item ${checked && 'component-form-group-item-active'}`} key={option.value} onClick={() => onSelectOption(option.value)}>
      {option.text}
    </div>  
  )
}

const FormGroup: React.FC<any> = ({ options, multipleChoice, value, onChange, onHandleChange, disabled }) => {
  const selectedOptions: any = value || [];

  const onSelectOption = (option: string) => {
    if (disabled) {
      return
    }

    let newState: any = []

    if (multipleChoice) {
      const checkIfExist = selectedOptions.find((item: any) => item === option)

      if (checkIfExist) {
        newState = selectedOptions.filter((item: any) => item !== option)
      } else {
        newState = [...selectedOptions, option] 
      }
    } else {
      newState = option
    }
    
    onChange(newState)
    onHandleChange && onHandleChange(newState)
  }

  const isChecked = (option: string) => {
    if (multipleChoice) {
      return selectedOptions?.find((item: any) => item === option) || false
    } else {
      return selectedOptions === option
    }
  }

  return (
    <Space size={16} className="_justify-content-center" wrap>
      {options.map((option: any) => (
        <FormGroupItem key={option.value} option={option} checked={isChecked(option.value)} onChange={onSelectOption} />
      ))}
    </Space>  
  )
}

const FormGroupSelect: React.FC<FormCheckboxProps> = (props) => {
  const rules = []

  if (props?.required) {
    rules.push({ required: true, message: props.requiredMessage || 'Você deve selecionar uma opção.' })
  }

  return (
    <div>
      {props.label && (
        <Text className="_text-2">{props.label}{props.required && ' *'}</Text>
      )}

      <Form.Item
        name={props.name}
        rules={rules}>
        <FormGroup options={props.options} multipleChoice={props.multipleChoice} onHandleChange={props.onChange} disabled={props.disabled} />
      </Form.Item>
    </div>
  )
}

export default FormGroupSelect

FormGroupSelect.defaultProps = {
  disabled: false
}