import { StateMachineType } from 'libs/statemachine'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FeatureGetter, features, getData } from 'store/getter/Action'
import { getterSelector } from 'store/getter/Selector'

interface UseGetterStore {
  getData: ({ queryParams, pathParams, signal }?: { queryParams?: any; pathParams?: any; signal?: AbortSignal }) => Promise<any>;
  data: any;
  options: any;
  viewState: StateMachineType;
}

export const useGetterStore = ({ feature }: { feature: FeatureGetter }): UseGetterStore => {
  const dataFeature: any = features[feature]
  
  const dispatch = useDispatch()

  const { data, viewState }: any = useSelector(getterSelector)

  const _getData = useCallback((params: any = {}) => getData({ feature, ...params })(dispatch), [dispatch, feature])

  const dataResult = data[feature]

  const options = Array.isArray(dataResult) ? dataResult?.map((item: any ) => {
    return {
      value: item[dataFeature?.keyValue],
      text: item[dataFeature?.keyText]
    }
  }) : null

  return {
    data: data[feature] || null,
    options,
    viewState: viewState[feature] || StateMachineType.NotStarted,
    getData: _getData,
  }
}