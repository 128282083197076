import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import * as CheckTokenAction from 'store/checktoken/Action'
import { VehicleSearched } from 'store/vehicle/Selector'

import Layout from 'components/atom/layout'
import Body from 'components/atom/body'
import Header from 'components/organism/header'
import Footer from 'components/organism/footer'
import Content from 'components/atom/content'
import Text from 'components/atom/text'
import SearchVertical, { QuerySearchVertical } from 'components/molecule/search_vertical'
import SkeletonList from 'components/molecule/skeleton_list'
import EmptyVehicle from 'components/molecule/empty/vehicle'

import CardVehicle from 'pages/UserSearchVehicle/card'
import CardGroupVehicle from 'pages/UserSearchVehicle/cardGroup'
import { AppState } from 'store'
import ManageDate from 'libs/date'
import DatePickerDrawer from 'components/molecule/datepicker_drawer'
import { useGetterStore } from 'store/getter/Hooks'
import { FeatureGetter } from 'store/getter/Action'
import StateMachine from 'libs/statemachine'

interface SearchVehicleProps {
  checkToken: any;
  dataAuth: any;
  loadingAuth: any;
}

export interface QuerySearch {
  city?: string;
  type?: string;
  uCode?: string;
  start?: string;
  end?: string;
}

const UserSearchAllVehicle:  React.FC<SearchVehicleProps> = ({ checkToken, loadingAuth, dataAuth }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { companyTag = "" } = useParams<{companyTag: string}>()
  const params: QuerySearch = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
  const [searchDate, setSearchDate] = useState({ 
    startDate: params.start || `${ManageDate.nowWithFormat()}T10:00`,
    endDate: params.end || `${ManageDate.nowWithFormat(3)}T10:00`
  })

  const { getData: getAllVehicle, data: dataVehicle = [], viewState } = useGetterStore({ 
    feature: FeatureGetter.GetVehicleAll 
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    checkToken()
    getAllVehicle({ queryParams: { start: searchDate.startDate, end: searchDate.endDate }})
  }, [])

  const onSelectVehicle = (vehicle: VehicleSearched | undefined) => {
    if (dataAuth?.hasError) {
      history.push('/signin', {
        redirect: true,
        urlToRedirect: companyTag.length > 0 ? `/my/${companyTag}` : `/pages/vehicle/all?city=${params.city}&start=${searchDate.startDate}&end=${searchDate.endDate}`,
        dataToRedirect: vehicle,
        messageToRedirect: 'Para concluir o seu aluguel você deve logar primeiro.'
      })
    } else {
      history.push(`/pages/vehicle/rent/detail?uCode=${vehicle?.uCode}&city=${params.city}&start=${searchDate.startDate}&end=${searchDate.endDate}`)
    }
  }

  const onSearch = (query: QuerySearchVertical) => {
    const start = `${query.start}T${query.startTime}`
    const end = `${query.end}T${query.endTime}`

    getAllVehicle({ queryParams: { start, end }})
  }

  const onClickDateMobile = ({ startFormatDate, endFormatDate }: { startFormatDate: string; endFormatDate: string; }) => {
    setSearchDate({ startDate: startFormatDate, endDate: endFormatDate })
    getAllVehicle({ queryParams: { start: startFormatDate, end: endFormatDate }})
  }

  return (
    <Layout>
      <Header />
      <Body className="user-search-vehicle-content">
        <Content>
          <div className="user-search-vehicle-content-grid">
            <div className="_p-4 user-search-vehicle-content-search">
              <Text strong className="_text-4">{params.city}</Text>

              <SearchVertical
                defaultFields={{ 
                  city: params.city,
                  start: ManageDate.mountDate(searchDate.startDate),
                  end: ManageDate.mountDate(searchDate.endDate),
                }}
                onSearch={onSearch} 
              />
            </div>
            
            <div className="user-search-vehicle-content-search-mobile">
              <div>{t('chosen_date')}</div>
              <DatePickerDrawer startDate={searchDate.startDate} endDate={searchDate.endDate} onClick={onClickDateMobile} />
            </div>

            <div className="user-search-vehicle-content-grid-data">
              { StateMachine.isLoading(viewState)? (
                <SkeletonList />
              ) : (
                <>
                  {StateMachine.isError(viewState)? (
                    <EmptyVehicle city={params.city} start={searchDate.startDate} end={searchDate.endDate} />
                  ) : (
                    <>
                      {dataVehicle?.map((vehicle: VehicleSearched) => (
                        <>
                          {vehicle.groupTypeRegister === 'FULL' ? (
                            <CardVehicle 
                              className="_mb-4"
                              showRentButton={!loadingAuth}
                              key={vehicle.uCode} 
                              vehicle={vehicle} 
                              onClick={() => onSelectVehicle(vehicle)} 
                            />
                          ):(
                            <CardGroupVehicle 
                              className="_mb-4"
                              showRentButton={!loadingAuth}
                              key={vehicle.uCode} 
                              vehicle={vehicle}
                              onClick={() => onSelectVehicle(vehicle)} 
                            />
                          )}
                        </>
                      ))}

                      {dataVehicle?.length <= 0 && (
                        <EmptyVehicle city={params.city} start={searchDate.startDate} end={searchDate.endDate} />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </Content>
      </Body>
      <Footer />
    </Layout>
  );
}

const mapStateToProps = ({ checkToken, vehicle }: AppState) => {
  return {
    loadingAuth: checkToken?.loading,
    dataAuth: checkToken?.data,
  }
}

const mapDispatchToProps = {
  checkToken: CheckTokenAction.checkToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchAllVehicle);
