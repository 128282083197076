import React from 'react'
import { Result, Form } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SignUpSvg } from './assets/sign_up.svg'
import FormInput from 'components/molecule/form_input'
import Header from 'components/organism/header'
import Footer from 'components/organism/footer'
import Body from 'components/atom/body'
import Button from 'components/atom/button'
import Text from 'components/atom/text'
import Grid, { GridSize } from 'components/atom/grid'
import { AppState } from 'store'
import * as SignupAction from 'store/signup/Action'

import './styles.scss'
import FormCheckbox from 'components/molecule/form_checkbox'
import Layout from 'components/atom/layout'

const CompanySignUp = ({ signup, loading, error, success }: any) => {

  const { t } = useTranslation()

  const onSubmit = ({ name, tradingName, documentLegalNumber, phone, email, password, acceptTerms}: any) => {
    signup({ name, tradingName, documentLegalNumber, phone, email, password, acceptTerms})
  }

  return (
    <Layout>
      <Header />
      <Body>
        <Grid size={GridSize.Two} className="signup_company_content_grid">
          <div className="signup_content_image">
            <div className="_text-4 _pl-6 _pr-6 _mb-4">
              <div className="_mb-1 _font-weight-bold">Tenha acesso agora à plataforma Closecar.</div>
              <div>E veja se a Closecar é o software certo para sua locadora.</div>
            </div>
            <SignUpSvg className="signup_image" />
          </div>
          
          <div className="_pt-6 _pl-8 _pr-8 _pb-6">
            {success ? (
              <Result
                status="success"
                title="Cadastro feito com sucesso!"
                subTitle="Agora você terá acesso ao painel para você gerenciar seus véiculos e receitas. O seu cadastro ainda irá passar por uma aprovação da nossa equipe, mas você já pode ir navegando e conhecendo todo o sistema."
                extra={[
                  <Link to="/account/company/dashboard">
                    <Button theme="primary" key="console">
                      Ir para o painel
                    </Button>
                  </Link>,
                ]}
              />
            ) : (
              <>
                <div className="_text-center _mb-6">
                  <Text className="_text-4"> Tenha a melhor gestão para os seus alugueis! </Text>
                </div>

                <Form name="basic" onFinish={onSubmit} layout="vertical">
                  <FormInput label="Nome do Responsável" name="name" max={35} disabled={loading} required />
                  <FormInput label="Nome Fantasia" name="tradingName" max={35} disabled={loading} required />
                  <FormInput label="CNPJ" name="documentLegalNumber" disabled={loading} required />
                  <FormInput label="Telefone (DDD+Telefone)" name="phone" disabled={loading} required />
                  <FormInput label="Email" name="email" type="email" disabled={loading} required />
                  <FormInput label="Senha" name="password" type="password" min={8} disabled={loading} required />
                  <FormInput 
                    label="Repita a Senha" 
                    name="rePassword" 
                    type="password" 
                    min={8} 
                    disabled={loading} 
                    customRules={[
                      ({ getFieldValue }: any) => ({
                        validator(rule: any, value: any) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('As senhas não estão iguais.')
                        },
                      })
                    ]}
                    required />

                  <FormCheckbox 
                    name="acceptTerms"
                    required
                    requiredMessage="Você deve aceitar os termos para se cadastrar."
                    label={<a href="https://closecar-public.s3.amazonaws.com/Contrato+-+Closecar+e+Locadora.pdf" target="_blank"> Li e aceito os termos de uso (clique para ver os termos) </a>} />

                  {error && (
                    <div className="_text-center _mb-4">
                      <Text className="_text-3 _text-color-red"> { t(error.key) } </Text>
                    </div>
                  )}

                  <Form.Item>
                    <Button theme="primary" htmlType="submit" size="large" loading={loading} block>
                      Cadastrar
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
          </div>
        </Grid>
      </Body>
      <Footer />
    </Layout>
  );
}

const mapStateToProps = ({ signup }: AppState) => {
  return {
    loading: signup?.loading,
    error: signup?.error,
    success: signup?.success,
  }
}

const mapDispatchToProps = {
  signup: SignupAction.signupCompany,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanySignUp)