import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { AppState } from 'store'
import { useHistory } from 'react-router-dom'
import * as ListAction from 'store/list/Action'
import { StateMachineType } from 'libs/statemachine'
import { ListFetchModule } from 'store/list/Action'
import CardStore from 'pages/UserSearchStore/card'
import EmptyFavorite from 'components/molecule/empty/favorite'
import Loading, { Rendering } from 'components/molecule/loading'

import './styles.scss'

const AccountUserFavorite: React.FC<any> = ({ getList, data, loading, error, favoriteCompany }) => {
  const history = useHistory()

  useEffect(() => {
    getList(ListFetchModule.Favorite)
  }, [])

  const onSelectStore = (companyTag: string) => {
    history.push(`/my/${companyTag}`)
  }

  const onClickFavorite = (event: any, company: any) => {
    favoriteCompany({ uCodeCompany: company.uCode })
    event.stopPropagation()
  }

  const renderStateNotStarted = () => <Rendering className="_mb-6" />

  const renderStateLoading = () => <Loading className="_mb-6" />

  const renderStateLoaded = () => (
    <div className="_mb-6 _ml-5 _mr-5">
      {data?.list.map((company: any) => (
        <CardStore 
          onClick={() => onSelectStore(company.companyTag)}
          onClickFavorite={(e: any) => onClickFavorite(e, company)}
          key={company.uCode}
          loading={company.loading}
          favorited={true}
          logoUrl={company.logo}
          tradingName={company.tradingName} 
          address={company.address}
          className="_p-5 account_user-favorite-border" />
      ))}
      {data?.list.length <= 0 && (
        <EmptyFavorite />
      )}
    </div>
  )

  const rendering = () => {
    switch(loading) {
      case StateMachineType.NotStarted:
        return renderStateNotStarted()
      case StateMachineType.Loading:
        return renderStateLoading()
      case StateMachineType.Loaded:
        return renderStateLoaded()
    }
  }
  
  return (
    <>
      {rendering()}
    </>
  ) 
}

const mapStateToProps = ({ list }: AppState) => {
  return {
    data: list?.data?.find((item: any) => item.key === "company_favorite"),
    loading: list?.loading,
    error: list?.error
  }
}

const mapDispatchToProps = {
  getList: ListAction.getList,
  favoriteCompany: ListAction.favoriteCompany
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountUserFavorite)