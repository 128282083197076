import baseReducer from "../utilities/baseReducer"
import ActionType from "./ActionType"

export interface ICompanyState {
  readonly data: any | null;
  readonly holdData: any | null;
  readonly loading: boolean | null;
  readonly dashboard: { loading?: boolean; data?: any };
}

const initialState: ICompanyState = {
  data: [],
  holdData: [],
  loading: false,
  dashboard: { loading: false, data: null }
};

const companyReducer = baseReducer(initialState, {
  [ActionType.COMPANY_STORE_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      data: action.payload,
    };
  },
  [ActionType.COMPANY_STORE_HOLD_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      holdData: action.payload,
    };
  },
  [ActionType.COMPANY_STORE_DATA_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loading: action.payload,
    };
  },
  [ActionType.COMPANY_STORE_DATA_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      data: [],
    };
  },
  [ActionType.COMPANY_DASHBOARD](
    state,
    action,
  ) {
    return {
      ...state,
      dashboard: { ...state.dashboard, [action.payload.key]: action.payload.value },
    };
  },
});

export default companyReducer;