import React from 'react'
import { Spin } from 'antd'
import Text from 'components/atom/text'

const Loading: React.FC<any> = (props) => {

  return (
      <div className={`${props.center ? '_text-center' : ''} ${props.className}`}>
        {props.withText && (
          <Text className="_text-4 _font-weight-bold">
            {props.text ? props.text : "Carregando aguarde..."}
          </Text>
        )}
        <div className="_mt-4">
            <Spin size="large" />
        </div>
      </div>
  )
}

Loading.defaultProps = {
  withText: true,
  center: true
}

export const Rendering: React.FC<any> = (props) => {

  return (
    <div className={`_text-center ${props.className}`}>
      <Text className="_text-4 _font-weight-bold">
          Processando aguarde...
      </Text>
      <div className="_mt-4">
          <Spin size="large" />
      </div>
    </div>
  )
}

export default Loading