import React from 'react'
import { Skeleton } from 'antd'
import Card from 'components/molecule/card'

const SkeletonList = () => {

  return (
    <Card className="_mb-6">
      <Skeleton active={true} title avatar />
      <Skeleton active={true} title avatar className="_mt-6"/>
      <Skeleton active={true} title avatar className="_mt-6"/>
    </Card>
  )
}

export default SkeletonList