import { Dispatch } from "redux";
import * as VehicleEffect from './Effect'
import * as FormEffect from 'store/form/Effect'
import { StateMachineType } from "libs/statemachine"

export interface FindVehicleParams {
  type: string;
  uCodeCompany: string;
  uCodeVehicle?: string;
  start: string;
  end: string;
  companyTag?: string;
}

export interface CheckInsuranceParams {
  plate: string;
  chassi: string;
  manufactureYear: string;
  modelYear: string;
  fipeCode: string;
}

export enum VehicleStatus {
  Active = 'A',
  WaitingForReleased = 'L',
  Inactive = 'I',
  Removed = 'R',
  Waiting = 'W',
}

export const findVehicle = ({ type, uCodeCompany, uCodeVehicle, start, end, companyTag = "" }: FindVehicleParams) => async (dispatch: Dispatch) => {
  
  try {
    VehicleEffect.setVehicleError(dispatch, false)
    VehicleEffect.setVehicleLoading(dispatch, true)

    const vehicles = await VehicleEffect.getVehicle({ type, uCodeCompany, uCodeVehicle, start, end, companyTag })

    if (vehicles.hasError) {
      throw new Error()
    }

    VehicleEffect.setVehicle(dispatch, vehicles?.data)
    VehicleEffect.setVehicleLoading(dispatch, false)

    return vehicles
  } catch (e) {
    VehicleEffect.setVehicleError(dispatch, true)
    VehicleEffect.setVehicleLoading(dispatch, false)
  }
}

export const checkInsurance = ({ plate, chassi, manufactureYear, modelYear, fipeCode }: CheckInsuranceParams) => async (dispatch: Dispatch) => {
  
  try {
    FormEffect.setLoading(dispatch, true)

    const result = await VehicleEffect.checkInsurance({ plate, chassi, manufactureYear, modelYear, fipeCode })

    FormEffect.setLoading(dispatch, false)

    return result
  } catch (e) {
    FormEffect.setLoading(dispatch, false)
    return { hasError: true, data: null }
  }
}

export const getCompanyVehicleSelect = () => async (dispatch: Dispatch) => {
  VehicleEffect.setSelectError(dispatch, false)
  VehicleEffect.setSelectLoading(dispatch, StateMachineType.Loading)

  const result = await VehicleEffect.getCompanyVehicleSelect()

  if (result.hasError) {
    VehicleEffect.setSelectError(dispatch, true)
  } else {
    VehicleEffect.setSelectError(dispatch, false)
    VehicleEffect.setSelectData(dispatch, result.data)
  }

  VehicleEffect.setSelectLoading(dispatch, StateMachineType.Loaded)
}