import React from 'react'
import { TimePicker } from 'antd'

function Time (props: any) {
  return (
    <TimePicker 
      defaultValue={props.defaultValue} 
      format={'HH:mm'} 
      size="large"
      minuteStep={30}
      onOk={props.onSelect}
      onSelect={props.onSelect}
    />
  );
}

export default Time;
