import React from 'react'
import { Card } from 'antd'

import Text from 'components/atom/text'
import ImageLoad from 'components/atom/image_load'
import Button from 'components/atom/button'

interface CardVehicleProps {
  company?: any;
  key?: string;
  onClick?: any;
  className?: string;
}

const CardStore: React.FC<CardVehicleProps> = ({ company, onClick, className }) => {

  const onClickMap = (address: string) => {
    window.open(`https://www.google.com/maps?q=${address}`)
  }

  return (
    <Card onClick={onClick} className={className}>
      <div className="card-store-image-mobile">
        <ImageLoad url={company?.logo} className="card-store-image" />
      </div>
      <div>
        <div className="_text-center">
          <Text strong className="_ml-4 _text-3">{company?.tradingName}</Text>
        </div>
        <div className="_text-center">
          <Text className="_ml-4 _text-1">{company?.address?.full}</Text>
        </div>

        {company?.daysOpen && (
          <div className="card-store-open-company">
            <div className="_text-2 _mb-1 _mt-2 _font-weight-bold"> Horário de funcionamento </div>
            {company?.daysOpen.map((days: any) => (
              <div><Text className="_text-1">{days.description}</Text></div>
            ))}
          </div>
        )}
        
        <Button 
          theme="outline-black"
          onClick={() => onClickMap(company?.address?.full)} 
          className="_mt-4 _ml-2"> 
          Ver no mapa 
        </Button>
      </div>
    </Card>
  )
}

export default CardStore