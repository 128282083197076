import React, { useState } from 'react'
import { Form, Input, Select, DatePicker, Divider } from 'antd'
import { connect } from 'react-redux'
import Text from 'components/atom/text'
import Button from 'components/atom/button'
import * as AccountAction from 'store/account/Action'
import StateMachine, { StateMachineType } from 'libs/statemachine'
import ManageDate from 'libs/date'
import { AppState } from 'store'

const { Option } = Select;

export enum UserDataEditingType {
  Text = "text",
  Options = "option",
  Date = "date",
}

interface UserDataEditingOption {
  value: string;
  text: string;
}

interface UserDataEditingProps {
  accountUpdate: any;
  update: any;
  label: string;
  field: string;
  value: string;
  min?: number;
  max?: number;
  required?: boolean;
  type?: UserDataEditingType;
  option?: UserDataEditingOption[];
  additional?: any
}

const UserDataEditing: React.FC<UserDataEditingProps> = ({ accountUpdate, update, label, field, value, min, max, required = true, type = "text", option = [], additional = {} }) => {

  const [editing, showEditing] = useState(false)
  const [editingValue, setEditingValue] = useState(value)
  const [additionalData, setAdditionalData] = useState(additional)

  const onSubmit = async ({ dataEditing }: any) => {
    let dataUpdate = dataEditing

    switch(type) {
      case UserDataEditingType.Date:
        dataUpdate = ManageDate.formatOnlyDate(dataUpdate)
        break;
    }

    const result = await accountUpdate({ fields: [{ field, value: dataUpdate, additional: additionalData }] })

    if (!result.hasError) {
      const dataField = result.data?.result.find((update: any) => update.field === field)
      
      if (dataField?.additional) {
        setAdditionalData(dataField?.additional)
      }

      showEditing(false)

      switch(type) {
        case UserDataEditingType.Text:
          return setEditingValue(dataEditing)
        case UserDataEditingType.Options:
          return setEditingValue(option.find(opt => opt.value === dataEditing)?.text || "")
        case UserDataEditingType.Date:
          return setEditingValue(dataEditing)
      }
    }
  }

  const renderElementValue = () => {
    switch (type) {
      case UserDataEditingType.Text:
        if (!editingValue) {
          return (<div>
            <Text className="_mt-02 _text-4 _text-color-light-grey">Não fornecido</Text>
          </div>)
        }

        return (
          <div>
            <Text className="_mt-02 _text-4">{editingValue}</Text>
          </div>
        )
      case UserDataEditingType.Options:
        if (!editingValue) {
          return (<div>
            <Text className="_mt-02 _text-4 _text-color-light-grey">Selecione uma opção</Text>
          </div>)
        }

        const dataOption = option.find(opt => opt.value === editingValue)
        const optionText = dataOption ? dataOption?.text : editingValue

        return (
          <div>
            <Text className="_mt-02 _text-4">{optionText}</Text>
          </div>
        )
      case UserDataEditingType.Date:
        if (!editingValue) {
          return (<div>
            <Text className="_mt-02 _text-4 _text-color-light-grey">Selecione uma data</Text>
          </div>)
        }

        return (
          <div>
            <Text className="_mt-02 _text-4">{ManageDate.formatBrazil(editingValue)}</Text>
          </div>
        )
    }
  }

  const renderElementEditing = () => {
    const rules = []

    if (required) {
      rules.push({ required: true, message: 'Este campo não pode ser vazio.' })
    }

    switch (type) {
      case UserDataEditingType.Text:
        if (min) {
          rules.push({ min, message: `Este campo deve ter no mínimo ${min} letras.`})
        }

        if (max) {
          rules.push({ max, message: `Este campo deve ter no máximo ${max} letras.`})
        }

        return (
          <Form.Item
            name="dataEditing"
            hasFeedback
            rules={rules}
          >
            <Input size="large" />
          </Form.Item>
        )
      case UserDataEditingType.Options:
        return (
          <Form.Item
            name="dataEditing"
            hasFeedback
            rules={rules}
          >
            <Select size="large">
              {option.map(opt => (
                <Option value={opt.value}>{opt.text}</Option>  
              ))}
            </Select>
          </Form.Item>
        )
      case UserDataEditingType.Date:
        return (
          <Form.Item
            name="dataEditing"
            hasFeedback
            rules={rules}
          >
            <DatePicker size="large" className="_mt-02 _pl-4 _pr-4" format="DD/MM/YYYY" />
          </Form.Item>
        )
    }
  }

  const getDefaultValue = () => {
    switch(type) {
      case UserDataEditingType.Date:
        return editingValue ? ManageDate.mountDate(editingValue) : null
      default:
        return editingValue
    }
  }

  return (
    <div>
      <div className="_grid-columns-2">
        <Text className="_text-2 _font">{label}</Text>
        <Text 
          onClick={() => showEditing(!editing)}
          className="_pl-06 _text-2 _font-weight-bold _text-color-action _text-right _cursor_pointer"
        >
          {editing? 'Cancelar' : editingValue ? 'Editar' : 'Adicionar'}
        </Text>
      </div>
      {!editing? (
        <>{renderElementValue()}</>
      ) : (
        <div className="_mt-2">
          <Form initialValues={{ dataEditing: getDefaultValue() }} name="basic" onFinish={onSubmit} layout="vertical">
            {renderElementEditing()}
            <Form.Item>
              <Button className="_button_default_action" htmlType="submit"  theme="primary" size="large" loading={StateMachine.in(update?.loading, StateMachineType.Loading)}>
                {StateMachine.in(update?.loading, StateMachineType.Loading)? 'Salvando...' : 'Salvar'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      <Divider />
    </div>
  )
}

const mapStateToProps = ({ account }: AppState) => {
  return {
    update: account?.update,
  }
}

const mapDispatchToProps = {
  accountUpdate: AccountAction.accountUpdate,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDataEditing);