import baseReducer from 'store/utilities/baseReducer'
import ActionType from './ActionType'

export interface IBookState {
  readonly loadingInitial: boolean | null;
  readonly loading: boolean | null;
  readonly data: any | null;
  readonly vehicle: any | null;
  readonly rentalRate: any | null;
}

const initialState: IBookState = {
  loadingInitial: true,
  loading: false,
  data: null,
  vehicle: null,
  rentalRate: null
};

const landingReducer = baseReducer(initialState, {
  [ActionType.BOOK_INITIAL_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loadingInitial: action.payload,
    };
  },
  [ActionType.BOOK_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loading: action.payload,
    };
  },
  [ActionType.BOOK_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      data: action.payload,
    };
  },
  [ActionType.BOOK_VEHICLE](
    state,
    action,
  ) {
    return {
      ...state,
      vehicle: action.payload,
    };
  },
  [ActionType.BOOK_RENTAL_RATE](
    state,
    action,
  ) {
    return {
      ...state,
      rentalRate: action.payload,
    };
  },
});

export default landingReducer