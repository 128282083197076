// @ts-nocheck

import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
// import { useGoogleMap } from '@react-google-maps/api'
// import { useTranslation } from 'react-i18next'
import { Marker } from '@react-google-maps/api'
import { Input } from 'antd'
import qs from 'qs'
// import moment from 'moment'
import { AppState } from 'store'
import { CompanySearched, companiesSelector } from 'store/company/Selector'
// import RangePicker from 'components/molecule/rangepicker'
import SkeletonList from 'components/molecule/skeleton_list'
import { QuerySearch } from 'components/molecule/search'
import EmptyStore from 'components/molecule/empty/store'
// import DatePickerDrawer from 'components/molecule/datepicker_drawer'
import Body from 'components/atom/body'
import Button from 'components/atom/button'
// import Marker from 'components/atom/marker'
import Layout from 'components/atom/layout'
import Header from 'components/organism/header'
import Footer from 'components/organism/footer'
import CardStore from 'pages/UserSearchStore/card'
// import Chat from 'components/organism/chat'

import MapComponent from './map'

import { ReactComponent as MapSvg } from './assets/map.svg'

import * as CompanyAction from 'store/company/Action'

import './styles.scss'

const { Search } = Input;

interface SearchStoreProps {
  findCompanyStore: any;
  loading: any;
}

const UserSearchStore: React.FC<SearchStoreProps> = ({ findCompanyStore, favoriteCompany, filterCompanyStore, loading }) => {
  // const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const [map, setMap] = useState(null)
  const [filter, setFilter] = useState({})
  // const [isChatShowing, showChat] = useState(false)
  const [companyChatSelect, setCompanyChat] = useState(null)
  const companies = useSelector(companiesSelector)
  const params: QuerySearch = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
  const [searchCity, setSearchCity] = useState(params.city)

  useEffect(() => {
    window.scrollTo(0, 0)
    findCompanyStore(params.city)
  }, [findCompanyStore, params.city])

  // const onSelectStore = (type: string, uCode: string) => {
  //   history.push(`/pages/vehicle?city=${params.city}&type=${type}&uCode=${uCode}&start=${params.start}&end=${params.end}`)
  // }
  
  // const onSelectDate = (value: any) => {
  //   const start = value[0]
  //   const end = value[1]

  //   history.push(`/pages/store?city=${params.city}&start=${start.format("YYYY-MM-DDTHH:mm")}&end=${end.format("YYYY-MM-DDTHH:mm")}`)
  // }

  // const disabledDate = (current: any) => {
  //   return current && current < moment().endOf('day')
  // }

  const onSelectOnMap = (event: any, company: any) => {
    // showChat(false)

    const latLng = new google.maps.LatLng(company.address.lat, company.address.lng)
    map.panTo(latLng)
    if (map.getZoom() !== 16) {
      map.setZoom(16)
    }
    event.stopPropagation()
  }

  const onFavoriteCompany = (event: any, company: any) => {
    favoriteCompany({ uCodeCompany: company.uCode })
    event.stopPropagation()
  }

  const onLoadedMap = (map) => {
    setMap(map)
    
    var bounds = new google.maps.LatLngBounds()
    companies.forEach((company: CompanySearched) => {
      bounds.extend(new google.maps.LatLng(company.address.lat ,company.address.lng))
    })

    map.fitBounds(bounds) 
  }

  // const apiIsLoaded = (map) => {
  //   setMap(map)

  //   var bounds = new google.maps.LatLngBounds()
  //   companies.forEach((company: CompanySearched) => {
  //     bounds.extend(new google.maps.LatLng(company.address.lat ,company.address.lng))
  //   })

  //   map.fitBounds(bounds)
  // }

  // const onClickDateMobile = ({ startFormatDate, endFormatDate }) => {
  //   history.push(`/pages/store?city=${params.city}&start=${startFormatDate}&end=${endFormatDate}`)
  // }

  const onChangeSearch = (event) => setSearchCity(event.target.value)

  const onSearch = () => {
    history.push(`/pages/store?city=${searchCity}`)
  }

  const onClickChat = (event, company) => {
    // history.push(`/pages/store?city=${searchCity}`)
    console.log(company)
    setCompanyChat(company)
    // showChat(true)
  }

  const onFilterModalidade = (modalidade: string) => {
    if (modalidade !== filter.modalidade) {
      setFilter({ modalidade })
      filterCompanyStore({ modalidade })
    } else {
      setFilter({})
      filterCompanyStore(null)
    }
  }

  return (
    <Layout>
      <Header />
      <Body className="search-store-body">
        <div className="search-store-content">
          <div className="search-store-data" style={{ height: '89vh', width: '100%' }}>
            <div className="search-store-header _mb-4">
              <div className="search-store-header-city _mt-4 _font-weight-bold">Locadoras de acordo com sua busca</div>
              {/*&nbsp;&nbsp; <span className="search-store-change _font-weight-bold">{t('change')}</span>*/}
              <Search defaultValue={params.city} className='_mt-4' placeholder="Digite a cidade que deseja buscar" allowClear onChange={onChangeSearch} onSearch={onSearch} />
              <div className="_mt-2">
                <div className="_font-weight-bold _text-3">Filtros</div>
                <div className="mt-2 p-2 border rounded border-slate-200 bg-slate-100">
                  <div className="mb-1">Selecione uma modalidade</div>
                  <Button theme={filter?.modalidade === "Diária" ? "primary" : "outline-black"} onClick={() => onFilterModalidade("Diária")}>Diária</Button>
                  <Button className="_ml-2" theme={filter?.modalidade === "Frota" ? "primary" : "outline-black"} onClick={() => onFilterModalidade("Frota")}>Frota</Button>
                </div>
              </div>
            </div>
            {!loading && (
              <div className='_mt-4 _mb-4 _text-2'><b>{companies.length}</b> locadora(s) encontradas</div>
            )}

            {/* <div className="_mb-4">
              <span>{t('chosen_date')}</span>

              <div className="search-store-header-date _mt-2">
                <RangePicker
                  onSelect={onSelectDate}
                  onSelectClick={onSelectDate}
                  disabledDate={disabledDate}
                  initialDate={{ start: params.start, end: params.end }}
                />
              </div>

              <div className="search-store-header-date__mobile _mt-2">
                <DatePickerDrawer startDate={params.start} endDate={params.end} onClick={onClickDateMobile} />
                <div className="_text-center _text-1 _mt-1">{t('change_date_mobile')}</div>
              </div>
            </div> */}
            {loading ? (
              <SkeletonList />
            ) : (
              <>
                {companies.map((company: CompanySearched) => (
                  <CardStore
                    onClickMap={(e: any) => onSelectOnMap(e, company)}
                    onClickFavorite={(e: any) => onFavoriteCompany(e, company)}
                    onClickChat={(e: any) => onClickChat(e, company)}
                    key={company.uCode}
                    logoUrl={company.logo}
                    tradingName={company.tradingName} 
                    companyTag={company.companyTag} 
                    site={company.site}
                    instagram={company.instagram}
                    facebook={company.facebook}
                    linkedin={company.linkedin}
                    whatsapp={company.whatsapp}
                    phone={company.phoneContact}
                    favorited={company.favorited}
                    loading={company.loading}
                    address={company.address}
                    timeOpenDesc={company.timeOpenDesc}
                    modalidade={company.modalidade}
                    additionalInformation={company.additionalInformation} />
                ))}

                {companies.length <= 0 && (
                  <EmptyStore />
                )}
              </>
            )}
          </div>

          {/* {isChatShowing ? (
            <Chat company={companyChatSelect} />
          ) : ( */}
            <div className="search-store-map" style={{ height: '89vh', width: '100%', overflow: 'hidden' }}>
              { loading ? (
                <div className="search-store-container-map-loading">
                  <MapSvg className="search-store-map-loading" />
                </div>
              ) : (
                <MapComponent onLoadedMap={onLoadedMap}>
                  {companies.map((company: CompanySearched) => (
                    <Marker
                      key={company.uCode}
                      title={company.tradingName}
                      icon={{
                        path:
                          "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
                        fillColor: "#FCA311",
                        fillOpacity: 0.9,
                        scale: 2,
                        strokeColor: "gold",
                        strokeWeight: 2,
                      }}
                      position={{ 
                        lat: Number(company.address.lat), 
                        lng: Number(company.address.lng) 
                      }}
                    />
                ))}
                </MapComponent>
              )}
            </div>
          {/* )} */}
        </div>
      </Body>
      <Footer />
    </Layout>
  );
}

const mapStateToProps = ({ company }: AppState) => {
  return {
    loading: company?.loading
  }
}

const mapDispatchToProps = {
  findCompanyStore: CompanyAction.findCompanyStore,
  favoriteCompany: CompanyAction.favoriteCompany,
  filterCompanyStore: CompanyAction.filterCompanyStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchStore);