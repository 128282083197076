import React from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from 'antd'
import Text from 'components/atom/text'
import ImageLoad from 'components/atom/image_load'


import { ReactComponent as User } from './images/user.svg'
import { ReactComponent as Bag } from './images/bag.svg'
import { ReactComponent as Snowflake } from './images/snowflake.svg'
import { ReactComponent as Shift } from './images/shift.svg'
import { ReactComponent as CarDoor } from './images/car-door.svg'

import './index.scss'

const DescriptionVehicle: React.FC<any> = ({ vehicle, className }) => {
  const { t } = useTranslation()

  return (
    <Space className={`_mt-2 ${className ? className : ''}`} wrap>

      {vehicle?.seats && (
        <div className="vehicle-option-box">
          <div className="vehicle-option _pl-2 _pr-2 _pt-2 _pb-2">
            <User className="vehicle-option-icon" />
            <Text className="_text-1 _ml-2 vehicle-option-box__data">{`${vehicle?.seats} lugares`}</Text>
          </div>

          <Text className="_text-1 _mt-1 vehicle-option-box__data-mobile _font-weight-bold">{vehicle?.seats}</Text>
        </div>
      )}

      {vehicle?.doors && (
        <div className="vehicle-option-box">
          <div className="vehicle-option _pl-2 _pr-2 _pt-2 _pb-2">
            <CarDoor className="vehicle-option-icon" />
            <Text className="_text-1 _ml-2 vehicle-option-box__data">{`${vehicle?.doors} portas`}</Text>
          </div>

          <Text className="_text-1 _mt-1 vehicle-option-box__data-mobile _font-weight-bold">{vehicle?.doors}</Text>
        </div>
      )}

      {vehicle?.baggageQuantity && (
        <div className="vehicle-option-box">
          <div className="vehicle-option _pl-2 _pr-2 _pt-2 _pb-2">
            <Bag className="vehicle-option-icon" />
            <Text className="_text-1 _ml-2 vehicle-option-box__data">{`${vehicle?.baggageQuantity} malas`}</Text>
          </div>

          <Text className="_text-1 _mt-1 vehicle-option-box__data-mobile _font-weight-bold">{vehicle?.baggageQuantity}</Text>
        </div>
      )}

      {vehicle?.airConditioning && (
        <div className="vehicle-option-box">
          <div className="vehicle-option _pl-2 _pr-2 _pt-2 _pb-2">
            <Snowflake className="vehicle-option-icon" />
            <Text className="_text-1 _ml-2 vehicle-option-box__data">A/C</Text>
          </div>

          <Text className="_text-1 _mt-1 vehicle-option-box__data-mobile _font-weight-bold">A/C</Text>
        </div>
      )}

      {vehicle?.abs && (
        <div className="vehicle-option-box">
          <div className="vehicle-option _pl-2 _pr-2 _pt-2 _pb-2">
            <ImageLoad url="https://closecar-public.s3.amazonaws.com/abs.png" className="vehicle-option-icon-abs" />
            <Text className="_text-1 _ml-2 vehicle-option-box__data">ABS</Text>
          </div>

          <Text className="_text-1 _mt-1 vehicle-option-box__data-mobile _font-weight-bold">ABS</Text>
        </div>
      )}

      <div className="vehicle-option-box">
        <div className="vehicle-option _pl-2 _pr-2 _pt-2 _pb-2">
          <Shift className="vehicle-option-icon" />
          <Text className="_text-1 _ml-2 vehicle-option-box__data">{t(vehicle?.carGearbox)}</Text>
        </div>

        <Text className="_text-1 _mt-1 vehicle-option-box__data-mobile _font-weight-bold">{t(vehicle?.carGearbox)}</Text>
      </div>
    </Space>
  )
}

export default DescriptionVehicle;