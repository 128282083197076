import React from 'react'

import { Card } from 'antd'
import Text from 'components/atom/text'

import { ReactComponent as EmptyStoreSvg } from './assets/empty_store.svg'

import './index.scss'

const EmptyStore = () => {
  return (
    <Card className="empty-store-content">
      <div>
        <Text className="empty-store-content-title">Ops! Infelizmente não encontramos nenhuma loja nessa cidade. </Text>
      </div>
      <div className="_mt-4">
        <Text className="empty-store-content-subtitle">Tente buscar em cidades próximas. </Text>
      </div>

      <EmptyStoreSvg className="empty-store-img" />
    </Card>
  )
}

export default EmptyStore