import React, { useRef, useState } from 'react'
import { Form, Input } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import { connect } from 'react-redux'
import { PlusOutlined, SyncOutlined } from '@ant-design/icons'
import ImageLoad from 'components/atom/image_load'
import Button from 'components/atom/button'
import { AppState } from 'store'
import * as DocumentAction from 'store/document/Action'

import './styles.scss'

const FormImage: React.FC<any> = ({ name, fileKey, upload, data, disabled = false, remove, documentType = null, title = null, url = null, onSuccess, position }) => {
  const fileInput: any = useRef(null)
  const document = data?.find((doc: any) => doc.fileKey === fileKey)
  const [uploadState, setUploadState] = useState<any>({ loading: null, url: document?.url || url, fileKey: document?.fileKey, success: null, position })

  const onHandleUpload = (e: any) => {
    if (uploadState.loading || disabled) {
      e.preventDefault()
    }
    
    fileInput.current.click()
  }

  const submitUpload = async () => {
    const { current } = fileInput;

    if (current && current.files.length > 0) {
      const [file] = current.files
      setUploadState({ ...uploadState, loading: true })
      const data = await upload({ file, type: file.type, documentType, position })

      if (!data.hasError) {
        setUploadState({ ...uploadState, success: true, loading: false, url: data.url, fileKey: data.fileKey, position })  
        onSuccess && onSuccess(data)
      } else {
        setUploadState({ ...uploadState, loading: false })
      }
    }
  }

  const removeUpload = () => {
    remove({ fileKey: uploadState.fileKey })
    setUploadState({ loading: null, url: null, success: null })
  }

  return (
    <div>
      <Form.Item name={name}>
        <Input size="large" type="hidden" />
        <div className="molecule-form-image-content">
          {uploadState.success || uploadState?.url ? (
            <div>
              <ImageLoad 
                url={uploadState?.url}
                ableToOpen={true}
                title={title}
              />
              <Button theme="outline-primary" icon={<DeleteOutlined />} className="_mt-4" block onClick={() => removeUpload()}> 
                Remover
              </Button>
            </div>
          ):(
            <div className="molecule-form-image-box-container">
              <div onClick={onHandleUpload} className="molecule-form-image-box">
                {uploadState?.loading? (
                  <SyncOutlined spin />
                ) : (
                  <PlusOutlined className="molecule-form-image-box__icon" />
                )}
                <input onChange={submitUpload} className="molecule-form-image-box__input-file" ref={fileInput} type="file" />
              </div>
              {title && (
                <div className="_text-3 _font-weight-bold _mt-4 _text-center"> {title} </div>
              )}
            </div>
          )}
        </div>
      </Form.Item>
    </div>
  )
}

const mapStateToProps = ({ document }: AppState) => {
  return {
    loading: document?.loading,
    data: document?.data,
    success: document?.success,
  }
}

const mapDispatchToProps = {
  upload: DocumentAction.upload,
  remove: DocumentAction.remove
}

export default connect(mapStateToProps, mapDispatchToProps)(FormImage);