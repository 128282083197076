import { createSelector } from "reselect"
import { AppState } from "store"
import { IVehicleState } from "./Reducer"

export interface VehicleSearched {
  uCode?: string;
  groupTypeRegister?: string;
  vehicleDesc?: string;
  vehicleCode?: string,
  vehicleModelName: string;
  vehicleModelCode?: string;
  category?: string;
  seats: number;
  literEngine?: string;
  fuel?: string;
  steering?: string;
  carGearbox: string;
  doors: number;
  baggageQuantity: number;
  airConditioning: boolean;
  kmUnlimited: boolean;
  rentalRate: {
    byHour: boolean;
    hourCharge: { 
      key: string;
      total: number;
      totalFormat: string;
      currencyCode: string;
      price: number;
      priceFormat: string;
      quantity: number;
    };
    dayCharge: { 
      key: string;
      total: number;
      totalFormat: string;
      currencyCode: string;
      price: number;
      priceFormat: string;
      quantity: number;
    };
    taxPartnerCharge: {
      key: string;
      total: number;
      totalFormat: string;
      tax?: number;
      currencyCode: string;
    };
    discountCharge: {
      key: string;
      total: number;
      totalFormat: string;
      percentage: number;
      currencyCode: string;
      isValid: boolean;
      message: string;
    },
    totalCharge: {
      total: number;
      totalFormat: string;
    },
    insurance: {
      total: number;
      totalFormat: string;
      deductible: number;
      deductibleFormat: string;
    },
    taxAdminstration: {
      total: number;
      totalFormat: string;
      percentage: number;
    }
  };
  company: any;
}

function vehiclesSearched(vehicle: IVehicleState): any {
  return vehicle?.data || []
}

export const vehiclesSelector = createSelector<AppState, any, any>(
  (state) => state?.vehicle,
  vehiclesSearched,
);