import React, { useEffect, useState } from 'react'
import './styles.scss'

type Props = {
  url: string,
  className?: string,
  ableToOpen?: boolean,
  title?: string,
  alt?: string,
}

const Image = ({ url, className, ableToOpen, title, alt }: Props) => {
  const [imageUrl, setImageUrl] = useState(url)

  const isDocument = imageUrl.indexOf(".pdf") >= 0

  useEffect(() => {
    setImageUrl(url)
  }, [url])

  const openImageNewWindow = () => { 
    if (!ableToOpen) {
      return false
    }

    const checkPdf = imageUrl.indexOf(".pdf") >= 0 || url.indexOf(".pdf") >= 0

    if (checkPdf) {
      window.open(url, "_blank")
    } else {
      window.open(imageUrl, "_blank")
    }
  }

  return <div onClick={openImageNewWindow} className={`${ableToOpen && '_cursor_pointer'} image_base_container`}>
    <img 
      src={imageUrl} 
      alt={alt} 
      style={{ maxWidth: "100%", height: "auto", display: "block" }} 
      className={className}
      onError={() => setImageUrl( isDocument ? "https://53cars.s3-sa-east-1.amazonaws.com/document.png" : "https://53cars.s3-sa-east-1.amazonaws.com/no-camera.png")} />

    {title && (
      <>
        {isDocument ? ( 
          <div className="_text-center _text-3 _font-weight-bold _mt-2"> {title ? title : 'Documento'} </div>
        ) : (
          <div className="_text-center _text-3 _font-weight-bold _mt-2"> {title} </div>
        )}
      </>
    )}

    {ableToOpen && (<div className={`_text-center _text-1 ${ !isDocument && '_mt-2'}`}> Clique para abrir </div>)}
  </div>
}

export default Image