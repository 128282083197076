import baseReducer from '../utilities/baseReducer'
import ActionType from './ActionType'

export interface ICheckTokenState {
  readonly loading: boolean | null;
  readonly data: any | null;
}

const initialState: ICheckTokenState = {
  loading: false,
  data: null
};

const landingReducer = baseReducer(initialState, {
  [ActionType.CHECKTOKEN_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loading: action.payload,
    };
  },
  [ActionType.CHECKTOKEN_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      data: action.payload,
    };
  },
});

export default landingReducer