import { ErrorPayload } from 'libs/fetch/interface'
import baseReducer from '../utilities/baseReducer'
import ActionType from './ActionType'

export interface IFormState {
  readonly loading: boolean | null;
  readonly loadingStart: boolean | null;
  readonly error: ErrorPayload | null;
  readonly errorPermission: boolean | null;
  readonly success: boolean | null;
  readonly data: any | null;
  readonly editData: any | null;
}

const initialState: IFormState = {
  loading: false,
  loadingStart: true,
  error: null,
  errorPermission: null,
  success: null,
  data: null,
  editData: {},
};

export default baseReducer(initialState, {
  [ActionType.FORM_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loading: action.payload,
    };
  },
  [ActionType.FORM_LOADING_START](
    state,
    action,
  ) {
    return {
      ...state,
      loadingStart: action.payload,
    };
  },
  [ActionType.FORM_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      error: action.payload,
    };
  },
  [ActionType.FORM_PERMISSION_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      errorPermission: action.payload,
    };
  },
  [ActionType.FORM_SUCCESS](
    state,
    action,
  ) {
    return {
      ...state,
      success: action.payload,
    };
  },
  [ActionType.FORM_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      data: action.payload,
    };
  },
  [ActionType.FORM_DATA_EDITING](
    state,
    action,
  ) {
    return {
      ...state,
      editData: action.payload,
    };
  },
});