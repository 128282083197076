import React from 'react'
import Text from "components/atom/text"

import './styles.scss'

function Footer() {
  return (
    <div className="footer-content-base _pt-6 _pb-6">
      <div className="_mb-6 _text-center">
        <Text className="_text-4">Atendimento ao cliente</Text>
      </div>

      <div className="_text-center">
        <div>
          <a href="https://closecar-public.s3.amazonaws.com/Politica+de+Privacidade+da+CloseCar.pdf" target="_blank" rel="noreferrer">
            Politica de Privacidade
          </a>
          <br />
          <a href="https://closecar-public.s3.amazonaws.com/Termos+de+Uso+-+Usuario+e+CloseCar.pdf" target="_blank" rel="noreferrer">
            Termos de uso do usuário
          </a>
          <br />
          <br />
          <div className="_pl-8 _pr-8"> 
            Nós da CloseCar apenas divulgamos dados públicos das locadoras da sua região, mas não realizamos nenhuma intermediação em nome das mesmas no nosso site nem mesmo contatos telefônicos e ou whatsapp. Para mais informações consulte nossos termos de uso e politica de privacidade. 
          </div>
          <br />
          <div>
            Caso queira entre em contato através do e-mail atendimento@closecar.com.br
          </div>
          <br />
        </div>
      </div>

      {/* <div className="_mb-6 _mt-6 _text-center">
          <Text className="_text-4">Formas de Pagamento</Text>
      </div>

      <div className="_mb-6 _text-center">
        <img alt="" src={visa} className="_mr-1" />
      </div> */}

      <div className="_text-center">
        <Text className="_text-1"> A Close Cars faz parte da Easy APP S.A, que é uma sociedade anônima registrada na República Federativa do Brasil, cuja sede social está em Belo Horizonte - MG.</Text> <br />
        <Text className="_text-1"> © 2022 closecar.com.br Todos os direitos reservados. </Text>
      </div>

      {/* <div className="landpage-subscription_whatsapp">
        <a href="https://wa.me/5531984330552" target="noopener noreferrer _blank">
          <ImageLoad url="https://53cars.s3.sa-east-1.amazonaws.com/whatsapp.png" width={64} />
        </a>
      </div> */}
    </div>
  );
}

export default Footer;
