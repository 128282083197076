import React, { useEffect, useState } from 'react'
import { Avatar, Drawer } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { useStorage } from 'libs/storage'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as CheckTokenAction from 'store/checktoken/Action'
import Config from 'libs/config'
import { AppState } from 'store'
import { Instagram } from 'assets'
import './index.scss'

const HeaderSubscription: React.FC<any> = ({ checkToken, withLogo, withBackground }) => {

  const { t } = useTranslation()
  const { authorization } = useStorage()
  const history = useHistory()
  const [menu, showMenu] = useState(false)

  useEffect(() => {
    checkToken()
  }, [])

  const goHome = () => {
    history.push('/')
  }

  const openMenu = () => {
    showMenu(true)
  }

  const closeMenu = () => {
    showMenu(false)
  }

  const goAccountOrLogin = () => {
    let url = '/account/user'

    switch (authorization?.role) {
      case Config.role.admin:
        url = '/account/admin/dashboard'
        break;
      case Config.role.user:
        url = '/account/user'
        break;
      case Config.role.company:
        url = '/account/company/dashboard'
        break;
      case Config.role.operator:
        url = '/account/company/dashboard'
        break;
      case Config.role.finance:
        url = '/account/company/dashboard'
        break;
    }

    history.push(authorization ? url : '/signin')
  }

  const [name] = authorization?.name || "U"

  return (
    <div className={`molecule-header ${withBackground && 'molecule-header__background'}`}>
      <div>
        {withLogo && (
          <div onClick={goHome} className="molecule-header__logo">
            <img src="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal.png" className="molecule-header__logo" />
          </div>
        )}

        <div onClick={openMenu} className="molecule-header__mobile-menu">
          <MenuOutlined className="molecule-header__menu-mobile" />
          <div className="molecule-header__logo-mobile-box">
            {withLogo && (
              <img src="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal.png" className="molecule-header__logo-mobile" />
            )}
          </div>
          {/*<div className="_text-2 molecule-header__menu-mobile-login">
            { authorization ? 
            (<div onClick={goAccountOrLogin}>
              Minha conta

              <Avatar className="_ml-4" size="large" style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                {name}
              </Avatar>
            </div>)
            :
            t('login')}
            </div>*/}
        </div>
      </div>

      <div className="molecule-header__menu">
        {/*<Facebook className="molecule-header__social-icon _mr-4" />*/}
        <a href="https://www.instagram.com/close_car/" target="_blank" rel="noopener noreferrer">
          <Instagram className="molecule-header__social-icon _ml-8 _mr-8" />
        </a>

        <div onClick={goAccountOrLogin} className="molecule-header__button-login _ml-4 _mr-8">
          {authorization ? 
            (<>
              <Avatar className="_mr-2" size="small" style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                {name}
              </Avatar>
              {authorization?.name}
            </>)
            :
            t('login')}
        </div>
      </div>

      <Drawer
          title={<img src="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal.png" className="molecule-header__logo-mobile" />}
          placement="left"
          closable={true}
          onClose={closeMenu}
          visible={menu}>
          <p onClick={() => history.push('/')}>Página Inicial</p>
          <p onClick={() => history.push('/signin')}>Login</p>
          <p onClick={() => history.push('/signup')}>Cadastre-se</p>
        </Drawer>
    </div>
  );
}

const mapStateToProps = ({ checkToken }: AppState) => {
  return {
    loading: checkToken?.loading
  }
}

const mapDispatchToProps = {
  checkToken: CheckTokenAction.checkToken,
}

HeaderSubscription.defaultProps = {
  withLogo: true,
  withBackground: true
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSubscription);