import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import * as CheckTokenAction from 'store/checktoken/Action'
import * as VehicleAction from 'store/vehicle/Action'
import { VehicleSearched, vehiclesSelector } from 'store/vehicle/Selector'

import Layout from 'components/atom/layout'
import Body from 'components/atom/body'
import Header from 'components/organism/header'
import Footer from 'components/organism/footer'
import Content from 'components/atom/content'
import SearchVertical, { QuerySearchVertical } from 'components/molecule/search_vertical'
import SkeletonList from 'components/molecule/skeleton_list'
import EmptyVehicle from 'components/molecule/empty/vehicle'

import CardVehicle from 'pages/UserSearchVehicle/card'
import CardGroupVehicle from 'pages/UserSearchVehicle/cardGroup'
import { AppState } from 'store'
import ManageDate from 'libs/date'
import CardStore from 'pages/UserSearchStore/card'
import DatePickerDrawer from 'components/molecule/datepicker_drawer'

interface SearchVehicleProps {
  findVehicle: any;
  loading: any;
  checkToken: any;
  dataAuth: any;
  loadingAuth: any;
  error: any;
}

export interface QuerySearch {
  city?: string;
  type?: string;
  uCode?: string;
  start?: string;
  end?: string;
}

const UserSearchVehicle:  React.FC<SearchVehicleProps> = ({ checkToken, findVehicle, loading, loadingAuth, dataAuth, error }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { companyTag = "" } = useParams<{companyTag: string}>()
  const vehicles = useSelector(vehiclesSelector)
  const params: QuerySearch = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
  const [firstVehicle] = vehicles || []
  const [searchDate, setSearchDate] = useState({ 
    startDate: params.start || `${ManageDate.nowWithFormat()}T10:00`,
    endDate: params.end || `${ManageDate.nowWithFormat(3)}T10:00`
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    checkToken()
    findVehicle({ uCodeCompany: params.uCode, start: searchDate.startDate, end: searchDate.endDate, companyTag })
  }, [])

  const onSelectVehicle = (vehicle: VehicleSearched | undefined) => {
    if (dataAuth?.hasError) {
      history.push('/signin', {
        redirect: true,
        urlToRedirect: companyTag.length > 0 ? `/my/${companyTag}` : `/pages/vehicle?city=${params.city || firstVehicle?.company.address.city}&uCode=${params.uCode}&start=${searchDate.startDate}&end=${searchDate.endDate}`,
        dataToRedirect: vehicle,
        messageToRedirect: 'Para concluir o seu aluguel você deve logar primeiro.'
      })
    } else {
      history.push(`/pages/vehicle/rent/detail?uCode=${vehicle?.uCode}&city=${params.city || firstVehicle?.company.address.city}&start=${searchDate.startDate}&end=${searchDate.endDate}`)
    }
  }

  const onSearch = (query: QuerySearchVertical) => {
    const start = `${query.start}T${query.startTime}`
    const end = `${query.end}T${query.endTime}`

    findVehicle({ uCodeCompany: params.uCode, start, end, companyTag })
  }

  const onClickDateMobile = ({ startFormatDate, endFormatDate }: { startFormatDate: string; endFormatDate: string; }) => {
    setSearchDate({ startDate: startFormatDate, endDate: endFormatDate })
    findVehicle({ uCodeCompany: params.uCode, start: startFormatDate, end: endFormatDate, companyTag })
  }

  return (
    <div>
      <Layout>
        <Header />
        <Body className="user-search-vehicle-content">
          <Content>
            <div className="user-search-vehicle-content-grid">
              <div className="_p-4 user-search-vehicle-content-search">
                <SearchVertical
                  defaultFields={{ 
                    city: params.city,
                    start: ManageDate.mountDate(searchDate.startDate),
                    end: ManageDate.mountDate(searchDate.endDate),
                  }}
                  onSearch={onSearch} 
                />
              </div>
              
              <div className="user-search-vehicle-content-search-mobile">
                <div>{t('chosen_date')}</div>
                <DatePickerDrawer startDate={searchDate.startDate} endDate={searchDate.endDate} onClick={onClickDateMobile} />
              </div>

              <div className="user-search-vehicle-content-grid-data">
                { loading ? (
                  <SkeletonList />
                ) : (
                  <>
                    {error ? (
                      <EmptyVehicle city={params.city || firstVehicle?.company.address.city} start={searchDate.startDate} end={searchDate.endDate} />
                    ) : (
                      <>
                        {vehicles?.length > 0 && (
                          <CardStore
                            className="_mb-6"
                            key={firstVehicle?.company.uCode}
                            logoUrl={firstVehicle?.company.logo}
                            tradingName={firstVehicle?.company.tradingName} 
                            timeOpenDesc={firstVehicle?.company?.daysOpen}
                            address={firstVehicle?.company.address}
                            showButtons={false} />
                        )}

                        {vehicles?.map((vehicle: VehicleSearched) => (
                          <>
                            {vehicle.groupTypeRegister === 'FULL' ? (
                              <CardVehicle 
                                className="_mb-4"
                                showRentButton={!loadingAuth}
                                key={vehicle.uCode} 
                                vehicle={vehicle} 
                                onClick={() => onSelectVehicle(vehicle)} 
                              />
                            ):(
                              <CardGroupVehicle 
                                className="_mb-4"
                                showRentButton={!loadingAuth}
                                key={vehicle.uCode} 
                                vehicle={vehicle}
                                onClick={() => onSelectVehicle(vehicle)} 
                              />
                            )}
                          </>
                        ))}

                        {vehicles?.length <= 0 && (
                          <EmptyVehicle city={params.city || firstVehicle?.company.address.city} start={searchDate.startDate} end={searchDate.endDate} />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </Content>
        </Body>
        <Footer />
      </Layout>
    </div>
  );
}

const mapStateToProps = ({ checkToken, vehicle }: AppState) => {
  return {
    loading: vehicle?.loading,
    loadingAuth: checkToken?.loading,
    dataAuth: checkToken?.data,
    error: vehicle?.error
  }
}

const mapDispatchToProps = {
  checkToken: CheckTokenAction.checkToken,
  findVehicle: VehicleAction.findVehicle,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchVehicle);
