import React, { useState, useEffect } from 'react'
import { Drawer } from 'antd'
import { CompassOutlined, CommentOutlined, StarOutlined } from '@ant-design/icons'
import Storage from 'libs/storage'
import Layout from 'components/atom/layout'
import Body from 'components/atom/body'
import Button from 'components/atom/button'
import Footer from 'components/organism/footer'
import FooterRequirements from 'components/organism/footer_requirements'
import Search from 'components/molecule/search'
import Grid, { GridSize } from 'components/atom/grid'
import Content from 'components/atom/content'

import "./styles.scss"

const Landpage: React.FC<any> = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)

    if (!Storage.hasCookieAccept()) {
      setVisible(true)
    }
  }, [])

  const onCloseDrawer = () => {
    setVisible(false)
  }

  const onSaveCookies = (accept: boolean) => {
    Storage.cookie(accept)
    setVisible(false)
  }

  return (
    <Layout hasHeader={true}>
      <Body hasHeader={true}>
        <Search hasHeader={true}/>

        <Content className="_mt-8">
          <div className="landing_title_section _text-center">
            Passos simples e fáceis para alugar
          </div>

          <Grid size={GridSize.Three} className="_mt-8 _mb-8 landing_tips_content">
            <div className="landing_path">
              <CompassOutlined className="landing_path_image" />
              <div className="landing_path_title">Escolha a cidade</div>
              <div className="landing_path_subtitle">Busque pela cidade que você deseja procurar.</div>
            </div>

            <div className="landing_path">
              <CommentOutlined className="landing_path_image" />
              <div className="landing_path_title">Entre em contato com a locadora que você escolheu</div>
              <div className="landing_path_subtitle">Tenha flexibilidade na sua escolha.</div>
            </div>

            <div className="landing_path">
              <StarOutlined className="landing_path_image" />
              <div className="landing_path_title">Alugue o veículo!</div>
              <div className="landing_path_subtitle">Pronto, agora é só escolher o veículo e ir buscá-lo.</div>
            </div>
          </Grid>
        </Content>

        <div className="landing_divisor_content">
          <div className="landing_divisor"/>
        </div>

        <Content className="_mb-4">
          {/* <div className="landing_title_section _text-center">
            Conheça algumas cidade terão nossos serviços.
          </div> */}

          {/* <Grid size={GridSize.Three} className="_mt-8 _mb-8 landing_city_content">
            <div className="landing_city_box">
              <ImageLoad url="https://53cars.s3-sa-east-1.amazonaws.com/beloHorizonte.jpg" className="landing_city_box_image" />
              <div className="landing_city_box_title"> Belo Horizonte </div>
            </div>

            <div className="landing_city_box">
              <ImageLoad url="https://53cars.s3-sa-east-1.amazonaws.com/saoPaulo.jpg" className="landing_city_box_image" />
              <div className="landing_city_box_title"> São Paulo </div>
            </div>

            <div className="landing_city_box">
              <ImageLoad url="https://53cars.s3-sa-east-1.amazonaws.com/rioDeJaneiro.jpg" className="landing_city_box_image" />
              <div className="landing_city_box_title"> Rio de Janeiro </div>
            </div>
          </Grid> */}

          <div className="landing_title_section _text-center _mb-6">
            Estamos no Brasil todo, faça sua cotação!
          </div>
        </Content>

        <FooterRequirements />
      </Body>

      <Footer />

      <Drawer
        title="Nossa site utiliza cookies"
        placement={"bottom"}
        closable={false}
        onClose={onCloseDrawer}
        visible={visible}>
        <p>Ei, gostaríamos que você soubesse que utilizamos cookies por aqui para entender melhor suas preferências e prover uma melhor experiência de navegação. Para obter mais informações sobre isso, leia nossa Política de Privacidade.</p>
        <p><a href="https://closecar-public.s3.amazonaws.com/Termos+de+Uso+-+Usuario+e+CloseCar.pdf" target="_blank" rel="noreferrer">Nossa política de privacidade</a></p>
        <Button theme="outline-black" onClick={() => onSaveCookies(false)}>Rejeitar</Button>
        <Button theme="primary" className="_ml-4" onClick={() => onSaveCookies(true)}>Aceitar e melhorar minha experiência</Button>
      </Drawer>
    </Layout>
  );
}

export default Landpage;
