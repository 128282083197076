import baseReducer from "../utilities/baseReducer"
import ActionType from "./ActionType"

export interface IVehicleRentState {
  readonly loading: boolean | null;
  readonly error: boolean | null;
  readonly data: any | null;

  readonly loadingInsurance: boolean | null;
  readonly errorInsurance: boolean | null;
  readonly dataInsurance: any | null;
}

const initialState: IVehicleRentState = {
  loading: false,
  error: false,
  data: {},
  loadingInsurance: false,
  errorInsurance: false,
  dataInsurance: {}
};

const rentReducer = baseReducer(initialState, {
  [ActionType.VEHICLE_RENT_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      data: action.payload,
    }
  },
  [ActionType.VEHICLE_RENT_DATA_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loading: action.payload,
    }
  },
  [ActionType.VEHICLE_RENT_DATA_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      error: true
    }
  },
  [ActionType.VEHICLE_RENT_INSURANCE_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      dataInsurance: action.payload,
    }
  },
  [ActionType.VEHICLE_RENT_INSURANCE_DATA_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loadingInsurance: action.payload,
    }
  },
  [ActionType.VEHICLE_RENT_INSURANCE_DATA_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      errorInsurance: action.payload,
    }
  },
})

export default rentReducer