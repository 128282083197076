import React from 'react'
import { useHistory } from 'react-router-dom'
import { Space } from 'antd'
import Body from 'components/atom/body'
import Text from 'components/atom/text'
import Content from 'components/atom/content'
import Layout from 'components/atom/layout'
import Footer from 'components/organism/footer'
import Header from 'components/organism/header'
import Card from 'components/molecule/card'
import Button from 'components/atom/button'
import { ReactComponent as OrderSuccessSvg } from './assets/order_success.svg'

const UserFormRequestSuccess: React.FC<any> = () => {
  const history = useHistory<any>()
  const data = history.location.state

  const onClickQuote = () => {
    history.push('/account/user', { activeKey: "2" })
  }
  
  return (
    <Layout>
      <Header />
      <Body className="user-form-request-body">
        <Content>
          <Card className="_text-center _mt-5">
            <Space direction="vertical">
              <Space direction="vertical">
                <OrderSuccessSvg className="user-form-request-success" />
                <Text className="_text-5 _mb-4">Você está a um passo de ter o seu carro.</Text>
                <Text className="_text-2">Recebemos sua cotação e já enviamos para nossos especialistas.</Text>
                <Text className="_text-2">Eles irão entrar em contato com você em no máximo 24 horas.</Text>
              </Space>

              <Space direction="vertical" className="_mt-4 _mb-4">
                <Text className="_text-4">Este é o código da sua cotação</Text>
                <Text className="_text-6 _font-weight-bold">{data?.code}</Text>
              </Space>

              <Space direction="vertical">
                <Text className="_text-3">Fique a vontade para entra em contato com a gente pelo email ou redes sociais.</Text>
              </Space>

              <Button onClick={onClickQuote} theme="outline-primary" className="_mt-4">Ver minhas cotações</Button>
            </Space>
          </Card>
        </Content>
      </Body>
      <Footer />
    </Layout>
  ) 
}

export default UserFormRequestSuccess