import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

export default function MapComponent({ children, onLoadedMap }: { children: any; onLoadedMap: any }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAWxvjgB_gdh7n2NsdDkRIGZEW9qJpumF4"
  })

  // const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    // setMap(map)
    onLoadedMap && onLoadedMap(map)
  }, [onLoadedMap])

  const onUnmount = React.useCallback(function callback() {
    // setMap(null)
  }, [])

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {children}
      </GoogleMap>
  ) : <></>
}