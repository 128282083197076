import { Dispatch } from "redux"
import FetchApi from "libs/fetch"
import * as ActionUtility from "store/utilities/actionUtility"
import ActionType from "./ActionType"
import { CheckInsuranceParams, FindVehicleParams } from "./Action"
import { StateMachineType } from "libs/statemachine"

const COMPANY_TYPE_MOVIDA = "movida"

export const getVehicle = async ({ type, uCodeCompany, uCodeVehicle, start, end, companyTag = "" }: FindVehicleParams) => {
  let fetch

  if (type === COMPANY_TYPE_MOVIDA) {
    fetch = await FetchApi.get(`/v1/vehicle/movida/${uCodeCompany}?start=${start}&end=${end}`)
  } else {
    if (uCodeVehicle) {
      fetch = await FetchApi.get(`/v1/vehicle/company/${uCodeVehicle}?start=${start}&end=${end}&companyTag=${companyTag}&type=detail`)
    } else {
      fetch = await FetchApi.get(`/v1/vehicle/company/${uCodeCompany}?start=${start}&end=${end}&companyTag=${companyTag}`)
    }
  }

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const getCompanyVehicle = async () => {
  const fetch = await FetchApi.get(`/v1/vehicle`, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const getCompanyVehicleSelect = async () => {
  const fetch = await FetchApi.get(`/v1/vehicle/select`, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const checkInsurance = async (params: CheckInsuranceParams) => {
  const fetch = await FetchApi.post(`/v1/vehicle/insurance/validate`, params, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const setVehicle = (dispatch: Dispatch, payload: string) =>
  dispatch(ActionUtility.createAction(ActionType.VEHICLE_DATA, payload));

export const setVehicleLoading = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.VEHICLE_DATA_LOADING, payload));

export const setVehicleError = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.VEHICLE_DATA_ERROR, payload));

export const setSelectData = (dispatch: Dispatch, payload: string) =>
  dispatch(ActionUtility.createAction(ActionType.VEHICLE_SELECT_DATA, payload));

export const setSelectLoading = (dispatch: Dispatch, payload: StateMachineType) =>
  dispatch(ActionUtility.createAction(ActionType.VEHICLE_SELECT_LOADING, payload));

export const setSelectError = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.VEHICLE_SELECT_ERROR, payload));
