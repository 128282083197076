import React from 'react';
import './styles.scss';

function Card(props: any) {

  const onClick = () => {
    props.onClick && props.onClick()
  }

  return (
    <div 
      onClick={onClick}
      className={`layout_card_molecule_component${props.className? ` ${props.className}` : ''}`}>
      { props.children }
    </div>
  );
}

export default Card;
