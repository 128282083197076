import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'antd'
import * as CreditCardAction from 'store/creditcard/Action'
import Grid, { GridSize } from 'components/atom/grid'
import Text from 'components/atom/text'
import ManageDate from 'libs/date'

import { ReactComponent as Visa } from './assets/visa.svg'
import { ReactComponent as Master } from './assets/master.svg'
import { ReactComponent as Amex } from './assets/amex.svg'
import { ReactComponent as Diners } from './assets/diners.svg'
import { ReactComponent as Discover } from './assets/discover.svg'
import Elo from './assets/elo.png'
import Aura from './assets/aura.png'
import JCB from './assets/jcb.png'

import './index.scss'
import { AppState } from 'store'
import FormInput from 'components/molecule/form_input'

const CreditCardData: React.FC<any> = ({ onSelect, card, selected, defaultParams, setSecurityData, securityData, hideSecurityFields = false, form }) => {
  const cardSelected = defaultParams?.cardHash ? defaultParams.cardHash === card.cardHash : selected.cardHash === card.cardHash

  const getBrandImage = (brand: string) => {
    switch (brand) {
      case 'Visa':
        return <Visa className="creditcard-image" />

      case 'Master':
        return <Master className="creditcard-image" />
      
      case 'Amex':
        return <Amex className="creditcard-image" />

      case 'Diners':
        return <Diners className="creditcard-image" />
      
      case 'Discover':
        return <Discover className="creditcard-image" />

      case 'Elo':
        return <img className="creditcard-image" src={Elo} />

      case 'Aura':
        return <img className="creditcard-image" src={Aura} />

      case 'JCB':
        return <img className="creditcard-image" src={JCB} />
    }
  }
  
  const setFormData = (data: any) => {
    setSecurityData({ ...securityData, ...data, cardHash: defaultParams?.cardHash ? defaultParams?.cardHash : selected.cardHash })
  }

  return (
    <div onClick={onSelect} className={`creditcard-content ${cardSelected && 'creditcard-content-selected'}`}>
      <div className="creditcard-content-data _mb-2 _mt-2">
        <div className="_mt-2">
          {getBrandImage(card.brand)}
        </div>

        <div className="_mt-2">
          <div>
            <Text className="_text-2">{`${card.brand} • `}</Text>
            <Text className="_text-2 _font-weight-bold">{card.last}</Text>
          </div>
          <div>
            <Text className="_text-1">{`Cad. em ${ManageDate.friendlyFormatCreate(card.createdAt)}`}</Text>
          </div>
        </div>
      </div>

      {(cardSelected && !hideSecurityFields) && (
        <div className="creditcard-input-data">
          <Form name="basic" form={form} onValuesChange={setFormData}>
            <Grid size={GridSize.Two} className="creditcard-input-data-grid">
              <FormInput className="creditcard-input-data-form" label="Cód. Segurança" name="securityCode" disabled={defaultParams?.blockCreating} required/>
              <FormInput className="creditcard-input-data-form" label="CPF" name="nationalNumberId" disabled={defaultParams?.blockCreating} required/>
            </Grid>
          </Form>
        </div>
      )}

      {cardSelected && (
        <div className="creditcard-seleted-label _text-right _pr-4">
          <Text className="_text-1 _text-color-white _font-weight-bold _text-uppercase"> Cartão selecionado </Text>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ creditcard }: AppState) => {
  return {
    selected: creditcard?.selected,
    securityData: creditcard?.securityData
  }
}

const mapDispatchToProps = {
  setSecurityData: CreditCardAction.setSecurityData
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardData)