class Utils {
  static phoneFormat(string: string): string {
    let ddd = string.substring(0, 2)
    let firstDigit = string.substring(2, 3)
    let phone = string.substring(3)

    // Checking for 0800
    if (ddd === '08') {
      return `${string.substring(0, 4)} ${string.substring(4, 7)} ${string.substring(7, 11)}`
    }

    if (Number(firstDigit) <= 4) {
      return `(${ddd}) ${firstDigit}${phone}`  
    }

    return `(${ddd}) ${firstDigit} ${phone}`
  }

  static cleanString(string: string, removeSpace: boolean = true): string {
    if (!string || typeof(string) !== 'string') {
      return ''
    }

    if (removeSpace) {
      string = string.replace(/ /g,'')
    }

    string = string.replace(/-/g,'')
    string = string.replace(/_/g,'')
    string = string.replace(/\//g,'')
    string = string.replace(/,/g,'')
    string = string.replace(/\./g,'')
    string = string.replace(/:/g,'')
    string = string.replace(/\(/g,'')
    string = string.replace(/\)/g,'')

    return string
  }
}

export default Utils