import React from 'react'
import { DatePicker as AntDatePicker } from 'antd'

const DatePicker = (props: any) => {

  return (
    <AntDatePicker 
      style={{
        width: "100%",
      }}
      size="large"
      format="DD/MM/YYYY"
      onOk={props.onSelect}
      onSelect={props.onSelect}
      defaultValue={props.defaultValue}
    />
  )
}

export default DatePicker