import React, {useState} from 'react'
import { Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import ManageDate from 'libs/date'
import Config from 'libs/config'
import Button from 'components/atom/button'
import Text from 'components/atom/text'

import './styles.scss'

const FooterRangePicker = (props: any) => {

  const { t } = useTranslation()
  
  const hours = Config.restrictTime.hours
  const minutes = Config.restrictTime.minutes

  const restrict = { 
    hour: hours, 
    minute: minutes
  }

  const [firstTime, setFirstTime] = useState(ManageDate.dateHourAndMinute(props?.initialDate?.start, restrict))
  const [secondTime, setSecondTime] = useState(ManageDate.dateHourAndMinute(props?.initialDate?.end, restrict))

  const handleChangeFirstHour = (value: any) => {
    setFirstTime({ hour: value.key, minute: firstTime.minute })
  }

  const handleChangeFirstMinute = (value: any) => {
    setFirstTime({ hour: firstTime.hour, minute: value.key })
  }

  const handleChangeSecondHour = (value: any) => {
    setSecondTime({ hour: value.key, minute: secondTime.minute })
  }

  const handleChangeSecondMinute = (value: any) => {
    setSecondTime({ hour: secondTime.hour, minute: value.key })
  }

  const firstHoursMenu = (
    <Menu onClick={handleChangeFirstHour} className="footer-picker-time-select">
      {hours.map(hour => {
        const hourConvert = hour <= 9 ? `0${hour}` : `${hour}`

        return <Menu.Item key={hourConvert}>
          {hourConvert}
        </Menu.Item>  
      })}
    </Menu>
  )

  const firstMinutesMenu = (
    <Menu onClick={handleChangeFirstMinute} className="footer-picker-time-select">
      {minutes.map(minute => {
        const minuteConvert = minute <= 9 ? `0${minute}` : `${minute}`

        return <Menu.Item key={minuteConvert}>
          {minuteConvert}
        </Menu.Item>  
      })}
    </Menu>
  )

  const secondHoursMenu = (
    <Menu onClick={handleChangeSecondHour} className="footer-picker-time-select">
      {hours.map(hour => {
        const hourConvert = hour <= 9 ? `0${hour}` : `${hour}`

        return <Menu.Item key={hourConvert}>
          {hourConvert}
        </Menu.Item>  
      })}
    </Menu>
  )

  const secondMinutesMenu = (
    <Menu onClick={handleChangeSecondMinute} className="footer-picker-time-select">
      {minutes.map(minute => {
        const minuteConvert = minute <= 9 ? `0${minute}` : `${minute}`

        return <Menu.Item key={minuteConvert}>
          {minuteConvert}
        </Menu.Item>  
      })}
    </Menu>
  )

  const onConfirmPicker = () => {
    props.onConfirmPicker({ start: firstTime, end: secondTime })
  }

  return (
    <div>
      <div className="footer-picker-time _mb-3">
        <div>
          <div> 
            <Text>{t('choose_time_pickup')}</Text>
          </div>
          <div>
            <Dropdown overlay={firstHoursMenu} trigger={["click"]}>
              <Button>{firstTime.hour} <DownOutlined /> </Button>
            </Dropdown>
            <Text className="_ml-2 _mr-2">:</Text>
            <Dropdown overlay={firstMinutesMenu} trigger={["click"]}>
              <Button>{firstTime.minute} <DownOutlined /> </Button>
            </Dropdown>
          </div>
        </div>
        <div>
          <div> 
            <Text>{t('choose_time_return')}</Text>
          </div>
          <div>
            <Dropdown overlay={secondHoursMenu} trigger={["click"]}>
              <Button>{secondTime.hour} <DownOutlined /> </Button>
            </Dropdown>
            <Text className="_ml-2 _mr-2">:</Text>
            <Dropdown overlay={secondMinutesMenu} trigger={["click"]}>
              <Button>{secondTime.minute} <DownOutlined /> </Button>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="footer-button _mb-3">
        <Button onClick={props.onClosePicker} size="large" block>{t('close')}</Button>
        <Button onClick={onConfirmPicker} size="large" type="primary" block>{t('confirm')}</Button>
      </div>
    </div>
  )
}

export default FooterRangePicker