import React, { Suspense } from 'react'
import PageLoading from 'components/organism/page_loading'
import PageCompanyLoading from 'components/organism/page_company_loading'

export const withSuspense = (WrappedComponent: any) => {
  return (props: any) => (
    <Suspense fallback={<PageLoading />}>
      <WrappedComponent {...props} />
    </Suspense>
  )
}

export const withSuspensePainel = (WrappedComponent: any) => {
  return (props: any) => (
    <Suspense fallback={<PageCompanyLoading />}>
      <WrappedComponent {...props} />
    </Suspense>
  )
}