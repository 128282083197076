import baseReducer from 'store/utilities/baseReducer'
import ActionType from 'store/firm/ActionType'
import { StateMachineType } from 'libs/statemachine'
import { ErrorPayload } from 'libs/fetch/interface'
import { BidFirm, BidFirmProposal } from 'store/firm/Selector'

export interface IFirmState {
  readonly data: { bid: BidFirm[]; detail: BidFirm | null; proposal: BidFirmProposal[] | null; };
  readonly loading: StateMachineType;
  readonly error?: ErrorPayload | null;
}

const initialState: IFirmState = {
  data: {
    bid: [],
    proposal: null,
    detail: null
  },
  loading: StateMachineType.NotStarted,
  error: null,
}

export default baseReducer(initialState, {
  [ActionType.FIRM_BID_COMPANY_DATA_BID](
    state,
    action,
  ) {
    return {
      ...state,
      data: {
        ...state.data,
        bid: action.payload
      },
    }
  },
  [ActionType.FIRM_BID_COMPANY_DATA_BID_DETAIL](
    state,
    action,
  ) {
    return {
      ...state,
      data: {
        ...state.data,
        detail: action.payload
      },
    }
  },
  [ActionType.FIRM_BID_COMPANY_DATA_PROPOSAL](
    state,
    action,
  ) {
    return {
      ...state,
      data: {
        ...state.data,
        proposal: action.payload
      },
    }
  },
  [ActionType.FIRM_BID_COMPANY_VIEW_STATE](
    state,
    action,
  ) {
    return {
      ...state,
      loading: action.payload,
    }
  },
  [ActionType.FIRM_BID_COMPANY_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      error: action.payload,
    }
  },
})