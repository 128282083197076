import React, { useEffect } from 'react'
import { Form, Result } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ForgotPasswordSvg } from './assets/forgot_password.svg'

import Header from 'components/organism/header'
import Footer from 'components/organism/footer'
import Body from 'components/atom/body'
import Button from 'components/atom/button'
import Text from 'components/atom/text'
import FormInput from 'components/molecule/form_input'
import Layout from 'components/atom/layout'
import Grid, { GridSize } from 'components/atom/grid'
import { AppState } from 'store'
import * as SigninAction from 'store/signin/Action'

import './styles.scss'

const UserRequireLostPassword = ({ requestPassword, error, success, loading, clean }: any) => {

  const { t } = useTranslation()

  useEffect(() => {
    return () => clean()
  }, [])

  const onSubmit = async ({ email }: any) => await requestPassword({ email })

  return (
    <Layout>
      <Header />
      <Body>
        <Grid size={GridSize.Two} className="forgot_password_content_grid">
          <div className="forgot_password_content_image">
            <ForgotPasswordSvg className="forgot_password_image" />
          </div>
          <div className="_pt-6 _pl-8 _pr-8">
            {success ? (
              <Result
                status="success"
                title="Pronto, sua solicitação de troca senha foi feita com sucesso!"
                subTitle="Enviamos para seu email as instruções para realizar a troca para uma nova senha."
                extra={[
                  <Link to="/">
                    <Button theme="primary" key="console">
                      Voltar para a página inicial
                    </Button>
                  </Link>,
                ]}
              />
            ) : (
            <>
              <div className="_text-center _mb-6">
                <Text className="_text-6">Esqueci minha senha</Text>
                <br /><br />
                <Text className="_text-3">Vamos enviar para o seu email as instruções para realizar a troca de senha.</Text>
              </div>

              <Form name="basic" onFinish={onSubmit} layout="vertical">
                <FormInput label="Digite o email da sua conta" name="email" type="email" disabled={loading} required />

                {error && (
                  <div className="_text-center _mb-4">
                    <Text className="_text-3 _text-color-red"> { t(error.key) } </Text>
                  </div>
                )}

                <Form.Item>
                  <Button htmlType="submit" theme="primary" loading={loading} block>Solicitar troca de senha</Button>
                </Form.Item>
              </Form>
            </>
            )}
          </div>
        </Grid>
      </Body>
      <Footer />
    </Layout>
  );
}

const mapStateToProps = ({ signin }: AppState) => {
  return {
    loading: signin?.loading,
    error: signin?.error,
    success: signin?.success,
  }
}

const mapDispatchToProps = {
  requestPassword: SigninAction.requestPassword,
  clean: SigninAction.clean
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRequireLostPassword);