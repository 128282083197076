import { Dispatch } from 'redux'
import FetchApi from 'libs/fetch'
import * as ActionUtility from 'store/utilities/actionUtility'
import ActionType from './ActionType'
import TrackLog from 'libs/trackLog'

const TAG_LOG_FILE = "Store_CreditCard_Effect"

export interface CreateCreditCard {
  customerName: string;
  cardNumber: string;
  holder: string;
  expirationMonth: string;
  expirationYear: string;
  brand: string;
  typeNumberId: string;
}

export const creditcard = async () => {
  const fetch = await FetchApi.get(`/v1/creditcard`, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const createCreditCard = async (data: CreateCreditCard) => {
  try {
    const fetch = await FetchApi.post(
      `/v1/creditcard/create`, 
      { ...data, customerName: data.holder, typeNumberId: data.typeNumberId || "cpf" }, 
      true,
    )

    return {
      hasError: FetchApi.hasError(fetch.status),
      data: fetch.data
    }
  } catch (e) {
    TrackLog.logError(TAG_LOG_FILE, createCreditCard.name, e)

    return {
      hasError: true,
      data: null
    }
  }
}

export const defaultCreditcard = async (cardHash: string) => {
  const fetch = await FetchApi.put(`/v1/creditcard/default/${cardHash}`, null, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const setLoading = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.CREDITCARD_DATA_LOADING, payload))

export const setLoadingCreate = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.CREDITCARD_CREATE_LOADING, payload))

export const setData = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.CREDITCARD_DATA, payload))

export const setError = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.CREDITCARD_DATA_ERROR, payload))

export const setErrorCreate = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.CREDITCARD_CREATE_ERROR, payload))

export const setSelected = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.CREDITCARD_SELECTED, payload))

export const setSecurityData = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.CREDITCARD_SECURITY_DATA, payload))
