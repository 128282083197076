import { createSelector } from "reselect"
import { AppState } from "store"

export interface CompanySearched {
  uCode: string;
  companyName: string;
  tradingName: string;
  type: string;
  logo: string;
  map: string;
  color: string;
  timeOpenDesc: string;
  address: any;
}

interface CompanyUseCase {
  data: CompanySearched[]
}

function companiesSearched(companies: CompanyUseCase): CompanySearched[] {
  const digest = companies.data.map(company => {
    const address = company.address
    return { 
      ...company,
      address
    }
  })

  return digest
}

export const companiesSelector = createSelector<AppState, any, any>(
  (state) => state?.company,
  companiesSearched,
);