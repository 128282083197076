import baseReducer from 'store/utilities/baseReducer'
import ActionType from 'store/subscription/ActionType'

export interface ISubscriptionState {
  readonly loading?: boolean | null;
  readonly data?: any[] | null;
  readonly filter?: any[] | null;
  readonly error?: any | null;
}

const initialState: ISubscriptionState = {
  loading: false,
  data: null,
  filter: null,
  error: null,
}

export default baseReducer(initialState, {
  [ActionType.SUBSCRIPTION_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loading: action.payload,
    }
  },
  [ActionType.SUBSCRIPTION_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      data: action.payload,
    }
  },
  [ActionType.SUBSCRIPTION_FILTER](
    state,
    action,
  ) {
    return {
      ...state,
      filter: action.payload,
    }
  },
  [ActionType.SUBSCRIPTION_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      error: action.payload,
    }
  },
})