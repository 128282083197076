import { Dispatch } from "redux";
import { FavoriteCompanyParams } from "store/account/Action"
import * as CompanyEffect from './Effect'
import * as AccountAction from 'store/account/Action'
import { AppState } from "store"

export enum CompanyTypeInsurance {
  Wiz = 'W',
  Own = 'O',
}

export enum CompanyTypePayment {
  Cielo = 'C',
  Own   = 'O',
}

export enum CompanyTypeUserPayment {
  None = 'N', // Não há pagamento;
  PartialUser = 'P', // A taxa de adminitração é paga pelo usuário no cartão de crédito;
  Full = 'F' // Todo valor é cobrado pelo usuário no cartão de crédito;
}

export const findCompanyStore = (city: string) => async (dispatch: Dispatch, getState: () => AppState) => {
  
  try {
    CompanyEffect.setCompanyStoreLoading(dispatch, true)

    const stores = await CompanyEffect.getCompanyStore(city)

    CompanyEffect.setCompanyStore(dispatch, stores.data)
    CompanyEffect.setCompanyHoldStore(dispatch, stores.data)
    CompanyEffect.setCompanyStoreLoading(dispatch, false)
  } catch (e) {
    CompanyEffect.setCompanyStoreError(dispatch)
    CompanyEffect.setCompanyStoreLoading(dispatch, false)
  }
}

export const filterCompanyStore = (filter: any) => (dispatch: Dispatch, getState: () => AppState) => {
  if (filter === null) {
    const result = getState()?.company?.holdData
    CompanyEffect.setCompanyStore(dispatch, result)
  } else {
    const result = getState()?.company?.data.filter((company: any) => {
      return company.modalidade && company.modalidade.indexOf(filter.modalidade) !== -1
    })

    CompanyEffect.setCompanyStore(dispatch, result)
  }
}

export const dashboard = ({ start, end }: { start: string; end: string; }) => async (dispatch: Dispatch) => {

  try {
    CompanyEffect.setCompanyDashboardLoading(dispatch, true)

    const result = await CompanyEffect.dashboard({ start, end })

    CompanyEffect.setCompanyDashboardData(dispatch, result.data)
    CompanyEffect.setCompanyDashboardLoading(dispatch, false)
  } catch (e) {
    CompanyEffect.setCompanyDashboardLoading(dispatch, false)
  }
}

export const favoriteCompany = (body: FavoriteCompanyParams) => async (dispatch: Dispatch, getState: () => AppState) => {
  loadingItemList(dispatch, true, getState()?.company?.data, body.uCodeCompany)

  const result = await AccountAction.favoriteCompany(body)()

  if (!result.hasError) {
    const data = getState()?.company?.data
    
    const mapCompanyFavorited = data.map((company: any) => {
      if (company.uCode === result.data.uCode) {
        company.favorited = result.data.isFavorited
        company.loading = false
      }

      return company
    })
    
    CompanyEffect.setCompanyStore(dispatch, mapCompanyFavorited)
  } else {
    loadingItemList(dispatch, false, getState()?.company?.data, body.uCodeCompany)
  }
}

export const loadingItemList = (dispatch: Dispatch, loading: boolean, data: any[], target: any) => {
  const mapCompanyLoading: any = data.map((company: any) => {
    if (company.uCode === target) {
      company.loading = loading
    }

    return company
  })

  CompanyEffect.setCompanyStore(dispatch, mapCompanyLoading)
}