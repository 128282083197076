import { Dispatch } from 'redux'
import FetchApi from 'libs/fetch'
import * as ActionUtility from 'store/utilities/actionUtility'
import ActionType from './ActionType'
import { ListFetchModule } from './Action'
import { StateMachineType } from 'libs/statemachine'

export const fetchData = async (module: ListFetchModule) => {
  const fetch = await FetchApi.get(module, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
};

export const setData = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.LIST_DATA, payload))

export const setLoading = (dispatch: Dispatch, payload: StateMachineType) =>
  dispatch(ActionUtility.createAction(ActionType.LIST_LOADING, payload))

export const setError = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.LIST_ERROR, payload))
