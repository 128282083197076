import React, { useState, useRef } from 'react'
import { Carousel, Modal } from 'antd'
import ImageLoad from 'components/atom/image_load'

interface ModalCarouselProps {
  urls: string[]
}

const ModalCarousel: React.FC<ModalCarouselProps> = (props) => {

  const refCarousel: any = useRef(null)
  const [show, setShow] = useState(false)

  return (
    <>
      <Modal
        visible={show}
        onCancel={() => setShow(false)}
        title="Fotos do veículo"
        footer={null}
        width={512}>
        <Carousel ref={refCarousel}>
          {props.urls.map(url => (
            <div onClick={() => refCarousel.current.next()} className="_cursor_pointer">
              <ImageLoad url={url} />
            </div>
          ))}
        </Carousel>
      </Modal>
      <Carousel>
        {props.urls.map(url => (
          <div onClick={() => setShow(true)} className="_cursor_pointer">
            <ImageLoad url={url} />
          </div>
        ))}
      </Carousel>
    </>
  )
}

export default ModalCarousel