const ActionType = {
  FORM_LOADING: "FormAction.FORM_LOADING",
  FORM_LOADING_START: "FormAction.FORM_LOADING_START",
  FORM_ERROR: "FormAction.FORM_ERROR",
  FORM_PERMISSION_ERROR: "FormAction.FORM_PERMISSION_ERROR",
  FORM_SUCCESS: "FormAction.FORM_SUCCESS",
  FORM_DATA: "FormAction.FORM_DATA",
  FORM_DATA_EDITING: "FormAction.FORM_DATA_EDITING",
};

export default ActionType;