import { Dispatch } from "redux"
import FetchApi from "libs/fetch"
import * as ActionUtility from "store/utilities/actionUtility"
import ActionType from "store/signup/ActionType"
import { SignupParams, SignupCompanyParams } from 'store/signup/Action'
import { ErrorPayload } from "libs/fetch/interface"
import Storage from 'libs/storage'

export const signup = async ({ name, email, password, acceptTerms }: SignupParams) => {
  const fetch = await FetchApi.post(`/v1/auth/signup`, { name, email, password, acceptTerms: acceptTerms ? 1 : 0 })

  if (fetch.status === FetchApi.HttpSuccessfulOk || fetch.status === FetchApi.HttpSuccessfulCreated) {
    Storage.authorization(fetch.data)
  }

  return {
    hasError: fetch.status === FetchApi.HttpErrorBadRequest,
    data: fetch.data
  }
};

export const signupCompany = async ({ name, tradingName, documentLegalNumber, phone, email, password, acceptTerms }: SignupCompanyParams) => {
  const fetch = await FetchApi.post(`/v1/company/start`, { name, tradingName, documentLegalNumber, phone, email, password, acceptTerms: acceptTerms ? 1 : 0 })

  if (fetch.status === FetchApi.HttpSuccessfulOk || fetch.status === FetchApi.HttpSuccessfulCreated) {
    Storage.authorization(fetch.data)
  }

  return {
    hasError: fetch.status === FetchApi.HttpErrorBadRequest,
    data: fetch.data
  }
};

export const setLoading = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.SIGNUP_LOADING, payload))

export const setSuccess = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.SIGNUP_SUCCESS, payload))

export const setError = (dispatch: Dispatch, payload: ErrorPayload) =>
  dispatch(ActionUtility.createAction(ActionType.SIGNUP_ERROR, payload))
