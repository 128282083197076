import { Dispatch } from 'redux'
import * as BookEffect from './Effect'
import { BookingParams, BookingCupomParams } from './Effect'
import * as VehicleEffect from 'store/vehicle/Effect'
import { FindVehicleParams } from 'store/vehicle/Action'

export enum BookPaymentType {
  Boleto = "B",
  CreditCard = "C"
}

export const booking = (params: BookingParams) => async (dispatch: Dispatch) => {
  BookEffect.setLoading(dispatch, true)

  const book = await BookEffect.booking(params)

  BookEffect.setData(dispatch, book)
  BookEffect.setLoading(dispatch, false)

  return { 
    data: book.data,
    hasError: book.hasError
  }
}

export const bookingCupom = (params: BookingCupomParams) => async (dispatch: Dispatch) => {
  BookEffect.setRentalRate(dispatch, null)
  BookEffect.setLoading(dispatch, true)

  const bookCupom = await BookEffect.bookingCupom(params)

  if (!bookCupom.hasError) {
    BookEffect.setRentalRate(dispatch, bookCupom.data)
  }
  
  BookEffect.setLoading(dispatch, false)

  return { 
    hasError: !bookCupom?.data?.discountCharge?.isValid
  }
}

export const findVehicleDetail = ({ type, uCodeCompany, uCodeVehicle, start, end, companyTag = "" }: FindVehicleParams) => async (dispatch: Dispatch) => {
  BookEffect.setInitialLoading(dispatch, true)

  const vehicles = await VehicleEffect.getVehicle({ type, uCodeCompany, uCodeVehicle, start, end, companyTag })
  
  BookEffect.setVehicle(dispatch, vehicles.data)
  BookEffect.setRentalRate(dispatch, vehicles.data.rentalRate)
  BookEffect.setInitialLoading(dispatch, false)

  return { 
    data: vehicles.data,
    hasError: vehicles.hasError
  }
}

export const bookingClean = () => async (dispatch: Dispatch) => {
  BookEffect.setRentalRate(dispatch, null)
}