import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import './styles.scss'

const Button: React.FC<any> = ({ className, icon, theme, block, onClick, children, disabled, loading, type="submit" }) => {

  const getClass = () => {

    let generateClassName = `atom-button ${className}`

    switch(theme) {
      case "primary":
        generateClassName = `atom-button-primary ${generateClassName}`
        break;

      case "secondary":
        generateClassName = `atom-button-secondary ${generateClassName}`
        break;

      case "gray":
        generateClassName = `atom-button-gray ${generateClassName}`
        break;

      case "outline-black":
        generateClassName = `atom-button-outline-black ${generateClassName}`
        break;

      case "outline-primary":
        generateClassName = `atom-button-outline-primary ${generateClassName}`
        break;

      case "excel":
        generateClassName = `atom-button-excel ${generateClassName}`
        break;

      case "danger":
        generateClassName = `atom-button-danger ${generateClassName}`
        break;
    }

    if (block) {
      generateClassName = `atom-button-block ${generateClassName}`
    }

    if (disabled) {
      generateClassName = `${generateClassName} atom-button-disabled`
    }

    if (loading) {
      generateClassName = `${generateClassName} atom-button-loading`
    }

    return generateClassName
  }

  return (
    <>
      {loading ? (
        <button className={getClass()} disabled={true}>
          <LoadingOutlined spin className="_mr-2" />
          {children}
        </button>
      ) : (
        <button className={getClass()} onClick={onClick} disabled={disabled} type={type}>
          {icon}
          {children}
        </button>
      )}
    </>
  );
}

export default Button
