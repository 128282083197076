import React from 'react';
import './styles.scss'

function Content(props: any) {
  return (
    <div className={`layout_container ${props?.className}`}>
      { props.children }
    </div>
  );
}

export default Content;
