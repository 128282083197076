import React from 'react'
import './styles.scss'

const Layout: React.FC<any> = (props) => {

  return (
    <article className={props.hasHeader ? `atom-layout-base` : `atom-layout-base-without-header`}>
      {props.children}
    </article>
  )
}

Layout.defaultProps = {
  hasHeader: true
}

export default Layout