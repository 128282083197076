import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { InstagramOutlined, FacebookOutlined, LinkedinOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { analytics } from 'libs/firebase'
import { logEvent } from 'firebase/analytics'

import ImageLoad from 'components/atom/image_load'
import Text from 'components/atom/text'
import Button from 'components/atom/button'
import Card from 'components/molecule/card'

import './styles.scss'
import Utils from 'libs/utils'
import { Tag } from 'antd'

interface CardStoreProps {
  className?: string;
  tradingName?: string;
  companyTag?: string;
  mapUrl?: string;
  logoUrl?: string;
  address?: any;
  site?: string;
  instagram?: string;
  facebook?: string;
  linkedin?: string;
  whatsapp?: string;
  phone?: string;
  favorited?: boolean;
  loading?: boolean;
  key?: string;
  timeOpenDesc?: string;
  modalidade?: string;
  onClick?: any;
  onClickMap?: any;
  onClickFavorite?: any;
  onClickChat?: any;
  showButtons?: boolean;
}

const CardStore: React.FC<CardStoreProps> = ({ 
  className,
  tradingName,
  companyTag,
  logoUrl, 
  address, 
  site,
  instagram,
  facebook,
  linkedin,
  whatsapp,
  phone,
  favorited, 
  loading, 
  timeOpenDesc, 
  modalidade,
  onClick, 
  onClickMap, 
  onClickFavorite, 
  onClickChat,
  showButtons = true 
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (companyTag) {
      logEvent(analytics, `appeared_in_the_search`, {
        company_tag: companyTag,
      })
    }
  }, [])
  
  const mountAddress = () => {
    if (!address?.uCode) {
      return ''
    }

    if (address?.full) {
      return address.full
    }

    return `${address?.street}, ${address?.number} ${address?.neighborhood} - ${address?.city}`
  }

  const openSite = (type: string, url: string) => { 
    if (companyTag) {
      logEvent(analytics, `click_open_${type}`, {
        company_tag: companyTag,
      })
    }

    window.open(url, '_blank', 'noreferrer')
  }

  const openMapMobile = () => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${address.lat}%2C${address.lng}`, '_blank', 'noreferrer')
  }

  const getTagModalidades = (modalidades: string) => {
    return modalidades.split(',').map(item => (
      <Tag color="#FCA312"><b>{item}</b></Tag>
    ))
  }

  return (
    <Card onClick={onClick} className={`search-store-card ${className} _mb-4`}>
      <div className="search-store-card-content">
        <ImageLoad url={logoUrl} />
        
        <div>
          <div className="_ml-4 _mb-1">
            <Text strong className="_text-4">{tradingName}</Text>
          </div>
          <div className="_ml-4 _mb-1">
            <Text className="_text-2">{mountAddress()}</Text>
          </div>
          {phone && (
            <div className="_ml-4 _mb-1">
              <Text className="_text-2"><b>Telefone:</b> {Utils.phoneFormat(phone)}</Text>
            </div>
          )}
          {whatsapp && (
            <div className="_ml-4 _mb-1">
              <a href={`https://wa.me/${whatsapp}`} target="noopener noreferrer _blank">
                <Text className="_text-2"><b>Whatsapp:</b> <WhatsAppOutlined /> {Utils.phoneFormat(whatsapp.substring(2))}</Text>
              </a>
            </div>
          )}
          {timeOpenDesc && (
            <div className="_ml-4">
              <div><Text className="_text-2"><b>Horário de funcionamento:</b> {timeOpenDesc}</Text></div>
            </div>
          )}
          {modalidade && (
            <div className="_ml-4 _mt-2">{getTagModalidades(modalidade)}</div>
          )}
          {showButtons && (
            <div className="_ml-4 _mt-3 card_store_flex_buttons">
              {/* <Button theme="primary">{t('see_that_store')}</Button> */}
              {onClickMap && (
                <Button theme="outline-black" onClick={onClickMap}>{t('see_on_map')}</Button>
              )}
              {site && (
                <Button theme="outline-black" onClick={() => openSite('site', site)}>{t('go_website')}</Button>
              )}
              {instagram && (
                <Button icon={<InstagramOutlined className="_mr-1" />} theme="outline-black" onClick={() => openSite('instagram', instagram)}>Instagram</Button>
              )}
              {facebook && (
                <Button icon={<FacebookOutlined className="_mr-1" />} theme="outline-black" onClick={() => openSite('facebook', facebook)}>Facebook</Button>
              )}
              {linkedin && (
                <Button icon={<LinkedinOutlined className="_mr-1" />} theme="outline-black" onClick={() => openSite('linkedin', linkedin)}>LinkedIn</Button>
              )}
              {/* <Button theme="outline-black" onClick={onClickChat}>Chat</Button> */}
              {/* {onClickFavorite && (
                <Button 
                  theme="outline-black" 
                  icon={favorited ? <HeartFilled className="button_favorite_icon_color" /> : <HeartOutlined className="button_favorite_icon_color" />}
                  onClick={onClickFavorite}
                  disabled={loading}>
                    {favorited ? t('favorited') : t('favorite')}
                </Button>
              )} */}
            </div>
          )}
        </div>
      </div>

      {/* Mobile Version */}
      <div className="search-store-card-content-mobile">
        <div className="search-store-card-content-mobile__title">
          <Text strong className="_text-3">{tradingName}</Text>
          {/* {onClickFavorite && (
            <div onClick={onClickFavorite}>
              {favorited ? <HeartFilled className="button_favorite_icon_color" /> : <HeartOutlined className="button_favorite_icon_color" />}
            </div>
          )} */}
          
        </div>
        <div>
          <Text className="_text-2">{mountAddress()}</Text>
        </div>
        {showButtons ? (
          <div className="search-store-card-content-mobile__image">
            <ImageLoad width={96} url={logoUrl} />
            <div className="_ml-4 _mt-3 card_store_flex_buttons">
              {site && (
                <Button theme="outline-black" onClick={() => openSite('site', site)}>{t('go_website')}</Button>
              )}
              <Button theme="outline-black" onClick={() => openMapMobile()}>Ver no mapa</Button>
              {instagram && (
                <Button icon={<InstagramOutlined className="_mr-1" />} theme="outline-black" onClick={() => openSite('instagram', instagram)} />
              )}
              {facebook && (
                <Button icon={<FacebookOutlined className="_mr-1" />} theme="outline-black" onClick={() => openSite('facebook', facebook)} />
              )}
              {linkedin && (
                <Button icon={<LinkedinOutlined className="_mr-1" />} theme="outline-black" onClick={() => openSite('linkedin', linkedin)} />
              )}
            </div>
          </div>
        ) : (
          <div className="search-store-card-content-mobile__image-alone">
            <ImageLoad width={96} url={logoUrl} />
          </div>
        )}
      </div>
    </Card>
  )
}

export default CardStore