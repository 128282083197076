const ActionType = {
  CREDITCARD_DATA: "CreditCardAction.CREDITCARD_DATA",
  CREDITCARD_DATA_LOADING: "CreditCardAction.CREDITCARD_DATA_LOADING",
  CREDITCARD_CREATE_LOADING: "CreditCardAction.CREDITCARD_CREATE_LOADING",
  CREDITCARD_DATA_ERROR: "CreditCardAction.CREDITCARD_DATA_ERROR",
  CREDITCARD_CREATE_ERROR: "CreditCardAction.CREDITCARD_CREATE_ERROR",
  CREDITCARD_SELECTED: "CreditCardAction.CREDITCARD_SELECTED",
  CREDITCARD_SECURITY_DATA: "CreditCardAction.CREDITCARD_SECURITY_DATA",
}

export default ActionType