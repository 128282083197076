import React, { useState } from 'react'
import { Form, Alert, Divider } from 'antd'
import * as AccountAction from 'store/account/Action'
import { connect } from 'react-redux'
import { AppState } from 'store'
import Grid, { GridSize } from 'components/atom/grid'
import FormInput from 'components/molecule/form_input'
import FormSelect from 'components/molecule/form_select'
import Button from 'components/atom/button'
import StateMachine, { StateMachineType } from 'libs/statemachine'

import './styles.scss'

interface UserDataEditingProps {
  initialValues: any;
  accountUpdate?: any;
  update?: any;
  fields?: string;
}

const UserFormEditing: React.FC<UserDataEditingProps> = ({ initialValues, accountUpdate, update }) => {

  const [message, setMessage] = useState<any>({})

  const onSubmit = async (form: any) => {
    setMessage({})

    const data = Object.keys(form)

    const fields = data.map((field: string) => {
      return {
        field,
        value: form[field],
        additional: { entity: 'address', uCode: initialValues.uCode }
      }
    })

    const result = await accountUpdate({ fields })

    if (!result.hasError) {
      setMessage({ success: true })
    } else {
      setMessage({ success: false })
    }
  }

  const typeAddressOption = [
    { value: "Rua", text: "Rua" },
    { value: "Avenida", text: "Avenida" },
    { value: "Via", text: "Via" },
    { value: "Rodovia", text: "Rodovia" },
    { value: "Acesso", text: "Acesso" },
    { value: "Alameda", text: "Alameda" },
    { value: "Alto", text: "Alto" },
    { value: "Estrada", text: "Estrada" },
    { value: "Ladeira", text: "Ladeira" },
    { value: "Largo", text: "Largo" },
    { value: "Parque", text: "Parque" },
    { value: "Praca", text: "Praca" },
    { value: "Quadra", text: "Quadra" },
    { value: "Travessa", text: "Travessa" },
    { value: "Vale", text: "Vale" },
    { value: "Viaduto", text: "Viaduto" },
    { value: "Viela", text: "Viela" },
    { value: "Vila", text: "Vila" },
  ]

  return (
    <Form initialValues={{ ...initialValues }} onFinish={onSubmit} >
      <Grid size={GridSize.Two}>
        <FormInput label="CEP" name="zipCode" required disabled={StateMachine.in(update?.loading, StateMachineType.Loading)} />
      </Grid>

      <div className="address_number_form">
        <div className="account_user-address__type-address">
          <FormSelect label="Tipo do endereço" name="typeStreet" disabled={StateMachine.in(update?.loading, StateMachineType.Loading)} option={typeAddressOption} required/>
          <FormInput boxClassName="account_user-address__type-address-street" label="Rua/Logradouro" name="street" required disabled={StateMachine.in(update?.loading, StateMachineType.Loading)} />
        </div>
        <FormInput label="Número" name="number" disabled={StateMachine.in(update?.loading, StateMachineType.Loading)} />
      </div>

      <Grid size={GridSize.Three} className="account_user-address__grid">
        <FormInput label="Bairro" name="neighborhood" required disabled={StateMachine.in(update?.loading, StateMachineType.Loading)} />
        <FormInput label="Cidade" name="city" required disabled={StateMachine.in(update?.loading, StateMachineType.Loading)} />
        <FormInput label="Estado" name="state" required disabled={StateMachine.in(update?.loading, StateMachineType.Loading)} />
      </Grid>
      
      <Divider />
      <Button className="_button_default_action" htmlType="submit" theme="primary" loading={StateMachine.in(update?.loading, StateMachineType.Loading)} block> 
        Salvar endereço
      </Button>

      {message?.success && (
        <Alert message="Os dados foram atualizados com sucesso." type="success" showIcon className="_mt-4" />
      )}

      {message?.success === false && (
        <Alert message="Ops, houve algum erro. Tente novamente." type="error" showIcon className="_mt-4" />
      )}
    </Form>
  )
}

const mapStateToProps = ({ account }: AppState) => {
  return {
    update: account?.update,
  }
}

const mapDispatchToProps = {
  accountUpdate: AccountAction.accountUpdate,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserFormEditing);