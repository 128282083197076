import baseReducer from 'store/utilities/baseReducer'
import ActionType from './ActionType'
import { StateMachineType } from 'libs/statemachine';

interface AccountDataState {
  readonly loading?: StateMachineType;
  readonly error?: boolean;
  readonly value?: any;
}

interface AccountUpdateState {
  readonly loading?: StateMachineType;
  readonly error?: boolean;
}

interface AccountVerifyState {
  readonly loading?: StateMachineType;
  readonly error?: boolean;
  readonly data?: any;
}

export interface IAccountState {
  readonly data?: AccountDataState;
  readonly update?: AccountUpdateState;
  readonly verify?: AccountVerifyState;
}

const initialState: IAccountState = {
  data: {
    loading: StateMachineType.NotStarted,
    error: false,
    value: null,
  },
  update: {
    loading: StateMachineType.NotStarted,
    error: false,
  },
  verify: {
    loading: StateMachineType.NotStarted,
    error: false,
    data: {}
  }
};

const accountReducer = baseReducer(initialState, {
  [ActionType.ACCOUNT_INFO_DATA_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      data: {
        ...state.data, 
        loading: action.payload,
      }
    };
  },
  [ActionType.ACCOUNT_INFO_DATA_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      data: {
        ...state.data, 
        error: action.payload,
      }
    };
  },
  [ActionType.ACCOUNT_INFO_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      data: {
        ...state.data, 
        value: action.payload,
      }
    };
  },
  [ActionType.ACCOUNT_UPDATE_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      update: {
        ...state.update, 
        loading: action.payload,
      }
    };
  },
  [ActionType.ACCOUNT_UPDATE_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      update: {
        ...state.update, 
        error: action.payload,
      }
    };
  },
  [ActionType.ACCOUNT_VERIFY_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      verify: {
        ...state.verify, 
        loading: action.payload,
      }
    };
  },
  [ActionType.ACCOUNT_VERIFY_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      verify: {
        ...state.verify, 
        error: action.payload,
      }
    };
  },
  [ActionType.ACCOUNT_VERIFY_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      verify: {
        ...state.verify, 
        data: action.payload,
      }
    };
  },
});

export default accountReducer