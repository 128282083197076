export const pt = {
  translation: {
    "vehicle_in_all_places": "Veículos em qualquer lugar do Brasil",
    "reserver_near_you" : "Reserve os melhores veículos próximos a você.",
    "choose_place": "Lugar de retirada",
    "city": "Cidade",
    "type_name_city": "digite o nome da cidade",
    "select_the_date": "Seleciona a data e hora de retirada e entrega",
    "chosen_date": "Data e hora de retirada e entrega",
    "search": "Procurar",
    "choose_pickup": "Data de Retirada",
    "choose_return": "Data de Devolução",
    "choose_time_pickup": "Hora de Retirada",
    "choose_time_return": "Hora de Devolução",
    "choose_mobile_pickup": "Data e hora de Retirada",
    "choose_mobile_return": "Data e hora de Devolução",
    "change_date_mobile": "Clique para alterar",
    "change_date_mobile_title": "Alterar datas",
    "see_that_store": "Ver carros",
    "see_on_map": "Ver no mapa",
    "go_website": "Ir para o site",
    "change": "Trocar",
    "price_per_day": "Preço por dia",
    "price_per_hour": "Preço por hora",
    "total_days": "{day, plural, =0 {Preço final} =1 {Preço por 1 dia} other {Preço por # dias}}",
    "total_hours": "{hour, plural, =0 {Preço final} =1 {Preço por 1 hora} other {Preço por # horas}}",
    "taxes_fees": "Impostos e Taxas",
    "tax_administration_price": "Taxa de admin.",
    "discount": "Desconto ( {discount}% )",
    "total_price": "Preço Total",
    "rent": "Alugar",
    "charges": "Pagamento",
    "validationerror_name": "Você deve informar o nome",
    "validationerror_email": "Você deve informar o email",
    "validationerror_password": "Você deve escolher uma senha",
    "validationerror_acceptTerms": "Você deve aceitar os termos de uso",
    "validationerror_manufactureYear": "Ano de fabricação inválido",
    "validationerror_modelYear": "Ano de modelo inválido",
    "account_not_authorized": "Ops! Você não está autorizado ou houve algum erro na solicitação.",
    "generic_error": "Ops! Acorreu um erro inesperado, tente novamente ou entre em contato com o suporte.",
    "rent_done_info": "Pronto! Seu aluguel foi feito com sucesso! Agora é só ir até a loja e buscar o veículo na data e hora solicitados.",
    "reservation_code": "Código da sua reserva",
    "reservation_code_movida": "Leve esse código na hora da sua reserva.",
    "go_my_reservation": "Ir para minhas reservas",
    "pick_up": "Retirada",
    "drop_off": "Entrega",
    "confirm": "Confirmar",
    "close": "Fechar",
    "payment_not_authorized": "Ops, não foi possível aprovar o pagamento.",
    "login": "Fazer Login",
    "my_account": "Minha Conta",
    "place": "Local",
    "ts_vehicle_automatic": "Automático",
    "ts_vehicle_manual": "Manual",
    "insurance_price": "Preço do seguro",
    "date_not_valid": "Essa data não é válida",
    "vehicle_already_has_booking": "Este veículo já está alugado nesta data.",
    "company_not_approved": "A sua empresa ainda está em fase aprovação, aguarde até o processo de validação ser finalizado.",
    "booking_error_cpf": "Este CPF não é válido.",
    "booking_error_cvv": "Os dados do cartão estão incorretos.",
    "validationerror_vehicleUpload": "O veículo deve ter pelo menos 1 foto.",
    "account_created_error": "Ops, parece que há algo faltando em seu cadastro.",
    "account_already_exist": "Já existe uma conta para esse email.",
    "cupom_error_invalid": "Ops! Este cupom não é valido.",
    "booking_error_cupom_code_invalid": "Ops! Este cupom não é valido.",
    "favorite": "Favoritar",
    "favorited": "Favoritado",
    "validationerror_description": "Você deve incluir uma descrição.",
    "booking_error_insurance_validate_error": "Ops! Esse veículo não pode ser validado junto ao nosso seguro.",
    "vehicle_group_already_registered": "Categoria de veículo já cadastrada.",
    "validationerror_phone": "Você deve informar o telefone",
    "validationerror_cnhNumber": "Você deve informar o número da CNH",
    "validationerror_cnhExpirationDate": "Você deve informar a data de validade da CNH",
    "manual_select_the_date": "Selecione a data e hora de inicio e fim da reserva manual",
    "vehicle_group_not_available": "Não há veículos disponíveis no grupo para essa data.",
    "validationerror_seats": "Você deve informar o número de lugares",
    "validationerror_doors": "Você deve informar o número de portas",
    "ts_company_question_car_condition": "O cliente entregou o veículo com as codições acordadas?",
    "ts_company_question_user_rating": "Qual a avaliação do cliente?",
    "quote_forbidden": "A cotação não pode ser criada com uma conta empresarial.",
    "validationerror_site": "Você deve incluir http:// ou https://",
    "validationerror_facebook": "Você deve incluir http:// ou https://",
    "validationerror_instagram": "Você deve incluir http:// ou https://",
    "validationerror_linkedin": "Você deve incluir http:// ou https://",
  }
}

export const DEFAULT_ERROR_MESSAGE = "account_not_authorized"
export const GENERIC_ERROR_MESSAGE = "generic_error"