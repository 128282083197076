import { Dispatch } from 'redux'
import FetchApi from 'libs/fetch'
import * as ActionUtility from 'store/utilities/actionUtility'
import ActionType from './ActionType'

export const createAndEdit = async (form: any, url: string | undefined) => {
  const fetch = form.uCode ? await FetchApi.put(url, form, true) : 
    await FetchApi.post(url, form, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const fetchEditData = async (uCode: string, url: string | undefined, queryStringParameters = '') => {

  const fetch = await FetchApi.get(`${url}?uCode=${uCode}${queryStringParameters}`, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const setLoading = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.FORM_LOADING, payload))

export const setLoadingStart = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.FORM_LOADING_START, payload))

export const setSuccess = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.FORM_SUCCESS, payload))

export const setError = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.FORM_ERROR, payload))

export const setErrorPermission = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.FORM_PERMISSION_ERROR, payload))

export const setData = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.FORM_DATA, payload))

export const setEditing = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.FORM_DATA_EDITING, payload))