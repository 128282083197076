export enum Environment {
  Development,
  DevelopmentWithProduction,
  Homolog,
  Production,
}

interface ConfigEnvironment {
  base: Environment;
  firm: Environment;
  auth: Environment;
  vehicle: Environment;
  admin: Environment;
  chat: Environment;
}

class Config {
    static get restrictTime(): { hours: number[], minutes: number[]} {
      const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
      const minutes = [0, 15, 30, 45]

      return { hours, minutes }
    }

    static get role() {
      return {
        admin: 1,
        user: 2,
        company: 3,
        finance: 6,
        operator: 7,
        firm: 8
      }
    }

    static isRoleCompany(roleId: number) {
      if (roleId === Config.role.company || roleId === Config.role.finance || roleId === Config.role.operator) {
        return true
      }

      return false
    }

    static get environment(): ConfigEnvironment {
      return {
        base: Environment.Production,
        firm: Environment.Production,
        auth: Environment.Production,
        vehicle: Environment.Production,
        admin: Environment.Production,
        chat: Environment.Production,
      }
    }
}

export default Config