import React, { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Router } from 'react-router'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import history from 'libs/history'
import store from 'store'
import { ConfigProvider } from 'antd'
import { Provider } from "react-redux"
import ptBR from 'antd/es/locale/pt_BR'

import Landpage from 'pages/Landpage'
import LandpageCompany from 'pages/LandpageCompany'
// import LandpageSubscription from 'pages/LandpageSubscription'
// import LandpageSubscriptionVehicle from 'pages/LandpageSubscriptionVehicle'
import LandpagePartner from 'pages/LandpagePartner'

import UserAccount from 'pages/UserAccount'
import UserLostPassword from 'pages/UserLostPassword'
import UserRequireLostPassword from 'pages/UserRequireLostPassword'
import UserSearchStore from 'pages/UserSearchStore'
import UserSearchVehicle from 'pages/UserSearchVehicle'
import UserSearchAllVehicle from 'pages/UserSearchAllVehicle'
import UserFormRequest from 'pages/UserFormRequest'
import UserFormRequestSuccess from 'pages/UserFormRequest/success'
import UserRentDetail from 'pages/UserRentDetail'
import UserRentDone from 'pages/UserRentDone'

import CompanySignUp from 'pages/CompanySignUp'

// Menu Mobile;
import UserAccountMenuMobile from 'pages/UserAccount/accountMenuMobile'
import AccountUserBooking from 'pages/UserAccount/accountUserBooking'
import AccountUserFavorite from 'pages/UserAccount/accountUserFavorite'
import CreditCard from 'components/organism/creditcard'
import AccountUserData from 'pages/UserAccount/accountUserData'
import { withSuspense, withSuspensePainel } from 'libs/hoc'

//---- withSuspense ----

// User
const UserSignIn = withSuspense(lazy(() => import (/* webpackChunkName: "page_user_signin" */ 'pages/UserSignIn')))
const UserSignUp = withSuspense(lazy(() => import ('pages/UserSignUp')))

// Company;
const CompanyDashboard = withSuspensePainel(lazy(() => import ('pages/CompanyDashboard')))

const CompanyBook = withSuspensePainel(lazy(() => import ('pages/CompanyBook')))
const CompanyBookAdd = withSuspensePainel(lazy(() => import ('pages/CompanyBookAdd')))
const CompanyBookDetail = withSuspensePainel(lazy(() => import ('pages/CompanyBookDetail')))

const CompanyVehicle = withSuspensePainel(lazy(() => import ('pages/CompanyVehicle')))
const CompanyVehicleAdd = withSuspensePainel(lazy(() => import ('pages/CompanyVehicleAdd')))
const CompanyVehicleDetail = withSuspensePainel(lazy(() => import ('pages/CompanyVehicleDetail')))
const CompanyVehicleGroupDetail = withSuspensePainel(lazy(() => import ('pages/CompanyVehicleGroupDetail')))

const CompanyBid = withSuspensePainel(lazy(() => import ('pages/CompanyBid')))
const CompanyBidDetail = withSuspensePainel(lazy(() => import ('pages/CompanyBidDetail')))
const CompanyBidDetailAdd = withSuspensePainel(lazy(() => import ('pages/CompanyBidDetailAdd')))

const CompanyFinance = withSuspensePainel(lazy(() => import ('pages/CompanyFinance')))
const CompanyFinanceVehicle = withSuspensePainel(lazy(() => import ('pages/CompanyFinanceVehicle')))

const CompanyDiscount = withSuspensePainel(lazy(() => import ('pages/CompanyDiscount')))
const CompanyDiscountAdd = withSuspensePainel(lazy(() => import ('pages/CompanyDiscountAdd')))
const CompanyCalendar = withSuspensePainel(lazy(() => import ('pages/CompanyCalendar')))
const CompanyVehicleCalendar = withSuspensePainel(lazy(() => import ('pages/CompanyVehicleCalendar')))

const CompanyClient = withSuspensePainel(lazy(() => import ('pages/CompanyClient')))
// const CompanyQuote = withSuspensePainel(lazy(() => import ('pages/CompanyQuote')))
// const CompanyQuoteDetail = withSuspensePainel(lazy(() => import ('pages/CompanyQuoteDetail')))

const CompanyServices = withSuspensePainel(lazy(() => import ('pages/CompanyServices')))

// Firm;
const FirmDashboard = withSuspensePainel(lazy(() => import ('pages/FirmDashboard')))
const FirmBid = withSuspensePainel(lazy(() => import ('pages/FirmBid')))
const FirmBidAdd = withSuspensePainel(lazy(() => import ('pages/FirmBidAdd')))
const FirmBidDetail = withSuspensePainel(lazy(() => import ('pages/FirmBidDetail')))

// Admin;
const AdminDashboard = withSuspensePainel(lazy(() => import ('pages/AdminDashboard')))
const AdminUser = withSuspensePainel(lazy(() => import ('pages/AdminUser')))
const AdminUserAdd = withSuspensePainel(lazy(() => import ('pages/AdminUserAdd')))
const AdminCompany = withSuspensePainel(lazy(() => import ('pages/AdminCompany')))
const AdminCompanyAdd = withSuspensePainel(lazy(() => import ('pages/AdminCompanyAdd')))
const AdminCompanySimpleAdd = withSuspensePainel(lazy(() => import ('pages/AdminCompanySimpleAdd')))
const AdminVehicle = withSuspensePainel(lazy(() => import ('pages/AdminVehicle')))
const AdminBook = withSuspensePainel(lazy(() => import ('pages/AdminBook')))
const AdminQuote = withSuspensePainel(lazy(() => import ('pages/AdminQuote')))
const AdminQuoteDetail = withSuspensePainel(lazy(() => import ('pages/AdminQuoteDetail')))
const AdminCompanyVehicle = withSuspensePainel(lazy(() => import ('pages/AdminCompanyVehicle')))

const GTM_PARAMS = { id: 'GTM-TL4QXL4' }

const queryClient = new QueryClient()

function App() {
  return (
    <GTMProvider state={GTM_PARAMS}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ConfigProvider locale={ptBR}>
            <Router history={history}>
              <Switch>
                { /*<!-- User Routes -->*/ }
                <Route exact path={"/parceiro"} component={() => <LandpagePartner />} />
                <Route exact path={"/concessionaria"} component={() => <LandpageCompany />} />
                <Route exact path={"/"} component={() => <Landpage />} />

                <Route exact path={"/account/user"} component={() => <UserAccount />} />
                
                <Route exact path={"/signin"} render={props => <UserSignIn {...props} />} />
                <Route exact path={"/signup"} render={props => <UserSignUp {...props} />} />

                <Route exact path={"/forgotpassword"} component={() => <UserLostPassword />} />
                <Route exact path={"/requirepassword"} component={() => <UserRequireLostPassword />} />
                <Route exact path={"/pages/store"} component={() => <UserSearchStore />} />
                <Route exact path={"/pages/vehicle"} component={() => <UserSearchVehicle />} />
                <Route exact path={"/my/:companyTag"} component={() => <UserSearchVehicle />} />
                <Route exact path={"/pages/vehicle/all"} component={() => <UserSearchAllVehicle />} />
                <Route exact path={"/pages/request"} component={() => <UserFormRequest />} />
                <Route exact path={"/pages/request/success"} component={() => <UserFormRequestSuccess />} />
                <Route exact path={"/pages/vehicle/rent/detail"} component={() => <UserRentDetail />} />
                <Route exact path={"/pages/vehicle/rent/done"} component={() => <UserRentDone />} />

                { /*<!-- Mobile Menu Routes -->*/ }
                <Route 
                  exact 
                  path={"/account/user/mobile/book"} 
                  component={() => <UserAccountMenuMobile title="Minhas reservas"> <AccountUserBooking /> </UserAccountMenuMobile>} />

                <Route 
                  exact 
                  path={"/account/user/mobile/favorite"} 
                  component={() => <UserAccountMenuMobile title="Meus favoritos"> <AccountUserFavorite /> </UserAccountMenuMobile>} />

                <Route 
                  exact 
                  path={"/account/user/mobile/creditcard"} 
                  component={() => <UserAccountMenuMobile title="Meus cartões"> <CreditCard className="_ml-5 _mr-5" hideSecurityFields={true} /> </UserAccountMenuMobile>} />

                <Route 
                  exact 
                  path={"/account/user/mobile/data"} 
                  component={() => <UserAccountMenuMobile title="Meus dados"> <AccountUserData /> </UserAccountMenuMobile>} />
                
                { /*<!-- Company Routes -->*/ }
                <Route exact path={"/signup/company"} component={() => <CompanySignUp />} />
                <Route exact path={"/account/company/dashboard"} render={props => <CompanyDashboard {...props} />} />

                <Route exact path={"/account/company/bid"} render={props => <CompanyBid {...props} />} />
                <Route exact path={"/account/company/bid/detail/:uCode"} render={props => <CompanyBidDetail {...props} />} />
                <Route exact path={"/account/company/bid/detail/add/:uCode"} render={props => <CompanyBidDetailAdd {...props} />} />

                <Route exact path={"/account/company/book"} render={props => <CompanyBook {...props} />} />
                <Route exact path={"/account/company/book/add"} render={props => <CompanyBookAdd {...props} />} />
                <Route exact path={"/account/company/book/detail/:uCode"} render={props => <CompanyBookDetail {...props} />} />
                <Route exact path={"/account/company/book/calendar"} render={props => <CompanyCalendar {...props} />} />
                
                <Route exact path={"/account/company/vehicle"} render={props => <CompanyVehicle {...props} />} />
                <Route exact path={"/account/company/vehicle/add"} render={props => <CompanyVehicleAdd {...props} />} />
                <Route exact path={"/account/company/vehicle/edit/:uCode"} render={props => <CompanyVehicleAdd {...props} />} />
                <Route exact path={"/account/company/vehicle/group/edit/:uCode"} render={props => <CompanyVehicleAdd {...props} />} />
                <Route exact path={"/account/company/vehicle/detail/:uCode"} render={props => <CompanyVehicleDetail {...props} />} />
                <Route exact path={"/account/company/vehicle/group/detail/:uCode"} render={props => <CompanyVehicleGroupDetail {...props} />} />
                <Route exact path={"/account/company/vehicle/calendar"} render={props => <CompanyVehicleCalendar {...props} />} />

                <Route exact path={"/account/company/finance"} render={props => <CompanyFinance {...props} />} />
                <Route exact path={"/account/company/vehicle/finance"} render={props => <CompanyFinanceVehicle {...props} />} />

                <Route exact path={"/account/company/user"} render={props => <AdminUser {...props} />} />
                <Route exact path={"/account/company/user/add"} render={props => <AdminUserAdd {...props} />} />
                <Route exact path={"/account/company/user/edit/:uCode"} render={props => <AdminUserAdd {...props} />} />

                <Route exact path={"/account/company/discount"} render={props => <CompanyDiscount {...props} />} />
                <Route exact path={"/account/company/discount/add"} render={props => <CompanyDiscountAdd {...props} />} />
                <Route exact path={"/account/company/discount/edit/:uCode"} render={props => <CompanyDiscountAdd {...props} />} />

                <Route exact path={"/account/company/client"} render={props => <CompanyClient {...props} />} />

                <Route exact path={"/account/company/services"} render={props => <CompanyServices {...props} />} />

                {/* <Route exact path={"/account/company/quote"} render={props => <CompanyQuote {...props} />} />
                <Route exact path={"/account/company/quote/detail/:uCode"} render={props => <CompanyQuoteDetail {...props} />} /> */}
                <Route exact path={"/account/company/config/:uCode"} render={props => <AdminCompanyAdd {...props} />} />

                { /*<!-- Firm Routes -->*/ }
                <Route exact path={"/account/firm"} render={props => <FirmDashboard {...props} />} />
                <Route exact path={"/account/firm/bid"} render={props => <FirmBid {...props} />} />
                <Route exact path={"/account/firm/bid/add"} render={props => <FirmBidAdd {...props} />} />
                <Route exact path={"/account/firm/bid/detail/:uCode"} render={props => <FirmBidDetail {...props} />} />

                { /*<!-- Admin Routes -->*/ }
                <Route exact path={"/account/admin/dashboard"} render={props => <AdminDashboard {...props} />} />
                <Route exact path={"/account/admin/book"} render={props => <AdminBook {...props} />} />
                <Route exact path={"/account/admin/user"} render={props => <AdminUser {...props} />} />
                <Route exact path={"/account/admin/company"} render={props => <AdminCompany {...props} />} />
                <Route exact path={"/account/admin/company/add"} render={props => <AdminCompanySimpleAdd {...props} />} />
                <Route exact path={"/account/admin/company/edit/:uCode"} render={props => <AdminCompanySimpleAdd {...props} />} />
                <Route exact path={"/account/admin/vehicle"} render={props => <AdminVehicle {...props} />} />
                <Route exact path={"/account/admin/quote"} render={props => <AdminQuote {...props} />} />
                <Route exact path={"/account/admin/quote/detail/:uCode"} render={props => <AdminQuoteDetail {...props} />} />
                <Route exact path={"/account/admin/company/:uCode/vehicle"} render={props => <AdminCompanyVehicle {...props} />} />
                <Route exact path={"/account/admin/company/:uCodeCompany/vehicle/group/add"} render={props => <CompanyVehicleAdd {...props} />} />
                <Route exact path={"/account/admin/company/:uCodeCompany/vehicle/group/edit/:uCode"} render={props => <CompanyVehicleAdd {...props} />} />
              </Switch>
            </Router>
          </ConfigProvider>
        </Provider>
      </QueryClientProvider>
    </GTMProvider>
  );
}

export default App;
