import React from 'react'
import { Form } from 'antd'
import Layout from 'components/atom/layout'
import Body from 'components/atom/body'
import FormInput, { InputMask } from 'components/molecule/form_input'
import FormSelect from 'components/molecule/form_select'
import HeaderSubscription from 'components/organism/header_subscription'
import FooterSubscription from 'components/organism/footer_subscription'
import { useExecuterStore } from 'store/executer/Hooks'
import { FeatureExecuter } from 'store/executer/Action'
import StateMachine from 'libs/statemachine'
import { Clock, Security, Money, Doc } from 'assets'
import { ReactComponent as TodoMundoSVG } from './assets/todo-mundo.svg'

import "./styles.scss"


const LandpagePartner: React.FC<any> = () => {
  const { executeAction: contactAction, viewState } = useExecuterStore({ 
    feature: FeatureExecuter.ContactLandpageCompany 
  })

  const contactOption = [
    { value: "whatsapp", text: "Whatsapp" },
    { value: "email", text: "Email" },
    { value: "telefone", text: "Telefone" },
  ]

  const onSubmit = async (form: any) => {
    contactAction({ bodyParams: form })
  }

  return (
    <Layout hasHeader={false}>
      <Body hasHeader={false}>

        <div>
          <HeaderSubscription withBackground={false} />
        </div>

        <div className="landpage-subscription-banner">
          <div className="landpage-subscription-banner__menu">
            <a href="/">Home</a>
          </div>

          <div className="landpage-subscription-banner__content">
            <div className="landpage-subscription-banner__title">Alcance novos destinos</div>
            <div className="landpage-subscription-banner__subtitle">
              Faça parte da plataforma que irá gerenciar suas reservas e alavancar os resultados da sua locadora!
            </div>
            <a href="#vantagens-closecar">
              <div className="landpage-subscription-banner__button">
                Seja um parceiro!
              </div>
            </a>
          </div>
        </div>

        <div id="vantagens-closecar" className="landpage-subscription-boxes">
          <div className="landpage-subscription-boxes__title">
            Vamos juntos transformar o futuro da mobilidade? Conheça os benefícios em ser Closecar.
          </div>

          <div className="landpage-subscription-boxes__grid">
            <div className="landpage-subscription-boxes__grid__box">
              <div>
                <Clock className="landpage-subscription-boxes__grid__box__icon-mobile" />
              </div>
              <div>
                <div className="landpage-subscription-boxes__grid__box__title">Gestão descomplicada</div>
                <div className="landpage-subscription-boxes__grid__box__description">
                  Conte com uma plataforma descomplicada e intuitiva! Tudo que você precisa para gerenciar suas reservas e criar novas possibilidades de alcance, mercado e tecnologia.
                </div>
              </div>
              <div>
                <Clock className="landpage-subscription-boxes__grid__box__icon" />
              </div>
            </div>
            <div className="landpage-subscription-boxes__grid__box">
              <div>
                <Security className="landpage-subscription-boxes__grid__box__icon-mobile" />
              </div>
              <div>
                <div className="landpage-subscription-boxes__grid__box__title">+ Confiabilidade para seus clientes</div>
                <div className="landpage-subscription-boxes__grid__box__description">
                    Fique tranquilo! Somos uma plataforma segura! Todos os dados são armazenados em nuvem, criptografados e aplicado a todos os direitos previstos na LGPD. Mais segurança para sua locadora e seus clientes!
                </div>
              </div>
              <div>
                <Security className="landpage-subscription-boxes__grid__box__icon" />
              </div>
            </div>
            <div className="landpage-subscription-boxes__grid__box">
              <div> 
                <Doc className="landpage-subscription-boxes__grid__box__icon-mobile" />
              </div>
              <div>
                <div className="landpage-subscription-boxes__grid__box__title">Monitore seus resultados</div>
                <div className="landpage-subscription-boxes__grid__box__description">
                  Acompanhe todas as demandas, explore ferramentas exclusivas para medir a evolução do seu mercado e obtenha relatórios gerenciais em um clique!
                </div>
              </div>
              <div>
                <Doc className="landpage-subscription-boxes__grid__box__icon" />
              </div>
            </div>
            <div className="landpage-subscription-boxes__grid__box">
              <div> 
                <Money className="landpage-subscription-boxes__grid__box__icon-mobile" />
              </div>
              <div>
                <div className="landpage-subscription-boxes__grid__box__title">Zero adesão e mensalidade</div>
                <div className="landpage-subscription-boxes__grid__box__description">
                  Não se preocupe com prejuízos! Viemos para somar! Nada de adesão ou mensalidades. Aqui, você alcança novas possibilidades e retribui através dos negócios gerados em nossa rede.
                </div>
              </div>
              <div>
                <Money className="landpage-subscription-boxes__grid__box__icon" />
              </div>
            </div>
          </div>

          <a href="#closecar-contato">
            <div className="landpage-subscription-boxes__button">
              Quer saber mais sobre como fazer parte da plataforma?
            </div>
          </a>
        </div>

        <div className="landpage-subscription-partnership">
          <div className="landpage-subscription-partnership__title">
            <div>
              <span className="landpage-subscription-partnership__title__highlight">Estamos em todo o Brasil. <br />Você está pronto para transformar o seu negócio?</span>
            </div>
            <TodoMundoSVG className="landpage-subscription-partnership__image" />
          </div>
        </div>

        <div id="closecar-contato" className="landpage-subscription-contact">
          <div className="landpage-subscription-contact__title">
            Preencha o formulário e faça parte do futuro da mobilidade:
          </div>

          <div className="landpage-subscription-contact__form">
            <div className="_mt-2">
              <Form name="basic" onFinish={onSubmit}>
                <FormInput 
                  inputClassName="landpage-subscription-contact__form__input" 
                  name="name" 
                  placeholder="Nome completo" 
                  disabled={StateMachine.isLoading(viewState)} 
                  required />

                <div className="landpage-subscription-contact__form__email_and_contact _mt-4">
                  <FormInput 
                    type="email" 
                    inputClassName="landpage-subscription-contact__form__input" 
                    name="email" 
                    placeholder="O seu melhor email" 
                    disabled={StateMachine.isLoading(viewState)} 
                    required />
                  
                  <div className="landpage-subscription-contact__form__select__box">
                    <FormSelect 
                      selectClassName="landpage-subscription-contact__form__select" 
                      placeholder="Qual a melhor forma de contato?" 
                      name="bestContact" 
                      bordered={false} 
                      option={contactOption} 
                      disabled={StateMachine.isLoading(viewState)} 
                      required />
                  </div>
                </div>

                <FormInput 
                  inputClassName="landpage-subscription-contact__form__input" 
                  name="phone" 
                  placeholder="Whatsapp ou Telefone" 
                  disabled={StateMachine.isLoading(viewState)} 
                  mask={InputMask.DynamicPhone}
                  required 
                />

                {StateMachine.isLoaded(viewState) && (
                  <div className="landpage-subscription-contact__form__success">
                    <div className="_mb-2">Pronto! Contato enviado com sucesso, em no máximo 24 horas entraremos em contato.</div>
                    <div>E fique a vontade para entrar com a gente pelo nosso <a href="https://wa.me/5531984330552" target="noopener noreferrer _blank">whatsapp</a>.</div>
                  </div>
                )}

                <button className="landpage-subscription-contact__form__button" type="submit" disabled={StateMachine.isLoading(viewState)}>
                  {StateMachine.isLoading(viewState) ? "Enviando..." : "Enviar"}
                </button>
              </Form>
            </div>
          </div>
        </div>
      </Body>
      
      <FooterSubscription />
    </Layout>
  )
}

export default LandpagePartner;