import React from 'react'
import './styles.scss'

const Body: React.FC<any> = (props) => {

  return (
    <main className={props.hasHeader ? `atom-layout-base-body ${props?.className}` : `atom-layout-base-body-without-header ${props?.className}`}>
      {props.children}
    </main>
  )
}

Body.defaultProps = {
  hasHeader: true
}

export default Body