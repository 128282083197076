import Grid, { GridSize } from 'components/atom/grid'
import React from 'react'

import "./styles.scss"

const FooterRequirements: React.FC<any> = (props) => {

  return (
    <div>
      <div className="footer_requirements_content _pt-8 _pb-6 _pl-6 _pr-6">

        <div className="footer_requirements_content__header">
          <div>
            <img src="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal_white.png" className="footer_requirements_content__logo" />
          </div>
          
          <div>
            <div className="footer_requirements_content__header_title _mb-4">Nunca foi tão fácil alugar um carro!</div>
            <div className="footer_requirements_content__header_subtitle">Veja o que você precisa para alugar um carro.</div>
          </div>
        </div>

        <Grid size={GridSize.Four} className="footer_requirements_content__mobile">
          <div className="footer_requirements_content__box">
            <div>
              <img src="https://53cars.s3-sa-east-1.amazonaws.com/check-box-with-check-sign.png" className="footer_requirements_content__check" />
            </div>

            <div className="footer_requirements_content__box-description">
              <div className="footer_requirements_content__box-description__title">Idade</div>
              <div className="footer_requirements_content__box-description__description">
                Ter entre 21 e 70 anos de idade. Caso você tem menos de 25 ou mais que 70 anos, você poderá ser obrigado a pagar uma taxa adicional.
              </div>
            </div>
          </div>

          <div className="footer_requirements_content__box">
            <div>
              <img src="https://53cars.s3-sa-east-1.amazonaws.com/check-box-with-check-sign.png" className="footer_requirements_content__check" />
            </div>
            <div className="footer_requirements_content__box-description">
              <div className="footer_requirements_content__box-description__title">CNH</div>
              <div className="footer_requirements_content__box-description__description">CNH definitiva dentro do prazo de vencimento, emitida há mais de 2 anos.</div>
            </div>
          </div>

          <div className="footer_requirements_content__box">
            <div>
              <img src="https://53cars.s3-sa-east-1.amazonaws.com/check-box-with-check-sign.png" className="footer_requirements_content__check" />
            </div>
            <div className="footer_requirements_content__box-description">
              <div className="footer_requirements_content__box-description__title">Documentos</div>
              <div className="footer_requirements_content__box-description__description">RG e CPF originais e comprovante de endereço. Não ter restrições de qualquer espécie junto aos órgãos de proteção ao crédito no momento da contratação. Estrangeiros Passaporte original e válido</div>
            </div>
          </div>

          <div className="footer_requirements_content__box">
            <div>
              <img src="https://53cars.s3-sa-east-1.amazonaws.com/check-box-with-check-sign.png" className="footer_requirements_content__check" />
            </div>
            <div className="footer_requirements_content__box-description">
              <div className="footer_requirements_content__box-description__title">Cartão de crédito</div>
              <div className="footer_requirements_content__box-description__description">Cartão de crédito válido, de sua titularidade, dentro do prazo de vencimento, emitido por uma instituição bancária e com limite de crédito disponível para pré-autorização da caução de garantia.</div>
            </div>
          </div>
        </Grid>
        <div className="footer_requirements_content__header_subtitle">*Condições sujeitas à alteração de acordo com a locadora.</div>
      </div>
    </div>
  )
}

export default FooterRequirements