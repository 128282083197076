import { useState, useEffect } from 'react'

const get = (key: string, json = false) => {
  try {
    const val = localStorage.getItem(key)

    if (val && json) {
      return JSON.parse(val)
    }

    return val
  } catch (e) {
    return null
  }
}

const set = (key: string, data: any, json = false) => {
  try {
    if (json) {
      return localStorage.setItem(key, JSON.stringify(data))
    }

    return localStorage.setItem(key, String(data))
  } catch (e) {
    return null
  }
}

const ALIAS_KEY_STORAGE = '@53_V1'

/**
 * Responsável pelo consumo dos dados guardados em storage;
 * 
 * GET: Quando chamado sem parametros irá retornar os dados salvos para chave específica;
 * SET: Quando chamado com parâmetros irá adicionar ou atualizar os dados; 
 */
export default class Storage {

  // @object
  static authorization = (data?: string) => {
    if (typeof data !== 'undefined') {
      return set(`${ALIAS_KEY_STORAGE}:oauth:authorization`, data, true)
    } else {
      return get(`${ALIAS_KEY_STORAGE}:oauth:authorization`, true)
    }
  }

  static hasAuthorization = () => {
    return get(`${ALIAS_KEY_STORAGE}:oauth:authorization`, true) !== null
  }

  // @boolean
  static cookie = (accept: boolean) => {
    return set(`${ALIAS_KEY_STORAGE}:cookies:accept`, { cookie: accept }, true)
  }

  static hasCookieAccept = () => {
    return get(`${ALIAS_KEY_STORAGE}:cookies:accept`, true) !== null
  }

  static clear = () => {
    localStorage.clear()
  }
}

export function useStorage(): { authorization: any } {

  const [authorization, setAuthorization] = useState(null);

  useEffect(() => {
    setAuthorization(Storage.authorization())
  }, [])

  return { authorization }
}