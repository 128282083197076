import { Dispatch } from "redux"
import StateMachine, { StateMachineType } from "libs/statemachine"
import * as AccountEffect from './Effect'
import { AppState } from "store"
import Storage from 'libs/storage'

export interface FavoriteCompanyParams {
  uCodeCompany: string
}

export const accountInfo = () => async (dispatch: Dispatch, getState: () => AppState) => {
  if (Storage.hasAuthorization()) {
    const accountStoreData = getState()?.account?.data

    if (StateMachine.in(accountStoreData?.loading, StateMachineType.Loaded)) {
      return false
    }

    AccountEffect.setError(dispatch, false)
    AccountEffect.setLoading(dispatch, StateMachineType.Loading)

    const account = await AccountEffect.accountInfo()

    if (account.hasError) {
      AccountEffect.setError(dispatch, true)
      AccountEffect.setLoading(dispatch, StateMachineType.Error)
    } else {
      AccountEffect.setError(dispatch, false)
      AccountEffect.setData(dispatch, account.data)
      AccountEffect.setLoading(dispatch, StateMachineType.Loaded)
      return account.data
    }
  } else {
    return null
  }
}

export const accountUpdate = (params: any) => async (dispatch: Dispatch) => {
  AccountEffect.setErrorUpdate(dispatch, false)
  AccountEffect.setLoadingUpdate(dispatch, StateMachineType.Loading)

  const update = await AccountEffect.accountUpdate(params)

  if (update.hasError) {
    AccountEffect.setErrorUpdate(dispatch, true)
    AccountEffect.setLoadingUpdate(dispatch, StateMachineType.Loaded)
    return { hasError: true }
  }

  AccountEffect.setErrorUpdate(dispatch, false)
  AccountEffect.setLoadingUpdate(dispatch, StateMachineType.Loaded)

  return { hasError: false, data: update?.data }
}

export const accountVerify = ({ email }: { email: string }) => async (dispatch: Dispatch) => {
  AccountEffect.setErrorVerify(dispatch, false)
  AccountEffect.setLoadingVerify(dispatch, StateMachineType.Loading)

  const result = await AccountEffect.accountVerify({ email })

  if (result.hasError) {
    AccountEffect.setErrorVerify(dispatch, true)
  } else {
    AccountEffect.setErrorVerify(dispatch, false)
    AccountEffect.setDataVerify(dispatch, result.data)
  }

  AccountEffect.setLoadingVerify(dispatch, StateMachineType.Loaded)
  return result.data || null
}

export const favoriteCompany = (body: FavoriteCompanyParams) => async () => {
  return AccountEffect.favoriteCompany(body)
}

export const accountContact = (body: any) => async () => {
  return AccountEffect.accountContact(body)
}

export const accountLogout = () => (dispatch: Dispatch) => {
  AccountEffect.setData(dispatch, null)
  AccountEffect.setLoading(dispatch, StateMachineType.NotStarted)

  const cookieAccept = Storage.hasCookieAccept()

  Storage.clear()

  // TODO: Find a better way to handle the cookie consent;
  Storage.cookie(cookieAccept)
}