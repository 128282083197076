import React, { useEffect } from 'react'
import { Layout, Card, Space } from 'antd'
import { SmileTwoTone } from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import ManageDate from 'libs/date'

import Header from 'components/organism/header'
import Footer from 'components/organism/footer'
import Content from 'components/atom/content'
import Text from 'components/atom/text'
import Button from 'components/atom/button'

import CardVehicle from 'pages/UserSearchVehicle/card'
import CardGroupVehicle from 'pages/UserSearchVehicle/cardGroup'
import CardStore from 'pages/UserRentDetail/cardStore'
import { VehicleSearched } from 'store/vehicle/Selector'
import Grid, { GridSize } from 'components/atom/grid'

import './styles.scss'

export interface QuerySearch {
  reservationCode?: string;
  movidaId?: string;
  start?: string;
  end?: string;
}

const UserRentDone = () => {

  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory<VehicleSearched>()
  const vehicle = history.location.state
  const params: QuerySearch = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
  const formatDate = ManageDate.formatRentDone(params.start, params.end)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onClickReservation = () => {
    history.push('/account/user')
  }

  return (
    <Layout>
      <Header />
      <Content>
        <Card className="_text-center _mt-5">
          <Space direction="vertical">
            <SmileTwoTone className="_text-7" />
            <Text className="_text-3">{t('rent_done_info')}</Text>
          </Space>
        </Card>

        <Card className="_text-center"> 
          <Space direction="vertical">
            <Text className="_text-2">{t('reservation_code')}</Text>
            <Text className="_text-6">{params.reservationCode}</Text>
            <Button onClick={onClickReservation} theme="outline-primary" block>{t('go_my_reservation')}</Button>
          </Space>
        </Card>

        <Card> 
          <Grid size={GridSize.Two} className="rent-done-date">
            <div className="_divider_right rent-done-date-remove-divider">
              <div className="_mb-4 _font-weight-bold">{t('pick_up')}</div>
              <div className="_text-3">{formatDate.startDayMonth}</div>
              <div className="_text-6 _font-weight">{formatDate.startTime}</div>
            </div>

            <div>
              <div className="_mb-4 _font-weight-bold">{t('drop_off')}</div>
              <div className="_text-3">{formatDate.endDayMonth}</div>
              <div className="_text-6 _font-weight">{formatDate.endTime}</div>
            </div>
          </Grid>
        </Card>

        {vehicle.groupTypeRegister === 'FULL' ? (
          <CardVehicle 
            className="_pl-5"
            vehicle={vehicle} 
            showPrice={false} />
        ):(
          <CardGroupVehicle 
            className="_pl-5"
            showPrice={false}
            vehicle={vehicle}
          />
        )}
        <CardStore company={vehicle?.company} className="_mb-6" />
      </Content>
      <Footer />
    </Layout>
  );
}

export default UserRentDone;
