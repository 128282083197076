import { Dispatch } from 'redux'
import { StateMachineType } from 'libs/statemachine'
import * as GetterEffect from 'store/getter/Effect'

export enum FeatureGetter {
  GetVehicle = "GET_VEHICLE",
  GetVehicleAll = "GET_VEHICLE_ALL",
  GetVehicleAllDetail = "GET_VEHICLE_ALL_DETAIL",
  GetVehicleGroup = "GET_VEHICLE_GROUP",
  GetVehicleGroupType = "GET_VEHICLE_GROUP_TYPE",
  GetVehicleGroupAvailability = "GET_VEHICLE_GROUP_AVAILABILITY",
  GetVehicleFinance = "VEHICLE_FINANCE",
  GetVehicleCalendar = "VEHICLE_CALENDAR",
  GetCompanyCalendar = "COMPANY_CALENDAR",
  GetCompanyVehicleCalendar = "COMPANY_VEHICLE_CALENDAR",
  GetUser = "GET_USER",
  GetDiscount = "GET_DISCOUNT",
  GetCompany = "GET_COMPANY",
  GetBooking = "GET_BOOKING",
  GetFirmBid = "GET_FIRM_BID",
  GetUserCreditCard = "GET_USER_CREDIT_CARD",
  GetQuote = "GET_QUOTE",
  GetAdminQuote = "GET_ADMIN_QUOTE",
  AdminDashboard = "ADMIN_DASHBOARD",
  GetAdminQuoteDetail = "GET_ADMIN_QUOTE_DETAIL",
  GetCities = "GET_CITIES",
  GetCompanyCities = "GET_COMPANY_CITIES",
  GetCompanyClients = "GET_COMPANY_CLIENTS",
  GetCompanyQuotes = "GET_COMPANY_QUOTES",
  GetCompanyQuotesNote = "GET_COMPANY_QUOTES_NOTE",
}

export const features = {
  [FeatureGetter.GetVehicle]: {
    url: "/v1/vehicle"
  },
  [FeatureGetter.GetVehicleAll]: {
    url: "/v1/vehicle/all"
  },
  [FeatureGetter.GetVehicleAllDetail]: {
    url: "/v1/vehicle/all"
  },
  [FeatureGetter.GetVehicleGroup]: {
    url: "/v1/vehicle/group",
    keyValue: "uCode",
    keyText: "vehicleGroup"
  },
  [FeatureGetter.GetVehicleGroupType]: {
    url: "/v1/vehicle/group/type"
  },
  [FeatureGetter.GetVehicleGroupAvailability]: {
    url: "/v1/vehicle/group/availability/{uCode}"
  },
  [FeatureGetter.GetVehicleFinance]: {
    url: "/v1/vehicle/finance"
  },
  [FeatureGetter.GetVehicleCalendar]: {
    url: "/v1/vehicle/calendar/{uCode}"
  },
  [FeatureGetter.GetCompanyCalendar]: {
    url: "/v1/company/calendar"
  },
  [FeatureGetter.GetCompanyVehicleCalendar]: {
    url: "/v1/company/calendar"
  },
  [FeatureGetter.GetUser]: {
    url: "/v1/account"
  },
  [FeatureGetter.GetDiscount]: {
    url: "/v1/company/discount"
  },
  [FeatureGetter.GetCompany]: {
    url: "/v1/company"
  },
  [FeatureGetter.GetBooking]: {
    url: "/v1/booking"
  },
  [FeatureGetter.GetFirmBid]: {
    url: "/v1/firm/bid"
  },
  [FeatureGetter.GetUserCreditCard]: {
    url: "/v1/creditcard/{uCode}"
  },
  [FeatureGetter.GetQuote]: {
    url: "/v1/quote"
  },
  [FeatureGetter.GetAdminQuote]: {
    url: "/v1/admin/quote"
  },
  [FeatureGetter.AdminDashboard]: {
    url: "/v1/admin/dashboard"
  },
  [FeatureGetter.GetAdminQuoteDetail]: {
    url: "/v1/admin/quote/{uCode}"
  },
  [FeatureGetter.GetCities]: {
    url: "/v1/company/cities"
  },
  [FeatureGetter.GetCompanyCities]: {
    url: "/v1/company/mycities",
  },
  [FeatureGetter.GetCompanyClients]: {
    url: "/v1/company/clients",
  },
  [FeatureGetter.GetCompanyQuotes]: {
    url: "/v1/company/quotes",
  },
  [FeatureGetter.GetCompanyQuotesNote]: {
    url: "/v1/company/quotes/note",
  },
}

export const getData = ({ feature, queryParams = {}, pathParams = {}, signal }: { feature: FeatureGetter; queryParams?: any; pathParams?: any; signal: AbortSignal }) => async (dispatch: Dispatch) => {
  
  try {
    const dataFeature = features[feature]

    let url = dataFeature.url

    GetterEffect.setViewState(dispatch, { feature, viewState: StateMachineType.Loading })

    const flatQueryParams = Object.keys(queryParams).reduce((result: string | null, key: string) => {
      if (result) {
        return `${result}&${key}=${queryParams[key]}`
      } else {
        return `?${key}=${queryParams[key]}`
      }
    }, null) || ''

    const urlWithPathParams = Object.keys(pathParams).reduce((result: string, key: string) => {
      return result.replace(`{${key}}`, pathParams[key])
    }, url) || url

    const result = await GetterEffect.getData({ url: `${urlWithPathParams}${flatQueryParams}`, signal })

    if (result.hasError) {
      GetterEffect.setViewState(dispatch, { feature, viewState: StateMachineType.Error })
    } else {
      GetterEffect.setData(dispatch, { feature, data: result.data })
      GetterEffect.setViewState(dispatch, { feature, viewState: StateMachineType.Loaded })
    }

    return result.data
  } catch (e) {
    GetterEffect.setViewState(dispatch, { feature, viewState: StateMachineType.Error })
  }
}
