import FetchApi from 'libs/fetch'
import { Dispatch } from 'redux'
import * as CheckTokenEffect from './Effect'
import Storage from 'libs/storage'

export const checkToken = () => async (dispatch: Dispatch) => {
  if (Storage.hasAuthorization()) {
    CheckTokenEffect.setLoading(dispatch, true)

    const checkToken = await CheckTokenEffect.checkToken()

    if (checkToken.status === FetchApi.HttpErrorBadRequest) {
      Storage.clear()
      CheckTokenEffect.setData(dispatch, { hasError: true, data: null })
    } else {
      CheckTokenEffect.setData(dispatch, checkToken)
    }

    CheckTokenEffect.setLoading(dispatch, false)
  } else {
    CheckTokenEffect.setData(dispatch, { hasError: true, data: null })
  }
}