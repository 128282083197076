import React, { useState, useEffect, useRef } from 'react'
import ManageDate from 'libs/date'
import Config from 'libs/config'
import { DatePicker as AntDatePicker } from 'antd'
import FooterRangePicker from './footer'

interface RangePickerProps {
  onSelect: (params: any) => void,
  onSelectClick?: (params: any) => void,
  initialDate?: { start: string | undefined; end: string | undefined},
  disabledDate?: any,
  enableHour?: boolean,
  className?: string,
  rangeDays?: number,
  selectWhenStart?: boolean;
}

const { RangePicker: AntRangePicker } = AntDatePicker;

const RangePicker = (props: RangePickerProps) => {
  const restrict = { 
    hour: Config.restrictTime.hours, 
    minute: Config.restrictTime.minutes
  }

  const [openPicker, setOpenPicker] = useState(false)
  const [datesSelected, setDatesSelected] = useState<any>([
    ManageDate.dateWithRestrict(props?.initialDate?.start, 0, restrict), 
    ManageDate.dateWithRestrict(props?.initialDate?.end, props?.initialDate?.end ? 0 : props.rangeDays, restrict)
  ])

  useEffect(() => {
    if (props.selectWhenStart) {
      const dateStart = datesSelected[0]
      const dateEnd = datesSelected[1]

      if (dateStart.isBefore(dateEnd)) {
        props?.onSelect([datesSelected[0], datesSelected[1]])
      } else {
        props?.onSelect([datesSelected[1], datesSelected[0]])
      }
    }
  }, [])

  const onCalendarChange = (dates: any, dateStrings: [string, string], selection: any) => {
    if (selection.range === "start") {
      setDatesSelected([dates[0], null])
    } else if (selection.range === "end") {
      const dateStart = dates[0]
      const dateEnd = dates[1]

      props?.onSelect([dateStart, dateEnd])
      setDatesSelected([dateStart, dateEnd])
    }
  }

  const onOpenPicker = () => {
    setOpenPicker(true)
  }

  const onConfirmPicker = (hours: any) => {
    const dateStart = datesSelected[0]
    const dateEnd = datesSelected[1]

    const timeStart = `${hours.start.hour}:${hours.start.minute}`
    const endStart = `${hours.end.hour}:${hours.end.minute}`

    const getStartDate = ManageDate.mountDate(`${dateStart.format("YYYY-MM-DD")}T${timeStart}`)
    const getEndDate = ManageDate.mountDate(`${dateEnd.format("YYYY-MM-DD")}T${endStart}`)

    setDatesSelected([getStartDate, getEndDate])
    setOpenPicker(false)

    if (props?.onSelectClick) {
      props?.onSelectClick([getStartDate, getEndDate])
    }
  }

  return (
    <>
      {props.enableHour? (
        <AntRangePicker
          className={props.className}
          style={{
            width: "100%",
          }}
          format="DD/MM/YYYY HH:mm"
          size="large"
          value={datesSelected}
          open={openPicker}
          onOpenChange={onOpenPicker}
          onCalendarChange={onCalendarChange}
          renderExtraFooter={() => props.enableHour && (
            <FooterRangePicker 
              onClosePicker={() => setOpenPicker(false)} 
              onConfirmPicker={onConfirmPicker}
              initialDate={{ start: props?.initialDate?.start, end: props?.initialDate?.end }} />
          )}
          inputReadOnly
        />
      ) : (
        <AntRangePicker 
          className={props.className}
          style={{
            width: "100%",
          }}
          value={datesSelected}
          format="DD/MM/YYYY"
          size="large"
          onCalendarChange={onCalendarChange} />        
      )}
    </>
  )
}

RangePicker.defaultProps = {
  enableHour: true,
  onSelect: () => {},
  className: "",
  rangeDays: 3,
  selectWhenStart: true
}

export default RangePicker