import { Dispatch } from "redux";
import * as SignupEffect from './Effect'

export interface SignupParams {
  name: string;
  email: string;
  password: string;
  acceptTerms: boolean | number;
}

export interface SignupCompanyParams {
  name: string;
  tradingName: string;
  documentLegalNumber: string;
  phone: string;
  email: string;
  password: string;
  acceptTerms: boolean | number;
}

export const signup = ({ name, email, password, acceptTerms }: SignupParams) => async (dispatch: Dispatch) => {
  SignupEffect.setLoading(dispatch, true)
  const authorization = await SignupEffect.signup({ name, email, password, acceptTerms })

  if (authorization.hasError) {
    SignupEffect.setError(dispatch, authorization.data)  
  } else {
    SignupEffect.setSuccess(dispatch, true)
  }
  
  SignupEffect.setLoading(dispatch, false)
};

export const signupCompany = ({ name, tradingName, documentLegalNumber, phone, email, password, acceptTerms }: SignupCompanyParams) => async (dispatch: Dispatch) => {
  SignupEffect.setLoading(dispatch, true)
  const authorization = await SignupEffect.signupCompany({ name, tradingName, documentLegalNumber, phone, email, password, acceptTerms })

  if (authorization.hasError) {
    SignupEffect.setError(dispatch, authorization.data)  
  } else {
    SignupEffect.setSuccess(dispatch, true)
  }
  
  SignupEffect.setLoading(dispatch, false)
};