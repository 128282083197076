import { Dispatch } from 'redux'
import * as SigninEffect from './Effect'

export interface SigninParams {
  email: string;
  password: string;
}

export interface RequestPassword {
  email: string;
}

export interface ChangePassword {
  token: string;
  password: string;
}

export const signin = ({ email, password }: SigninParams) => async (dispatch: Dispatch) => {
  SigninEffect.setLoading(dispatch, true)
  const authorization = await SigninEffect.signin({ email, password })

  if (authorization.hasError) {
    SigninEffect.setError(dispatch, authorization.data)  
  } else {
    SigninEffect.setSuccess(dispatch, true)
  }
  
  SigninEffect.setLoading(dispatch, false)

  return { hasError: authorization.hasError, data: authorization.data }
}

export const requestPassword = ({ email }: RequestPassword) => async (dispatch: Dispatch) => {
  SigninEffect.setLoading(dispatch, true)
  const request = await SigninEffect.requestPassword({ email })

  if (request.hasError) {
    SigninEffect.setError(dispatch, request.data)  
  } else {
    SigninEffect.setSuccess(dispatch, true)
  }
  
  SigninEffect.setLoading(dispatch, false)

  return { hasError: request.hasError, data: request.data }
}

export const changePassword = ({ token, password }: ChangePassword) => async (dispatch: Dispatch) => {
  SigninEffect.setLoading(dispatch, true)
  const request = await SigninEffect.changePassword({ token, password })

  if (request.hasError) {
    SigninEffect.setError(dispatch, request.data)  
  } else {
    SigninEffect.setSuccess(dispatch, true)
  }
  
  SigninEffect.setLoading(dispatch, false)

  return { hasError: request.hasError, data: request.data }
}

export const clean = () => async (dispatch: Dispatch) => {
  SigninEffect.setLoading(dispatch, false)
  SigninEffect.setError(dispatch, null)
  SigninEffect.setSuccess(dispatch, null)
}