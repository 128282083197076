import React from 'react'
import './styles.scss'

export enum GridDirection {
    Rows = "rows",
    Columns = "columns"
}

export enum GridSize {
    One = "1",
    Two = "2",
    Three = "3",
    Four = "4",
    Five = "5",
}

interface GridProps {
  size: GridSize | number | string;
  direction?: GridDirection;
  className?: string;
  children: React.ReactNode;
}

const Grid: React.FC<GridProps> = (props) => {
  return (
    <div className={`atom-grid-${props.direction || "columns"}-content-${props.size} ${props.className ? props.className : ''}`}>
      {props.children}
    </div>
  )
}

export default Grid