import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card } from 'antd'
import Text from 'components/atom/text'
import EmptyBooking from 'components/molecule/empty/booking'
import ImageLoad from 'components/atom/image_load'
import Grid, { GridSize } from 'components/atom/grid'
import Loading, { Rendering } from 'components/molecule/loading'
import DescriptionVehicle from 'pages/UserSearchVehicle/descriptionVehicle'
import { StateMachineType } from 'libs/statemachine'
import { FeatureGetter } from 'store/getter/Action'
import { useGetterStore } from 'store/getter/Hooks'

import './styles.scss'

const AccountUserBooking: React.FC<any> = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const { getData: getBookings, data: dataBooking = [], viewState } = useGetterStore({ 
    feature: FeatureGetter.GetBooking 
  })
  
  useEffect(() => {
    getBookings()
  }, [])

  const onSelectStore = (type: string, uCode: string) => {
    history.push(`/pages/vehicle?type=${type}&uCode=${uCode}`)
  }

  const renderStateNotStarted = () => <Rendering className="_mb-6" />

  const renderStateLoading = () => <Loading className="_mb-6" />

  const renderStateLoaded = () => (
    <div className="_mb-4">
      {dataBooking?.map((book: any) => {
        const numberOfDay = book?.charges?.find((charge: any) => charge.type === "charge_total_number_of_days") 
        const chargeDiscount = book?.charges?.find((charge: any) => charge.type === "charge_discount")
        const chargeTotalUser = book?.charges?.find((charge: any) => charge.type === "charge_total_user_rent")
        const chargeTotal = book?.charges?.find((charge: any) => charge.type === "charge_total_rent")
        const chargeTotalPlatform = book?.charges?.find((charge: any) => charge.type === "charge_total_rent_to_platform")
        const chargeTotalCompany = book?.charges?.find((charge: any) => charge.type === "charge_total_rent_to_company")

        return (<Card className="_mb-4 account_user-booking-mobile-card">
          <div className="account_user-booking-desktop">
            <div className="account_user-booking _mb-6">
              <ImageLoad width={180} url={book.vehicle?.pictureURL} />
              <div className="_ml-4">
                <div className="_text-4 _mb-2"> Reserva <span className="_font-weight-bold">{book.reservationCode}</span></div>
                <div className="_text-3">
                  {book.vehicle.registerType === "FULL" ? book.vehicle.vehicleModelName : book.vehicle.vehicleGroupType.description}
                </div>
                <DescriptionVehicle vehicle={book.vehicle} />
              </div>
            </div>
            <Grid size={GridSize.Four}>
              <div className="_divider_right">
                <div className="_text-4 _mb-2">{t('pick_up')}</div>
                <div className="_text-3">{book.startRentDate}</div>
              </div>
              <div className="_ml-2 _divider_right">
                <div className="_text-4 _mb-2">{t('drop_off')}</div>
                <div className="_text-3 _mb-2">{book.endRentDate}</div>
                <div className="_text-2">Total de dias de locação: {numberOfDay?.amount} dia(s)</div>
              </div>
              <div className="_ml-2 _divider_right">
                <div className="_text-4 _mb-2">{t('place')}</div>
                <div className="_text-3 _mb-2"><span className="_font-weight-bold">{book.company.tradingName}</span></div>
                <div className="_text-2 _mb-2">{book.company.address.full}</div>
                <div className="_text-2">{book.company.phone} | {book.company.email}</div>
              </div>
              {!book.hiddenPrices && (
                <div className="_ml-2">
                  <div className="_text-4 _mb-2">{t('charges')}</div>
                  <div className="_text-2">Preço pago à plataforma: {chargeTotalPlatform?.amountFormat}</div>
                  <div className="_text-2 _mb-2">Preço pago à concessionária: {chargeTotalCompany?.amountFormat}</div>
                  <div className="_text-2">Preço final: {chargeTotal?.amountFormat}</div>
                  <div className="_text-2 _mb-2">Desconto: {chargeDiscount?.amountFormat}</div>
                  <div className="_text-3"><span className="_font-weight-bold">Total: {chargeTotalUser?.amountFormat}</span></div>
                </div>
              )}
            </Grid>
          </div>

          {/** Mobile Card */}
          <div className="account_user-booking-mobile">
            <div className="_text-center">
              <Text strong className="_text-2 _font-weight-bold">
                Reserva <span className="_font-weight-bold">{book.reservationCode}</span>
              </Text>
              <div className="_text-3">{book.vehicle.vehicleModelName}</div>
              <div className="account_user-booking-mobile__image">
                <ImageLoad width={180} url={book.vehicle?.pictureURL} />
              </div>
            </div>
            
            <DescriptionVehicle vehicle={book.vehicle} />

            <Grid size={GridSize.Four} className="account_user-booking-mobile__grid _mt-4">
              <div className="account_user-booking-mobile__divider">
                <div className="_text-4 _mb-2">{t('pick_up')}</div>
                <div className="_text-3">{book.startRentDate}</div>
              </div>
              <div className="account_user-booking-mobile__divider">
                <div className="_text-4 _mb-2">{t('drop_off')}</div>
                <div className="_text-3 _mb-2">{book.endRentDate}</div>
                <div className="_text-2">Total de dias de locação: {numberOfDay?.amount} dia(s)</div>
              </div>
              <div className="account_user-booking-mobile__divider">
                <div className="_text-4 _mb-2">{t('place')}</div>
                <div className="_text-3 _mb-2"><span className="_font-weight-bold">{book.company.tradingName}</span></div>
                <div className="_text-2 _mb-2">{book.company.address.full}</div>
                <div className="_text-2">{book.company.phone} | {book.company.email}</div>
              </div>
              <div>
                <div className="_text-4 _mb-2">{t('charges')}</div>
                <div className="_text-2">Preço pago à plataforma: {chargeTotalPlatform?.amountFormat}</div>
                <div className="_text-2 _mb-2">Preço pago à concessionária: {chargeTotalCompany?.amountFormat}</div>
                <div className="_text-2">Preço final: {chargeTotal?.amountFormat}</div>
                <div className="_text-2 _mb-2">Desconto: {chargeDiscount?.amountFormat}</div>
                <div className="_text-3"><span className="_font-weight-bold">Total: {chargeTotalUser?.amountFormat}</span></div>
              </div>
            </Grid>
          </div>
        </Card>)
      })}
      
      {dataBooking && dataBooking?.length <= 0 && (
        <EmptyBooking />
      )}
    </div>
  )

  const rendering = () => {
    switch(viewState) {
      case StateMachineType.NotStarted:
        return renderStateNotStarted()
      case StateMachineType.Loading:
        return renderStateLoading()
      case StateMachineType.Loaded:
        return renderStateLoaded()
    }
  }
  
  return (
    <>
      {rendering()}
    </>
  )
}

export default AccountUserBooking