import { Dispatch } from "redux"
import FetchApi from "libs/fetch"
import * as ActionUtility from "store/utilities/actionUtility"
import ActionType from "store/executer/ActionType"
import { StateMachineType } from "libs/statemachine"

export enum HttpVerb {
  Post = "POST",
  Put = "PUT",
  Delete = "DELETE",
}

const FetchApiInstance = {
  [HttpVerb.Post]: FetchApi.post,
  [HttpVerb.Put]: FetchApi.put,
  [HttpVerb.Delete]: FetchApi.delete,
}

export const executeAction = async ({ url, httpVerb, bodyParams }: { url: string; httpVerb: HttpVerb; bodyParams: any }) => {
  const fetch = await FetchApiInstance[httpVerb](url, bodyParams, true)

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const setData = (dispatch: Dispatch, payload: PayloadDataExecuter) =>
  dispatch(ActionUtility.createAction(ActionType.EXECUTER_DATA, payload));

export const setViewState = (dispatch: Dispatch, payload: PayloadViewStateExecuter) =>
  dispatch(ActionUtility.createAction(ActionType.EXECUTER_VIEW_STATE, payload));


interface PayloadDataExecuter {
  feature: string;
  data: any | null;
}

interface PayloadViewStateExecuter {
  feature: string;
  viewState: StateMachineType;
}