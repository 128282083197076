import { Dispatch } from 'redux'
import FetchApi from 'libs/fetch'
import * as ActionUtility from 'store/utilities/actionUtility'
import ActionType from 'store/signin/ActionType'
import { RequestPassword, ChangePassword, SigninParams } from 'store/signin/Action'
import { ErrorPayload } from 'libs/fetch/interface'
import Storage from 'libs/storage'

export const signin = async ({ email, password }: SigninParams) => {
  const fetch = await FetchApi.post(`/v1/auth/signin`, { email, password })

  if (fetch.status === FetchApi.HttpSuccessfulOk || fetch.status === FetchApi.HttpSuccessfulCreated) {
    Storage.authorization(fetch.data)
  }

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const requestPassword = async ({ email }: RequestPassword) => {
  const fetch = await FetchApi.post(`/v1/auth/requestForgotPassword`, { email })

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const changePassword = async ({ token, password }: ChangePassword) => {
  const fetch = await FetchApi.put(`/v1/auth/changePassword`, { token, password })

  return {
    hasError: FetchApi.hasError(fetch.status),
    data: fetch.data
  }
}

export const setLoading = (dispatch: Dispatch, payload: boolean | null) =>
  dispatch(ActionUtility.createAction(ActionType.SIGNIN_LOADING, payload))

export const setSuccess = (dispatch: Dispatch, payload: boolean | null) =>
  dispatch(ActionUtility.createAction(ActionType.SIGNIN_SUCCESS, payload))

export const setError = (dispatch: Dispatch, payload: ErrorPayload | null) =>
  dispatch(ActionUtility.createAction(ActionType.SIGNIN_ERROR, payload))

export const setUser = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.SIGNIN_USER, payload))
