import { Dispatch } from 'redux'
import * as CreditCardEffect from './Effect'
import { CreateCreditCard } from './Effect'

export const creditcard = () => async (dispatch: Dispatch) => {
  CreditCardEffect.setLoading(dispatch, true)

  const creditcards = await CreditCardEffect.creditcard()
  const defaultCard = creditcards.data.find((card: any) => card.cardDefault)
  
  CreditCardEffect.setSelected(dispatch, defaultCard)
  CreditCardEffect.setData(dispatch, creditcards.data)
  CreditCardEffect.setLoading(dispatch, false)
}

export const selectedCreditCard = (card: any) => async (dispatch: Dispatch) => {
  CreditCardEffect.setSelected(dispatch, card)
}

export const createCreditCard = (data: CreateCreditCard) => async (dispatch: Dispatch) => {
  CreditCardEffect.setErrorCreate(dispatch, false)
  CreditCardEffect.setLoadingCreate(dispatch, true)

  const create = await CreditCardEffect.createCreditCard(data)

  if (create.hasError) {
    CreditCardEffect.setErrorCreate(dispatch, true)
  }

  CreditCardEffect.setLoadingCreate(dispatch, false)

  return create
}

export const setSecurityData = (data: any) => async (dispatch: Dispatch) => {
  CreditCardEffect.setSecurityData(dispatch, data)
}

export const setDefault = (cardHash: string) => async () => {
  CreditCardEffect.defaultCreditcard(cardHash)
}