import React from 'react'
import { ClosecarLogo, InstagramFull } from 'assets'
import ImageLoad from 'components/atom/image_load'
import "./styles.scss"

const FooterSubscription: React.FC<any> = () => {

  return (
    <div className="landpage-subscription-footer">
      <div>
        <ClosecarLogo className="landpage-subscription-footer__logo"/>
        <div className="landpage-subscription-footer__description _mb-8">O seu novo jeito de alugar carros.</div>
        {/*<FacebookFull className="landpage-subscription-footer__social-icon _mr-4" />*/}
        <a href="https://www.instagram.com/close_car/" target="_blank" rel="noopener noreferrer">
          <InstagramFull className="landpage-subscription-footer__social-icon _mr-8" />
        </a>
      </div>
      
      <div>
        <div className="landpage-subscription-footer__title _mb-2">Contato</div>
        <div className="landpage-subscription-footer__description">31 9 8433-0552</div>
        <div className="landpage-subscription-footer__description">atendimento@closecar.com.br</div>
      </div>

      <div>
        <div className="landpage-subscription-footer__title _mb-2">Endereço</div>
        <div className="landpage-subscription-footer__description">
          A CloseCar faz parte da Easy App S.A, sociedade anônima registrada na República Federativa do Brasil, cuja sede social está em Belo Horizonte, Minas Gerais.
        </div>
      </div>

      <div className="landpage-subscription_whatsapp">
        <a href="https://wa.me/5531984330552" target="noopener noreferrer _blank">
          <ImageLoad url="https://53cars.s3.sa-east-1.amazonaws.com/whatsapp.png" width={64} />
        </a>
      </div>
    </div>
  )
}

export default FooterSubscription