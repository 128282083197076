import React from 'react'
import { Form, Checkbox } from 'antd'

interface FormCheckboxProps {
  label: any;
  name: string;
  required?: boolean;
  requiredMessage?: string;
}

const FormCheckbox: React.FC<FormCheckboxProps> = (props) => {
  const rules = []

  if (props?.required) {
    rules.push({ required: true, message: props.requiredMessage || 'Este campo não pode ser vazio.' })
  }

  return (
    <div>
      <Form.Item
        name={props.name}
        valuePropName="checked"
        rules={rules}
      >
        <Checkbox>{props.label}</Checkbox>
      </Form.Item>
    </div>
  )
}

export default FormCheckbox