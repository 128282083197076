import { StateMachineType } from "libs/statemachine"
import baseReducer from "../utilities/baseReducer"
import ActionType from "./ActionType"

interface VehicleSelectState {
  readonly loading?: StateMachineType;
  readonly error?: boolean;
  readonly data?: any;
}

export interface IVehicleState {
  readonly data: any | null;
  readonly loading: boolean | null;
  readonly error: boolean | null;
  readonly select?: VehicleSelectState;
}

const initialState: IVehicleState = {
  data: [],
  loading: false,
  error: false,
  select: {
    loading: StateMachineType.NotStarted,
    error: false,
    data: []
  }
};

const companyReducer = baseReducer(initialState, {
  [ActionType.VEHICLE_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      data: action.payload,
    };
  },
  [ActionType.VEHICLE_DATA_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      loading: action.payload,
    };
  },
  [ActionType.VEHICLE_DATA_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      error: action.payload,
    };
  },
  [ActionType.VEHICLE_SELECT_LOADING](
    state,
    action,
  ) {
    return {
      ...state,
      select: {
        ...state.select,
        loading: action.payload
      },
    };
  },
  [ActionType.VEHICLE_SELECT_ERROR](
    state,
    action,
  ) {
    return {
      ...state,
      select: {
        ...state.select,
        error: action.payload
      },
    };
  },
  [ActionType.VEHICLE_SELECT_DATA](
    state,
    action,
  ) {
    return {
      ...state,
      select: {
        ...state.select,
        data: action.payload
      },
    };
  },
});

export default companyReducer;