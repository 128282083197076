import React from 'react'
import Layout from 'components/atom/layout'
import Header from 'components/organism/header'

const UserAccountMenuMobile: React.FC<any> = (props) => {
  return (
    <Layout>
      <Header />

      <div className="_mt-6">
        <div className="_text-8 _font-weight-bold _ml-5 _mb-4">{props.title}</div>
        {props.children}
      </div>
    </Layout>
  )
}

export default UserAccountMenuMobile