import React, { useRef } from 'react';
import { AutoComplete as AntAutoComplete, Input } from 'antd'

import { useGetterStore } from 'store/getter/Hooks'
import StateMachine from 'libs/statemachine';

const { Search } = Input;

const AutoComplete = (props: any) => {
  const controller = useRef<AbortController>()

  const { getData, data = [], viewState } = useGetterStore({ 
    feature: props.feature
  })

  const handleSearch = (value: string) => {
    if (value?.length >= props.startLengthSearch) {
      if (controller.current) {
        controller.current?.abort()
        controller.current = new AbortController()
      } else {
        controller.current = new AbortController()
      }

      const { signal } = controller.current;
      getData({ queryParams: { [props.queryParam]: value }, signal })
    }

    props.onChangeValue && props.onChangeValue(value)
  };

  const internalOnSelect = (value: string) => {
    const item = data.find((item: any) => item[props.keyData] === value)
    props.onSelect && props.onSelect(item)
  }

  return (
    <AntAutoComplete
      className={props.className}
      dropdownMatchSelectWidth={252}
      style={{
        width: "100%",
      }}
      options={data?.map((item: any) => {
        return {
          value: item[props.keyData],
          label: item[props.keyData],
        };
      })}
      onSelect={internalOnSelect}
      onSearch={handleSearch}
      onKeyDown={props.onKeyDown}
      disabled={props.disabled || false}
      defaultValue={props?.defaultValue || ""}
    >
      <Search size="large" placeholder={props.placeholder} loading={props.loading || StateMachine.isLoading(viewState)} autoComplete="off" type="text" />
    </AntAutoComplete>
  )
}

AutoComplete.defaultProps = {
  startLengthSearch: 3,
  placeholder: 'digite o texto'
}

export default AutoComplete