// @ts-nocheck
import React from 'react'
import { Form, Select } from 'antd'
import Text from 'components/atom/text'

type FormSelectOption ={
  value: string | number;
  text: string;
}

type FormSelectOptionGroup = {
  group: string;
  options: FormSelectOption[];
}

interface FormSelectProps {
  label?: string;
  name: string;
  placeholder?: string;
  selectClassName?: string;
  option?: FormSelectOption[] | FormSelectOptionGroup[];
  required?: boolean;
  disabled?: boolean;
  showSearch?: boolean;
  allowClear?: boolean;
  bordered?: boolean;
  extras?: string;
  loading?: boolean;
  onSelect?: (value: any, option: any) => void;
}

const { Option, OptGroup } = Select;

const FormSelect: React.FC<FormSelectProps> = (props) => {
  const rules = []

  if (props?.required) {
    rules.push({ required: true, message: 'Este campo não pode ser vazio.' })
  }

  return (
    <div>
      {props.label && (
        <Text className="_text-2 _font">{props.label}{props.required && ' *'}</Text>
      )}
      
      <Form.Item
        name={props.name}
        hasFeedback
        rules={rules}
        extra={props.extras}
      >
        <Select 
          size="large" 
          className={props.selectClassName}
          placeholder={props.placeholder}
          onSelect={props.onSelect}
          disabled={props.disabled || false} 
          showSearch={props.showSearch || false}
          allowClear={props.allowClear || false}
          bordered={props.bordered || true}
          optionFilterProp="children"
          loading={props.loading || false}>
          {props?.option?.map(opt => {
            if (opt.hasOwnProperty("group")) {
              return (
                <OptGroup label={opt?.group} key={opt.group}>
                  {opt.options.map(option => (
                    <Option key={option.value} value={option.value}>{option.text}</Option>
                  ))}
                </OptGroup>
              )
            } else {
              return (
                <Option key={opt.value} value={opt.value}>{opt.text}</Option>  
              )
            }
          })}   
        </Select>
      </Form.Item>
    </div>
  )
}

export default FormSelect